import React, { useCallback } from "react";
import { useCreateScenarioStore } from "../../../../../stores";
import { IContent } from "../../../../../types/contentList";
import { v4 as uuidv4 } from "uuid";
import { ICreateScenarioItem } from "../../../../../types/createScenario";
import { IconImageFile, IconVideoFile } from "../../../../../components/UI";
import styles from "./styles.module.scss";
import ContentContainer from "../../../../../components/ContentContainer";

interface IProps {
    content: IContent
}

const AddContentItem: React.FC<IProps> = ({ content }) => {
    const { setNode } = useCreateScenarioStore(state => state);

    const getNewNodeData = useCallback((content: IContent) => {
        const id = uuidv4().replaceAll("-", "");
        const newNode = {
            id: id,
            type: content.type,
            position: {
                x: Math.random() * 200,
                y: Math.random() * 200,
            },
            data: {
                label: content.name,
            }
        };
        const scenarioItemData: ICreateScenarioItem = {
            id: id,
            content_id: content.id,
            content: content.name,
            path: content.path,
            conditions: {},
            condition_types: [],
            nodes: {},
            next: "",
            is_start: false,
            is_end: false,
            content_type: content.type,
            duration: content.duration ? content.duration : 5,
            blackout_duration: 300
        };

        return {
            newNode: newNode,
            scenarioItemData: scenarioItemData
        };
    }, []);

    const addNode = useCallback((content: IContent) => {
        const { newNode, scenarioItemData } = getNewNodeData(content);

        setNode(newNode, scenarioItemData);
    }, []);


    const onDragStart = (event: React.DragEvent<HTMLLIElement>, content: IContent) => {
        const { newNode, scenarioItemData } = getNewNodeData(content);

        const data = {
            newNode: newNode,
            scenarioItemData: scenarioItemData
        };

        event.dataTransfer.setData("application/reactflow", JSON.stringify(data));
        event.dataTransfer.effectAllowed = "move";
    };

    return (
        <li className={styles.add_content_item_container} onClick={() => addNode(content)} onDragStart={(e) => onDragStart(e, content)} draggable>
            <div className={styles.preview_container}>
                {content.preview.length
                    ? <ContentContainer url={content.preview} contentType={"preview"} />
                    : content.type === "image"
                        ? <IconImageFile color={"black"} size={40} />
                        : <IconVideoFile color={"black"} size={40} />
                }
            </div>
            <span>{content.name}</span>
        </li>
    );
};

export default AddContentItem;