import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { useCreatePlaylistStore } from "../../../stores";
import styles from "./styles.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper";
import classnames from "classnames";
import ContentContainer from "../../../components/ContentContainer";


const SwipperContainer = () => {
    const { currentPlaylistItem } = useCreatePlaylistStore(state => state);

    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

    if (!currentPlaylistItem) {
        return null;
    }

    return (
        <>
            <Swiper
                className={styles.swiper}
                mousewheel
                navigation
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Mousewheel, Thumbs]}
            >
                {Object.entries(currentPlaylistItem.content).map(([key, val]) =>
                    <SwiperSlide className={styles.item} key={key}>
                        <ContentContainer url={val.path} contentType={val.content_type} />
                    </SwiperSlide>
                )}
            </Swiper>

            <Swiper
                className={classnames(styles.swiper_2, "slider-navigation")}
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                mousewheel={true}
                modules={[FreeMode, Navigation, Thumbs, Mousewheel]}
            >
                {Object.entries(currentPlaylistItem.content).map(([key, val]) =>
                    <SwiperSlide className={styles.item} key={key + "_small"}>
                        <ContentContainer url={val.content_type === "image" ? val.path : ""} contentType={"preview"} />
                    </SwiperSlide>
                )}
            </Swiper>
        </>
    );
};

const ViewContent = () => {
    const { currentPlaylistItem } = useCreatePlaylistStore(state => state);
    const t = useTranslation();

    if (!currentPlaylistItem) {
        return (
            <div className={styles.view_content_container}>
                <h1>{t("label.select_element", { name: t("playlist.genitive") })}</h1>
            </div>
        );
    }

    return (
        <div className={styles.view_content_container}>
            {currentPlaylistItem.type === "scenario"
                ? <div className={styles.scenario_content_container}>
                    <SwipperContainer />
                </div>
                : <ContentContainer
                    url={currentPlaylistItem.content[currentPlaylistItem.content_id].path}
                    contentType={currentPlaylistItem.content[currentPlaylistItem.content_id].content_type}
                />
            }
        </div>
    );
};

export default ViewContent;