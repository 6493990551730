import React, { useEffect, useState } from "react";
import "./RightControlPanel.scss";
import { useCreatePlaylistStore } from "../../../stores";
import { Input, Select } from "../../../components/UI";
import Triggers from "./Triggers";
import { TPlaylistItemDisplayType } from "../../../types/playlist";
import { useTranslation } from "i18nano";


const blackoutOptions = [
    {
        id: 0,
        name: "0 ms"
    },
    {
        id: 100,
        name: "100 ms"
    },
    {
        id: 200,
        name: "200 ms"
    },
    {
        id: 300,
        name: "300 ms"
    },
    {
        id: 400,
        name: "400 ms"
    },
    {
        id: 500,
        name: "500 ms"
    }
];

const RightControlPanel = () => {
    const { setDuration, setDisplayType, currentPlaylistItem, setBlackoutDuration } = useCreatePlaylistStore(state => state);
    const t = useTranslation();

    const [itemType, setItemType] = useState<TPlaylistItemDisplayType>("required");
    const [itemTime, setItemTime] = useState(0);
    const [itemBlackoutTime, setItemBlackoutTime] = useState(300);

    const itemTypeOptions = [];

    for (const key of Object.keys(t("create_playlist.item_playlist_type"))) {
        if (key !== "label") {
            itemTypeOptions.push({
                name: t(`create_playlist.item_playlist_type.${key}`),
                id: key
            });
        }
    }

    useEffect(() => {
        if (currentPlaylistItem) {
            setItemType(currentPlaylistItem.display_type);
            setItemTime(currentPlaylistItem.duration);
            setItemBlackoutTime(currentPlaylistItem.blackout_duration);
        }
    }, [currentPlaylistItem]);

    const changeTimeHandler = (value: string) => {
        setDuration(parseInt(value));
        setItemTime(parseInt(value));
    };

    const changeBlackoutTimeHandler = (value: string) => {
        setBlackoutDuration(parseInt(value));
        setItemBlackoutTime(parseInt(value));
    };

    const setTypeHandler = (value: any) => {
        setDisplayType(value);
        setItemType(value);
    };

    return (
        <div className={"controlPanel rightControlPanel"}>
            {currentPlaylistItem
                ? <div className={"settingsPlaylistItem"}>
                    <div className="select-item-type">
                        <span className={"title"}>{t("create_playlist.item_playlist_type.label")}</span>
                        <Select options={itemTypeOptions} value={itemType} setValue={val => setTypeHandler(val)} />
                    </div>
                    {currentPlaylistItem.type === "image" &&
                        <div className="playback_duration">
                            <label>{t("create_playlist.play_time")}</label>
                            <div>
                                <Input type={"number"} value={itemTime} onChange={changeTimeHandler} min={0} />
                                <span className={"seconds"}>{t("measurement.seconds")}</span>
                            </div>
                        </div>
                    }
                    <div className="blackout_duration">
                        <label>{t("create_playlist.blackout_time")}</label>
                        <div>
                            <Select options={blackoutOptions} value={itemBlackoutTime} setValue={changeBlackoutTimeHandler} />
                        </div>
                    </div>
                    {itemType === "triggers" &&
                        <Triggers currentId={currentPlaylistItem.id} />
                    }
                </div>
                : <h1>{t("label.select_element", { name: t("playlist.genitive") })}</h1>
            }
        </div>
    );
};

export default RightControlPanel;