import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Portal from "../Portal";
import styles from "./styles.module.scss";


interface IProps {
    children: React.ReactNode;
    text: string;
    showDelay?: number;
}

const Tooltip: React.FC<IProps> = ({ children, text, showDelay = 300 }) => {
    const [show, setShow] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLSpanElement>(null);
    const [position, setPosition] = useState({
        top: 0,
        left: 0
    });

    useEffect(() => {
        if (ref.current && tooltipRef.current) {
            const parentRect = ref.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();

            const tooltipLeft = parentRect.x - tooltipRect.width / 2 + parentRect.width / 2;

            setPosition({
                top: parentRect.y - tooltipRect.height - 4,
                left: tooltipLeft > 0
                    ? tooltipLeft + tooltipRect.width > document.body.offsetWidth
                        ? document.body.offsetWidth - tooltipRect.width - 10
                        : tooltipLeft
                    : 10
            });
        }
    }, [ref, show]);

    const showHandler = (event: React.MouseEvent) => {
        setShow(event.type === "mouseenter");
    };

    return (
        <div className={classnames(styles.tooltip_container)} ref={ref} onMouseEnter={showHandler} onMouseLeave={showHandler}>
            {children}
            {show && ref.current
                ? <Portal>
                    <span ref={tooltipRef} className={styles.tooltip_text} style={{ ...position }}>{text}</span>
                </Portal>
                : null
            }
        </div>
    );
};

export default Tooltip;