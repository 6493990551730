import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

interface IProps {
    onSearch: (val: string) => void;
    placeholder?: string;
    id?: string;
    disabled?: boolean;
    iconEnd?: JSX.Element;
}

const SearchInput: React.FC<IProps> = ({ placeholder, id, disabled, onSearch, iconEnd }) => {
    const [val, setVal] = useState("");
    const first = useRef(true);

    useEffect(() => {
        if (first.current) {
            first.current = false;
            return;
        }

        const timeout = setTimeout(() => {
            onSearch(val);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [val]);

    return (
        <div className={`${styles.input_container} ${iconEnd ? styles.icon : null}`}>
            {disabled && <div className={styles.disabled}></div>}
            <input
                value={val}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setVal(e.target.value)}
                placeholder={placeholder}
                type={"text"}
                id={id}
            />
            {iconEnd
                ? <span className={styles.input_icon}>{iconEnd}</span>
                : null
            }
        </div>
    );
};

export default SearchInput;