import React, { useCallback, useRef, useState } from "react";
import ReactFlow, {
    ReactFlowProvider,
    Controls,
    KeyCode,
    ReactFlowInstance,
    Node,
    Edge,
} from "reactflow";
import "reactflow/dist/style.css";
import { IconCloseSquare, IconEye } from "../../../components/UI";
import { useCreateScenarioStore } from "../../../stores/";

import { ImageNode, VideoNode, StartNode, EndNode } from "./CustomNodes";
import { CustomEdge } from "./CustomEdges";
import "./Chart.scss";

const deleteKeyCode: KeyCode = ["Delete", "Backspace"];
export const nodeTypes = {
    "video": VideoNode,
    "image": ImageNode,
    "start": StartNode,
    "end": EndNode
};
export const edgeTypes = {
    "custom": CustomEdge
};

const Chart = () => {
    const {
        nodes,
        edges,
        onNodesChange,
        onEdgesChange,
        onConnect,
        onEdgeClick,
        onEdgesDelete,
        onNodeClick,
        onPaneClick,
        onNodesDelete,
        setNode,
        chartConfig,
        changeChartConfig
    } = useCreateScenarioStore(store => store);

    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    };

    const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const reactFlowBounds = reactFlowWrapper.current!.getBoundingClientRect();
        const data = event.dataTransfer.getData("application/reactflow");

        if (typeof data === "undefined" || !data) {
            return;
        }

        const position = reactFlowInstance!.project({
            x: event.clientX - reactFlowBounds.left - 50,
            y: event.clientY - reactFlowBounds.top - 15,
        });

        const newNode = { ...JSON.parse(data).newNode, position: position };
        const scenarioItemData = JSON.parse(data).scenarioItemData;

        setNode(newNode, scenarioItemData);
    }, [reactFlowInstance, setNode]);

    const deleteModeHandler = (n?: Node, e?: Edge) => {
        reactFlowInstance?.deleteElements({
            nodes: n ? [n] : undefined,
            edges: e ? [e] : undefined,
        });
    };

    return (
        <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ height: "100%" }}>
                <div className={"chart-settings-container"}>
                    <div className="icon-container" onClick={() => changeChartConfig({ deleteMode: !chartConfig.deleteMode })}>
                        <IconCloseSquare size={25} color={chartConfig.deleteMode ? "red" : "default"} />
                    </div>
                    <div className="icon-container" onClick={() => changeChartConfig({ showMode: !chartConfig.showMode })}>
                        <IconEye size={25} color={chartConfig.showMode ? "blue" : "default"} />
                    </div>
                </div>
                <ReactFlow
                    onInit={setReactFlowInstance}
                    nodes={nodes}
                    edges={edges}
                    deleteKeyCode={deleteKeyCode}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onNodeDrag={(e, node) => chartConfig.deleteMode ? deleteModeHandler(node) : onNodeClick(node)}
                    onNodeClick={(e, node) => chartConfig.deleteMode ? deleteModeHandler(node) : onNodeClick(node)}
                    onEdgeClick={(e, edge) => chartConfig.deleteMode ? deleteModeHandler(undefined, edge) : onEdgeClick(edge)}
                    onEdgesDelete={onEdgesDelete}
                    onNodesDelete={onNodesDelete}
                    onPaneClick={onPaneClick}
                    multiSelectionKeyCode={null}
                    maxZoom={2}
                    minZoom={0.3}
                    zoomOnDoubleClick
                    fitView
                    fitViewOptions={{ maxZoom: 1.5 }}
                    defaultViewport={{ zoom: 1.5, x: 0, y: 0 }}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                >
                    <Controls style={{ display: "flex", flexDirection: "row" }} />
                </ReactFlow>
            </div>
        </ReactFlowProvider>
    );
};

export default Chart;