import React, { useState } from "react";
import { IContent } from "../../../types/contentList";
import "./ContentItem.scss";
import { useContentStore } from "../../../stores";
import { IconCloseSquare, IconEye, IconImageFile, IconVideoFile, Modal, ModalConfirm, Tooltip } from "../../../components/UI";
import { useTranslation } from "i18nano";
import ContentContainer from "../../../components/ContentContainer";


interface IProps {
    content: IContent,
}

const ContentItem: React.FC<IProps> = ({ content }) => {
    const t = useTranslation();

    const { deleteContent, selectContent } = useContentStore(state => state);
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    return (
        <div className="table-item cols-4">
            <div className={"checkbox-container"}>
                <input
                    type="checkbox"
                    checked={content.isChecked}
                    onChange={() => selectContent({ selectState: !content.isChecked, selectedData: content.id })}
                />
            </div>
            <div className={"preview-container"}>
                <div className="preview">
                    {content.preview?.length
                        ? <ContentContainer url={content.preview} contentType={"preview"} />
                        : content.type === "image"
                            ? <IconImageFile color={"black"} size={40} />
                            : <IconVideoFile color={"black"} size={40} />
                    }
                </div>
                <div className={"name"}>{content.name}</div>
            </div>
            <div>{content.size}</div>
            <div>{content.resolution}</div>
            <div className={"settings-container"}>
                <Tooltip text={t("actions.view") + " " + t(content.type + ".one")}>
                    <span className="action-container" onClick={() => setShow(true)}><IconEye color={"blue"} size={25} /></span>
                </Tooltip>
                <Tooltip text={t("actions.delete") + " " + t(content.type + ".one")}>
                    <span className="action-container" onClick={() => setShowConfirm(true)}><IconCloseSquare color={"red"} size={25} /></span>
                </Tooltip>
            </div>

            <Modal
                isShow={show}
                setIsShow={setShow}
                title={`${t("content.one")}: ${content.name}`}
            >
                <div className={"player-container"}>
                    <ContentContainer
                        url={content.path}
                        contentType={content.type}
                        width={content.type === "video" ? "100%" : undefined}
                        height={content.type === "video" ? "100%" : undefined}
                    />
                </div>
            </Modal>
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => deleteContent([content.id.toString()])} onCancel={() => { }}>
                <p>{t("confirm.delete", { name: content.name })}</p>
            </ModalConfirm>
        </div>
    );
};

export default ContentItem;