import classnames from "classnames";
import React from "react";
import { Handle, NodeProps, Position } from "reactflow";
import styles from "./styles.module.scss";


const EndNode: React.FC<NodeProps> = ({ data }) => {
    return (
        <div className={classnames(styles.node_container, styles.end_node_container)}>
            <Handle type="target" position={Position.Top} className={styles.handle} />
            <div className={styles.ball}>
                {data.label}
            </div>
        </div>
    );
};

export default EndNode;