import axios from "./axios";
import { ICreateScenarioData } from "../types/createScenario";
import { IFilters } from "../types/default";
import { IScenarioList } from "../types/scenarioList";


export const getOneScenarioApi = async (scenario_id: string): Promise<ICreateScenarioData | undefined> => {
    return await axios.get(`/scenario/${scenario_id}/`);
};

export const getAllScenarioApi = async (filters: IFilters): Promise<IScenarioList | undefined> => {
    return await axios.post("/scenario/", {
        page: filters.page,
        per_page: filters.perPage,
        filters: filters.filters,
        order_by: filters.orderBy,
        order_desc: filters.orderDesk
    });
};

export const createScenarioApi = async (data: ICreateScenarioData): Promise<Record<string, string> | undefined> => {
    return await axios.post("/scenario/create/", data);
};

export const updateScenarioApi = async (data: ICreateScenarioData) => {
    return await axios.post("/scenario/update/", data);
};

export const deleteScenarioApi = async (data: string[]) => {
    return await axios.delete("/scenario/delete/", { data });
};