import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useUserStore } from "../../stores";
import { Button, IconKey, IconUserInput, Input, useNotification } from "../../components/UI";
import { useTranslation } from "i18nano";

const AuthPage = () => {
    const { authentication, error } = useUserStore(state => state);
    const {addNotific} = useNotification();
    const t = useTranslation();

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const onSubmitHandler = () => {
        authentication({ login, password });
    };

    useEffect(() => {
        const sessionError = localStorage.getItem("sessionError");

        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body),
                type: "danger"
            });
        }

        if (sessionError) {
            addNotific({
                title: t(JSON.parse(sessionError).title),
                body: t(JSON.parse(sessionError).body),
                type: "danger"
            });

            localStorage.removeItem("sessionError");
        }
    }, [error]);

    return (
        <div className={styles.root}>
            <div className={styles.form_container}>
                <form className={styles.form_body} onSubmit={(e) => e.preventDefault()}>
                    <Input
                        type="text"
                        value={login}
                        onChange={setLogin}
                        iconStart={<IconUserInput size={20} />}
                        placeholder={"Type login"}
                    />
                    <Input
                        type="password"
                        value={password}
                        onChange={setPassword}
                        iconStart={<IconKey size={20} />}
                        placeholder={"Type password"}
                    />
                    <Button 
                    variant={login.length && password.length ? "primary" : "disabled"}
                    onClick={onSubmitHandler}
                    >{t("button.login")}</Button>
                </form>
            </div>
            <div className={styles.hello_text}>
                <h1>{t("authentication_page.head_text")}</h1>
                <p>{t("authentication_page.paragraph_text")}</p>
            </div>
        </div>
    );
};

export default AuthPage;