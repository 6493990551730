import classnames from "classnames";
import { useTranslation } from "i18nano";
import React, { SetStateAction, useEffect, useState } from "react";
import { Button, IconCloseX, Input, Modal, Select } from "../../../../../components/UI";
import { useCreatePlaylistStore } from "../../../../../stores";
import { ITriggerDatetime, ITriggersDatetimeInterval, TIntervalType } from "../../../../../types/playlist";
import { getShortName, IShortName, TEnd } from "./consts";
import { v4 as uuidv4 } from "uuid";
import "./TimetableItem.scss";


interface IProps {
    timetableList: Record<string, ITriggerDatetime>;
    itemKey: string;
}

interface IModal {
    timetableItem: ITriggerDatetime;
    closeHandler: () => void;
    confirmHandler: (item: ITriggerDatetime) => void;
    setTitleName: React.Dispatch<SetStateAction<string>>
}

const ModalContent: React.FC<IModal> = ({ timetableItem, closeHandler, confirmHandler, setTitleName }) => {
    const t = useTranslation();

    const [allDay, setAllDay] = useState(false);

    const [endType, setEndType] = useState<TEnd>("never");

    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("23:59");
    const [days, setDays] = useState<number[]>([]);

    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");

    const [interval, setInterval] = useState<ITriggersDatetimeInterval>({
        value: 1,
        type: "day"
    });

    const [repetitions, setRepetitions] = useState(1);

    const uniqKey = uuidv4();

    const week = Object.values(t("triggers.datetime.short.days")).map((item, index) => {
        return {
            id: index,
            name: item
        };
    });

    const selectIntervalType = Object.entries(t("triggers.datetime.select_interval")).map(item => {
        return {
            id: item[0],
            name: item[1]
        };
    });

    const changeDayHandler = (day: number) => {
        if (days.includes(day)) {
            setDays(days.filter(item => item !== day));
        } else {
            setDays([...days, day]);
        }
    };

    const changeEndTypeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (value === "never" || value === "before" || value === "date") {
            setEndType(value);
        }
    };

    useEffect(() => {
        const newDatetime = {
            date_start: dateStart,
            interval: interval,
            days: interval.type === "week" ? days : null,
            time_start: allDay ? null : startTime,
            time_end: allDay ? null : endTime,
            date_end: endType === "date" ? dateEnd : null,
            repetitions: endType === "before" ? repetitions : null
        };

        const { title } = getShortName(newDatetime, t("triggers.datetime"));
        setTitleName(title);
    }, [dateStart, startTime, endTime, allDay, interval, days, endType, dateEnd, repetitions, t, setTitleName]);

    useEffect(() => {
        if (timetableItem) {
            if (timetableItem.date_start.length) {
                setDateStart(timetableItem.date_start);
            } else {
                setDateStart(new Date().toISOString().split("T")[0]);
            }
            setInterval(timetableItem.interval);
            setDateEnd(timetableItem.date_end ? timetableItem.date_end : new Date().toISOString().split("T")[0]);
            setDays(timetableItem.days ? timetableItem.days : []);
            setStartTime(timetableItem.time_start ? timetableItem.time_start : "00:00");
            setEndTime(timetableItem.time_end ? timetableItem.time_end : "23:59");
            setAllDay(!timetableItem.time_start);
            setRepetitions(timetableItem.repetitions ? timetableItem.repetitions : 1);
            setEndType(timetableItem.date_end ? "date" : timetableItem.repetitions ? "before" : "never");
        }
    }, []);

    const confirm = () => {
        const newDatetime = {
            date_start: dateStart,
            interval: interval,
            days: interval.type === "week" ? days : null,
            time_start: allDay ? null : startTime,
            time_end: allDay ? null : endTime,
            date_end: endType === "date" ? dateEnd : null,
            repetitions: endType === "before" ? repetitions : null
        };

        confirmHandler(newDatetime);
    };

    return (
        <div>
            <div className="timetable-item-modal-container">
                <div className={"timetable-create-container"}>
                    <div className={"datetimePicker-container"}>
                        <span>{t("triggers.datetime.labels.dates")}</span>
                        {allDay
                            ?
                            <div className="dates">
                                <Input type={"date"} value={dateStart} onChange={setDateStart} />
                            </div>
                            :
                            <div className="datetime">
                                <Input type={"date"} value={dateStart} onChange={setDateStart} />
                                <Input type={"time"} value={startTime} onChange={setStartTime} />
                                <Input type={"time"} value={endTime} onChange={setEndTime} />
                            </div>
                        }
                        <div className="allDay-container">
                            <input type="checkbox" id={`allDayCheck-${uniqKey}`} checked={allDay} onChange={() => setAllDay(!allDay)} />
                            <label htmlFor={`allDayCheck-${uniqKey}`}>{t("triggers.datetime.labels.all_day")}</label>
                        </div>
                    </div>
                    <div className="interval-container">
                        <span>{t("triggers.datetime.labels.repeat_with_interval")}&nbsp;</span>
                        <Input
                            type="number"
                            value={interval.value}
                            onChange={val => setInterval({ ...interval, value: parseInt(val) > 0 ? parseInt(val) : 1 })}
                        />
                        <Select
                            options={selectIntervalType}
                            value={interval.type}
                            setValue={(val: TIntervalType) => setInterval({ ...interval, type: val })}
                        />
                    </div>
                    {interval.type === "week" &&
                        <div className={"weekPicker-container"}>
                            <span>{t("triggers.datetime.labels.days_repetition")}</span>
                            <div className={"weekPicker-items"}>
                                {week.map(day =>
                                    <span
                                        className={classnames({ "active": days.includes(day.id) })}
                                        onClick={() => changeDayHandler(day.id)}
                                        key={day.id}
                                    >{day.name}</span>
                                )}
                            </div>
                        </div>
                    }
                    <div className={"ends-container"}>
                        <span>{t("triggers.datetime.labels.ends.label")}</span>
                        <div className={"end-item"}>
                            <div className="radio-container">
                                <input
                                    type={"radio"}
                                    name={`end-${uniqKey}`}
                                    id={`radio-never-${uniqKey}`}
                                    defaultChecked={timetableItem.repetitions === null && timetableItem.date_end === null}
                                    value={"never"}
                                    onChange={changeEndTypeHandler}
                                />
                                <label htmlFor={`radio-never-${uniqKey}`}>{t("triggers.datetime.labels.ends.never")}</label>
                            </div>
                        </div>
                        <div className={"end-item"}>
                            <div className="radio-container">
                                <input
                                    type={"radio"}
                                    name={`end-${uniqKey}`}
                                    id={`radio-date-${uniqKey}`}
                                    defaultChecked={timetableItem.date_end !== null}
                                    value={"date"}
                                    onChange={changeEndTypeHandler}
                                />
                                <label htmlFor={`radio-date-${uniqKey}`}>{t("triggers.datetime.labels.ends.data")}</label>
                            </div>
                            <div className={"end-input"}>
                                <Input
                                    type={"date"}
                                    value={dateEnd}
                                    onChange={setDateEnd}
                                    disabled={endType !== "date"}
                                />
                            </div>
                        </div>
                        <div className={"end-item"}>
                            <div className="radio-container">
                                <input
                                    type={"radio"}
                                    name={`end-${uniqKey}`}
                                    id={`radio-before-${uniqKey}`}
                                    defaultChecked={timetableItem.repetitions !== null}
                                    value={"before"}
                                    onChange={changeEndTypeHandler}
                                />
                                <label htmlFor={`radio-before-${uniqKey}`}>{t("triggers.datetime.labels.ends.before")}</label>
                            </div>
                            <div className={"end-input"}>
                                <Input
                                    type="number"
                                    value={repetitions}
                                    onChange={val => setRepetitions(parseInt(val) > 0 ? parseInt(val) : 1)}
                                    disabled={endType !== "before"}
                                />
                                <span>{t("triggers.datetime.labels.ends.repetition")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"buttons-container"}>
                    <Button onClick={closeHandler} variant={"secondary"}>{t("button.cancel")}</Button>
                    <Button onClick={confirm} variant={"primary"}>{t("button.apply")}</Button>
                </div>
            </div>
        </div>
    );
};

const TimetableItem: React.FC<IProps> = ({ timetableList, itemKey }) => {
    const { setTriggers, triggers, currentPlaylistItem } = useCreatePlaylistStore(state => state);
    const t = useTranslation();

    const [show, setShow] = useState(timetableList[itemKey].date_start.length === 0);
    const [shortName, setShortName] = useState<IShortName>();
    const [titleName, setTitleName] = useState<string>("");

    useEffect(() => {
        if (timetableList[itemKey].date_start.length) {
            setShortName(getShortName(timetableList[itemKey], t("triggers.datetime")));
        }
    }, [itemKey, t, timetableList]);

    const deleteHandler = () => {
        if (currentPlaylistItem) {
            const newDatetime = { ...timetableList };
            delete newDatetime[itemKey];

            setTriggers({
                ...triggers[currentPlaylistItem.id],
                datetime: newDatetime
            });
        }

        setShow(false);
    };

    const closeHandler = () => {
        if (currentPlaylistItem) {
            const newDatetime = { ...timetableList };
            if (!newDatetime[itemKey].date_start) {
                delete newDatetime[itemKey];

                setTriggers({
                    ...triggers[currentPlaylistItem.id],
                    datetime: newDatetime
                });
            }
        }

        setShow(false);
    };

    const confirmHandler = (item: ITriggerDatetime) => {
        if (currentPlaylistItem) {
            const newDatetime = { ...timetableList };

            newDatetime[itemKey] = item;

            if (newDatetime[itemKey].date_start.length) {
                setShortName(getShortName(newDatetime[itemKey], t("triggers.datetime")));

                setTriggers({
                    ...triggers[currentPlaylistItem.id],
                    datetime: newDatetime
                });
            } else {
                deleteHandler();
            }

            setShow(false);
        }
    };

    return (
        <div className={"trigger-item timetable-item-container"}>
            <div className="short-name-container">
                {shortName
                    ? <span className={"name"} onClick={() => setShow(true)}>
                        <span className="title">{shortName.title}</span>
                        <span className="body">{shortName.body}</span>
                    </span>
                    : null
                }
                <span className={"delete-icon"} onClick={deleteHandler}><IconCloseX color={"black"} /></span>
            </div>
            <Modal
                isShow={show}
                setIsShow={setShow}
                title={titleName}
                disableOverlay
            >
                <ModalContent
                    timetableItem={timetableList[itemKey]}
                    confirmHandler={confirmHandler}
                    closeHandler={closeHandler}
                    setTitleName={setTitleName}
                />
            </Modal>
        </div>
    );
};

export default TimetableItem;