import classnames from "classnames";
import React from "react";
import { Handle, NodeProps, Position } from "reactflow";
import styles from "./styles.module.scss";


const StartNode: React.FC<NodeProps> = ({data}) => {
    return (
        <div className={classnames(styles.node_container, styles.start_node_container)}>
            <div className={styles.ball}>
                {data.label}
            </div>
            <Handle type="source" position={Position.Bottom} className={styles.handle} />
        </div>
    );
};

export default StartNode;