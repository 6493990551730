import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createJSONStorage, persist } from "zustand/middleware";
import {
    Node,
    Edge,
    addEdge,
    applyNodeChanges,
    applyEdgeChanges,
} from "reactflow";
import {
    ICreateScenarioData,
    ICreateScenarioStore,
    onConnectData,
} from "../../types/createScenario";
import {
    initialEdges,
    initialNodes,
    defaultEdgeSettings,
    defaultState,
    defaultStateScenarioItems,
    checkEnd,
    checkStart,
    addConnect,
    deleteTransition,
    addTransitions
} from "./consts";

import lodash from "lodash";
import { createScenarioApi, getOneScenarioApi, updateScenarioApi } from "../../api/scenario-api";


export const useCreateScenarioStore = create<ICreateScenarioStore>()(
    immer(persist(
        (set, get) => ({
            nodes: initialNodes,
            edges: initialEdges,
            scenarioData: {
                name: "",
                description: ""
            },
            currentEdge: undefined,
            currentNode: undefined,
            scenarioItems: defaultStateScenarioItems,
            editedScenario: defaultState,
            isEdited: false,
            error: undefined,
            chartConfig: {
                showMode: false,
                deleteMode: false
            },
            clearStore: () => {
                set(state => {
                    state.nodes = initialNodes;
                    state.edges = initialEdges;
                    state.currentEdge = undefined;
                    state.currentNode = undefined;
                    state.scenarioItems = defaultStateScenarioItems;
                    state.editedScenario = defaultState;
                    state.isEdited = false;
                    state.error = undefined;
                    state.chartConfig = {
                        showMode: false,
                        deleteMode: false
                    };
                    state.scenarioData = {
                        name: "",
                        description: ""
                    };
                });
            },
            setDefaultState: () => {
                const { editedScenario, scenarioItems } = get();

                if (editedScenario.id) {
                    const newNodes: Node[] = [];
                    const newEdges: Edge[] = [];

                    for (const [key, value] of Object.entries(editedScenario.scenario_network_nodes)) {
                        newNodes.push({
                            ...value,
                            data: {
                                ...value.data
                            },
                            type: value.type,
                            deletable: key !== "start" && key !== "end"
                        });
                    }

                    for (const value of Object.values(editedScenario.scenario_network_edges)) {
                        newEdges.push({
                            ...defaultEdgeSettings,
                            ...value,
                            labelBgPadding: [8, 4],
                        });

                        addTransitions(value.source, value.target);
                    }

                    set({
                        nodes: newNodes,
                        edges: newEdges,
                        scenarioItems: {
                            ...scenarioItems,
                            ...editedScenario.scenario_items
                        },
                        scenarioData: {
                            name: editedScenario.name,
                            description: editedScenario.description
                        }
                    });
                }
            },
            changeChartConfig: (data) => {
                set(state => {
                    state.chartConfig = { ...state.chartConfig, ...data };
                });
            },
            changeScenarioData: (data) => {
                set(state => {
                    state.scenarioData = data;
                });
            },
            setNode: (newNode, scenarioItemData) => {
                set((state) => ({
                    nodes: [
                        ...state.nodes,
                        {
                            ...newNode
                        }
                    ],
                    scenarioItems: {
                        ...state.scenarioItems,
                        ...{ [newNode.id]: { ...scenarioItemData } }
                    }
                }));
            },
            onNodesChange: (changes) => {
                set({ nodes: applyNodeChanges(changes, get().nodes) });
            },
            onEdgesChange: (changes) => {
                set({ edges: applyEdgeChanges(changes, get().edges) });
            },
            onEdgeClick: (selectedEdge) => {
                if (selectedEdge.id.split("-")[2] !== "end" && selectedEdge.id.split("-")[1] !== "start") {
                    set({
                        currentEdge: selectedEdge,
                        currentNode: undefined,
                        edges: get().edges.map(edge => edge.id === selectedEdge.id ? { ...edge, selected: true } : { ...edge, selected: false }),
                        nodes: get().nodes.map(node => ({ ...node, selected: false }))
                    });
                } else {
                    set({
                        currentEdge: undefined,
                        currentNode: undefined
                    });
                }
            },
            onNodeClick: (selectedNode) => {
                if (selectedNode.id !== "start" && selectedNode.id !== "end") {
                    set({
                        currentNode: selectedNode,
                        currentEdge: undefined,
                        nodes: get().nodes.map(node => node.id === selectedNode.id ? { ...node, selected: true } : { ...node, selected: false }),
                        edges: get().edges.map(edge => ({ ...edge, selected: false }))
                    });
                } else {
                    set({
                        currentEdge: undefined,
                        currentNode: undefined
                    });
                }
            },
            onPaneClick: () => {
                set({
                    currentEdge: undefined,
                    currentNode: undefined
                });
            },
            onEdgesDelete: (deletedEdges) => {
                const { scenarioItems } = get();

                const newScenarioItems = lodash.cloneDeep(scenarioItems);

                for (const edge of deletedEdges) {
                    if (scenarioItems[edge.source]) {
                        for (const [key, value] of Object.entries(scenarioItems[edge.source].nodes)) {
                            if (value === edge.target) {
                                delete newScenarioItems[edge.source].nodes[key];
                            }
                        }

                        if (edge.target === "end") {
                            newScenarioItems[edge.source].is_end = false;
                        }

                        newScenarioItems[edge.source].next = null;
                    }
                    if (scenarioItems[edge.target]) {
                        delete newScenarioItems[edge.target].conditions[`${edge.source}-${edge.target}`];

                        if (edge.source === "start") {
                            newScenarioItems[edge.target].is_start = false;
                        }
                    }

                    deleteTransition(edge.source, edge.target);
                }

                set({
                    currentEdge: undefined,
                    currentNode: undefined,
                    scenarioItems: newScenarioItems
                });
            },
            onNodesDelete: (deletedNodes) => {
                const { scenarioItems } = get();
                const newScenarioItems = { ...scenarioItems };

                for (const node of deletedNodes) {
                    delete newScenarioItems[node.id];
                }

                set({
                    currentEdge: undefined,
                    currentNode: undefined,
                    scenarioItems: newScenarioItems
                });
            },
            onConnect: (connection) => {
                const from = connection.source;
                const to = connection.target;

                if (!from || !to) {
                    return;
                }

                if (from === "start" && to === "end") {
                    set(state => {
                        state.error = {
                            title: "notification.error.create_scenario.edges_error.label",
                            body: "notification.error.create_scenario.edges_error.start_end"
                        };
                    });

                    return;
                }

                const { edges, scenarioItems } = get();

                const connections: onConnectData = {
                    from: lodash.cloneDeep(scenarioItems[from]),
                    to: lodash.cloneDeep(scenarioItems[to])
                };

                const connectData = addConnect(connections);

                if (connectData.error) {
                    set(state => {
                        state.error = connectData.error;
                    });

                    return;
                } else if (Object.keys(connectData.connectData).length) {
                    set((state) => ({
                        edges: addEdge({
                            ...connection,
                            ...defaultEdgeSettings
                        }, edges),
                        scenarioItems: {
                            ...state.scenarioItems,
                            ...connectData.connectData
                        }
                    }));
                }
            },
            setDataEdge: (edge, conditions, newConditionsType) => {
                const from = edge.source;
                const to = edge.target;

                if (!from || !to) {
                    return;
                }

                const { scenarioItems } = get();

                const data: onConnectData = {
                    from: lodash.cloneDeep(scenarioItems[from]),
                    to: lodash.cloneDeep(scenarioItems[to])
                };

                if (data.from.id && data.to.id) {
                    if (conditions.length) {
                        data.to.conditions[`${from}-${to}`] = conditions;
                        data.from.condition_types = newConditionsType;
                        data.from.next = null;

                        for (const key of Object.keys(data.from.nodes)) {
                            if (data.from.nodes[key] === to) {
                                delete data.from.nodes[key];
                            }
                        }

                        data.from.nodes[conditions.join("_")] = to;
                    } else {
                        data.to.conditions[`${from}-${to}`] = [];
                        data.from.condition_types = [];

                        delete data.from.nodes[conditions.join("_")];
                    }
                }

                set((state) => ({
                    edges: [...state.edges.map(item => {
                        if (item.id === edge.id) {
                            return {
                                ...item,
                                data: conditions
                            };
                        }
                        return item;
                    })],
                    scenarioItems: {
                        ...state.scenarioItems,
                        ...{
                            [from]: data.from,
                            [to]: data.to,
                        }
                    },
                    currentEdge: {
                        ...state.currentEdge,
                        data: conditions
                    }
                }));
            },
            setLabelEdge: (edge, label) => {
                const edgeInd = get().edges.indexOf(edge);
                set(state => {
                    state.edges[edgeInd].label = label;
                });
            },
            changeItemDuration: (data) => {
                set(state => {
                    state.scenarioItems[`${data.scanerioItemId}`].duration = data.duration ? data.duration : state.scenarioItems[`${data.scanerioItemId}`].duration;
                    state.scenarioItems[`${data.scanerioItemId}`].blackout_duration = data.blackout_duration ? data.blackout_duration : state.scenarioItems[`${data.scanerioItemId}`].blackout_duration;
                });
            },
            checkValid: () => {
                const { scenarioItems, edges, scenarioData } = get();

                const newScenarioItems = lodash.cloneDeep(scenarioItems);

                for (const edge of edges) {
                    if (!newScenarioItems[edge.source].next) {
                        if (!edge.data?.length) {
                            newScenarioItems[edge.source].next = edge.target;
                        }
                    } else {
                        if (newScenarioItems[edge.source].next !== edge.target && !edge.data?.length) {
                            set({
                                error: {
                                    title: "notification.error.create_scenario.save.label",
                                    body: "notification.error.create_scenario.save.multiple_unconditional_jumps",
                                    data: {
                                        name: newScenarioItems[edge.source].content
                                    }
                                }
                            });
                            return false;
                        }
                    }
                }

                for (const key of Object.keys(newScenarioItems)) {
                    if (!newScenarioItems[key].next && key !== "end") {
                        set({
                            error: {
                                title: "notification.error.create_scenario.save.label",
                                body: "notification.error.create_scenario.save.missing_jump_unconditionally",
                                data: {
                                    name: newScenarioItems[key].content
                                }
                            }
                        });
                        return false;
                    } else if (Object.keys(newScenarioItems[key].conditions).length === 0 && key !== "end" && key !== "start") {
                        set({
                            error: {
                                title: "notification.error.create_scenario.save.label",
                                body: "notification.error.create_scenario.save.missing_entries",
                                data: {
                                    name: newScenarioItems[key].content
                                }
                            }
                        });
                        return false;
                    }
                }

                if (!checkStart(scenarioItems)) {
                    set({
                        error: {
                            title: "notification.error.create_scenario.save.label",
                            body: "notification.error.create_scenario.save.missing_start"
                        }
                    });
                    return false;
                }

                if (!checkEnd(scenarioItems)) {
                    set({
                        error: {
                            title: "notification.error.create_scenario.save.label",
                            body: "notification.error.create_scenario.save.missing_end"
                        }
                    });
                    return false;
                }

                if (!scenarioData.name.length) {
                    set({
                        error: {
                            title: "notification.error.create_scenario.save.label",
                            body: "notification.error.create_scenario.save.missing_name"
                        }
                    });
                    return false;
                }

                return true;
            },
            createScenario: async () => {
                const { scenarioItems, nodes, edges, editedScenario, isEdited, scenarioData } = get();

                const newDataNodes: Record<string, Node> = {};
                const newDataEdges: Record<string, Edge> = {};
                const newScenarioItems = lodash.cloneDeep(scenarioItems);

                for (const node of nodes) {
                    newDataNodes[node.id] = {
                        id: node.id,
                        position: node.position,
                        data: {
                            label: node.data.label
                        },
                        type: node.type
                    };
                }

                for (const edge of edges) {
                    newDataEdges[edge.id] = {
                        id: edge.id,
                        target: edge.target,
                        source: edge.source,
                        data: edge.data
                    };

                    if (!edge.data?.length) {
                        newScenarioItems[edge.source].next = edge.target;
                    }
                }

                delete newScenarioItems.start;
                delete newScenarioItems.end;

                const createData: ICreateScenarioData = {
                    id: isEdited ? editedScenario.id : "",
                    name: scenarioData.name,
                    description: scenarioData.description,
                    in_playlist: false,
                    duration: 0,
                    preview: "",
                    scenario_network_nodes: newDataNodes,
                    scenario_network_edges: newDataEdges,
                    scenario_items: newScenarioItems
                };

                try {
                    if (isEdited) {
                        return await updateScenarioApi(createData).then(() => {
                            return true;
                        });
                    } else {
                        return await createScenarioApi(createData).then((data) => {
                            if (data?.id) {
                                set(state => {
                                    state.isEdited = true;
                                    state.editedScenario.id = data.id;
                                });
                                return true;
                            } else {
                                return false;
                            }
                        });
                    }
                } catch {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.save"
                        };
                    });
                    return false;
                }
            },
            getScenario: async (scenarioId) => {
                const { setDefaultState } = get();

                if (localStorage.getItem("create_scenario")) {
                    const prevState: ICreateScenarioStore = JSON.parse(localStorage.getItem("create_scenario")!).state;

                    for (const value of Object.values(prevState.edges)) {
                        addTransitions(value.source, value.target);
                    }

                    const id = prevState.editedScenario.id;

                    if (id === scenarioId) {
                        return;
                    }
                }

                await getOneScenarioApi(scenarioId).then(data => {
                    if (data) {
                        set({
                            isEdited: true,
                            editedScenario: data
                        });

                        setDefaultState();
                    }
                }).catch(() => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                });
            }
        }),
        {
            name: "create_scenario",
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => ({
                nodes: state.nodes,
                edges: state.edges,
                scenarioData: state.scenarioData,
                scenarioItems: state.scenarioItems,
                editedScenario: state.editedScenario,
                isEdited: state.isEdited,
            })
        }
    ))
);
