import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Button, Select } from "../../../components/UI";
import { downloadStats } from "../../../api/analytic-api";
import { useTranslation } from "i18nano";


interface IProps {
    startDate: string;
    endDate: string;
    terminalId: string;
    contentId: string;
    statsType: string;
}


interface IOptionShort {
    id: string;
    name: string;
}


const DownloadStats: React.FC<IProps> = ({ startDate, endDate, terminalId, contentId, statsType }) => {    
    const t = useTranslation();

    const [generalStats, setGeneralStats] = useState(statsType === "general" ? true : false);
    const [demographicStats, setDemographicStats] = useState(statsType === "demographic" ? true : false);

    const defaultPeriodItem: IOptionShort = {name: t("analytic.month"), id: "month"};
    const defaultPeriodOptions: IOptionShort[] = [
        {name: t("analytic.day"), id: "day"}, {name: t("analytic.week"), id: "week"}, {name: t("analytic.month"), id: "month"}
    ];
    const [periodOptions, setPeriodOptions] = useState(defaultPeriodOptions);
    const [ itemPeriod, setItemPeriod ] = useState(defaultPeriodItem.id);

    const changePeriodFilterHandler = (value: string) => {
        setItemPeriod(value);
    };


    return (
        <div style={{ padding: "0 18px" }}>
            <div style={{ paddingRight: "36px" }}>
                <div className={styles.control_panel_container}>
                    <div className={styles.panel_left}>
                        <div className={styles.dropdown_block}>
                            <label className={styles.label}>{t("analytic.choose_period")}</label>
                            <div className={styles.dropdown}>
                                <Select 
                                    options={periodOptions} 
                                    value={itemPeriod}
                                    setValue={changePeriodFilterHandler} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <input style={{ margin: "10px 10px 10px 0" }} type="checkbox" id="general-stats" checked={generalStats} onChange={() => setGeneralStats(!generalStats)} />
                    <label htmlFor="general-stats">{t("analytic.include_general")}</label>
                </div>
                <div style={{ paddingBottom: "18px"}}>
                    <input style={{ margin: "10px 10px 10px 0" }} type="checkbox" id="demographic-stats" checked={demographicStats} onChange={() => setDemographicStats(!demographicStats)} />
                    <label htmlFor="demographic-stats">{t("analytic.include_demography")}</label>
                </div> 
            </div>
            <div style={{ float: "right" }}>
                <Button variant={"primary"} onClick={() => {
                    downloadStats({
                        start_date: startDate,
                        end_date: endDate,
                        terminal_id: terminalId,
                        content_id: contentId,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        period: itemPeriod,
                        include_general: generalStats,
                        include_demographic: demographicStats,
                    });
                }}>Download</Button>
            </div>
        </div>
    );
};

export default DownloadStats;
