import React from "react";
import AbsoluteRelativeChart from "./AbsoluteRelativeChart";
import AnalyticCard, { IProps } from "../GeneralAnalytics/AnalyticCard/AnalyticCard";
import GenderAgeBar, { IBarChartProps } from "./GenderAgeBar/GenderAgeBar";
import Datepicker from "./Datepicker/Datepicker";
import { useAnalyticStore } from "../../../stores";
import { IChartProps } from "./AbsoluteRelativeChart/AbsoluteRelativeChart";
import { useTranslation } from "i18nano";


const Demography = () => {
    const t = useTranslation();

    const { fetchDemography, demographyAnalytic } = useAnalyticStore(state => state);

    /**
     * Props for cards
     */
    const maleViews: IProps = {
        headerLabel: t("analytic.demographic.male.label"),
        total: demographyAnalytic?.demographic_stats?.male_views_percent,
        footerLabel: `${demographyAnalytic?.demographic_stats?.male_views ?? ""} ${t("analytic.demographic.male.unit")}`,
        growth: null,
        icon: <></>,
        tooltipText: t("analytic.demographic.male.help")
    };
    const femaleViews: IProps = {
        headerLabel: t("analytic.demographic.female.label"),
        total: demographyAnalytic?.demographic_stats?.female_views_percent,
        footerLabel: `${demographyAnalytic?.demographic_stats?.female_views ?? ""} ${t("analytic.demographic.female.unit")}`,
        growth: null,
        icon: <></>,
        tooltipText: t("analytic.demographic.female.help")
    };
    const topDemographicGroup: IProps = {
        headerLabel: t("analytic.demographic.top_demographic_group.label"),
        total: demographyAnalytic?.demographic_stats?.top_demographic_group,
        footerLabel: t("analytic.demographic.top_demographic_group.unit"),
        growth: null,
        icon: <></>,
        tooltipText: t("analytic.demographic.top_demographic_group.help")
    };
    const maleAverageAttention: IProps = {
        headerLabel: t("analytic.demographic.male_avg_attention.label"),
        total: demographyAnalytic?.demographic_stats?.male_avg_attention,
        footerLabel: t("analytic.demographic.male_avg_attention.unit"),
        growth: null,
        icon: <></>,
        tooltipText: t("analytic.demographic.male_avg_attention.help")
    };
    const femaleAverageAttention: IProps = {
        headerLabel: t("analytic.demographic.female_avg_attention.label"),
        total: demographyAnalytic?.demographic_stats?.female_avg_attention,
        footerLabel: t("analytic.demographic.female_avg_attention.unit"),
        growth: null,
        icon: <></>,
        tooltipText:  t("analytic.demographic.female_avg_attention.help")
    };
    const topAttentionTime: IProps = {
        headerLabel: t("analytic.demographic.top_attention_time.label"),
        total: demographyAnalytic?.demographic_stats?.attention_top_demographic_group,
        footerLabel: `${demographyAnalytic?.demographic_stats?.top_attention_time ?? ""} ${t("analytic.demographic.top_attention_time.unit")}`,
        growth: null,
        icon: <></>,
        tooltipText:  t("analytic.demographic.top_attention_time.help")
    };


    /**
     * Props for charts
     */
    const genderAgeSplit: IChartProps = {
        headerLabel: t("analytic.demographic.charts.gender_age_split.title"),
        labels: demographyAnalytic?.demographic_stats?.gender_age_split?.labels ?? [],
        data: demographyAnalytic?.demographic_stats?.gender_age_split?.data
    };
    const genderSplit: IChartProps = {
        headerLabel: t("analytic.demographic.charts.gender_split.title"),
        labels: demographyAnalytic?.demographic_stats?.gender_split?.labels ?? [],
        data: demographyAnalytic?.demographic_stats?.gender_split?.data
    };
    const ageSplit: IChartProps = {
        headerLabel: t("analytic.demographic.charts.age_split.title"),
        labels: demographyAnalytic?.demographic_stats?.age_split?.labels ?? [],
        data: demographyAnalytic?.demographic_stats?.age_split?.data
    };
    const emotionsSplit: IChartProps = {
        headerLabel: t("analytic.demographic.charts.emotions_split.title"),
        labels: demographyAnalytic?.demographic_stats?.emotions_split?.labels ?? [],
        data: demographyAnalytic?.demographic_stats?.emotions_split?.data
    };

    /**
     * Props for Average Bar Charts
     */
    const totalViewsAcrossAvgWeek: IBarChartProps = {
        labels: demographyAnalytic?.demographic_stats?.total_views_across_avg_week?.labels,
        headerLabel: t("analytic.demographic.charts.views_week.title"),
        datasets: demographyAnalytic?.demographic_stats?.total_views_across_avg_week?.dataset,
    };
    const totalViewsAcrossAvgDay: IBarChartProps = {
        labels: demographyAnalytic?.demographic_stats?.total_views_across_avg_day?.labels,
        headerLabel: t("analytic.demographic.charts.views_day.title"),
        datasets: demographyAnalytic?.demographic_stats?.total_views_across_avg_day?.dataset,
    };
    const totalAttentionAcrossAvgWeek: IBarChartProps = {
        labels: demographyAnalytic?.demographic_stats?.total_attention_across_avg_week?.labels,
        headerLabel: t("analytic.demographic.charts.attention_week.title"),
        datasets: demographyAnalytic?.demographic_stats?.total_attention_across_avg_week?.dataset,
    };
    const totalAttentionAcrossAvgDay: IBarChartProps = {
        labels: demographyAnalytic?.demographic_stats?.total_attention_across_avg_day?.labels,
        headerLabel: t("analytic.demographic.charts.attention_day.title"),
        datasets: demographyAnalytic?.demographic_stats?.total_attention_across_avg_day?.dataset,
    };
    const emotionsGenderplit: IBarChartProps = {
        headerLabel: t("analytic.demographic.charts.emotions_gender_split.title"),
        labels: demographyAnalytic?.demographic_stats?.emotions_gender_split?.labels ?? [],
        datasets: demographyAnalytic?.demographic_stats?.emotions_gender_split?.dataset,
        stackedBar: false,
    };
    const emotionsAgeplit: IBarChartProps = {
        headerLabel: t("analytic.demographic.charts.emotions_age_split.title"),
        labels: demographyAnalytic?.demographic_stats?.emotions_age_split?.labels ?? [],
        datasets: demographyAnalytic?.demographic_stats?.emotions_age_split?.dataset,
        stackedBar: false,
    };

    return (
        <div style={{ display: "grid", gridTemplateRows: "auto", gap: 20, marginBottom: "20px" }}>
            <Datepicker fetchStats={fetchDemography} statsType="demographic"/>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(6, 1fr)", gap: 20 }}>
                <AnalyticCard { ...maleViews } />
                <AnalyticCard { ...femaleViews } />
                <AnalyticCard { ...topDemographicGroup } />
                <AnalyticCard { ...maleAverageAttention } />
                <AnalyticCard { ...femaleAverageAttention } />
                <AnalyticCard { ...topAttentionTime } />
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 20 }}>
                <AbsoluteRelativeChart {...genderAgeSplit}/>
                <AbsoluteRelativeChart {...genderSplit}/>
                <AbsoluteRelativeChart {...ageSplit}/>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 20 }}>
                <GenderAgeBar {...totalViewsAcrossAvgWeek}/>
                <GenderAgeBar {...totalViewsAcrossAvgDay}/>
                <GenderAgeBar {...totalAttentionAcrossAvgWeek}/>
                <GenderAgeBar {...totalAttentionAcrossAvgDay}/>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 20 }}>
                <AbsoluteRelativeChart {...emotionsSplit}/>
                <GenderAgeBar {...emotionsGenderplit}/>
                <GenderAgeBar {...emotionsAgeplit}/>
            </div>
        </div>
    );
};

export default Demography;