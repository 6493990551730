import React, { useState } from "react";
import { IScenario } from "../../../types/scenarioList";
import "./ScenarioItem.scss";
import { useScenarioStore } from "../../../stores";
import { IconCloseSquare, IconScenarioFile, IconEdit, ModalConfirm, Tooltip } from "../../../components/UI";
import { Link } from "react-router-dom";
import { useTranslation } from "i18nano";
import ContentContainer from "../../../components/ContentContainer";


interface IProps {
    scenario: IScenario
}

const ScenarioItem: React.FC<IProps> = ({ scenario }) => {
    const { deleteScenario, selectScenario } = useScenarioStore(state => state);

    const t = useTranslation();
    const [showConfirm, setShowConfirm] = useState(false);

    return (
        <div className="table-item cols-3">
            <div className={"checkbox-container"}>
                <input
                    type="checkbox"
                    checked={scenario.isChecked}
                    onChange={() => selectScenario({ selectState: !scenario.isChecked, selectedData: scenario.id })}
                />
            </div>
            <div className={"preview-container"}>
                <div className={scenario.preview ? "preview" : ""}>
                    {scenario.preview?.length
                        ? <ContentContainer url={scenario.preview} contentType={"preview"} />
                        : <IconScenarioFile color={"black"} size={40} />
                    }
                </div>
                <div className={"name"}>{scenario.name}</div>
            </div>
            <div>{scenario.description}</div>
            <div className={"settings-container"}>
                <Tooltip text={t("actions.change") + " " + t("scenario.one")}>
                    <span className="action-container">
                        <Link to={`/scenarios/edit/${scenario.id}`}>
                            <IconEdit color={"blue"} size={25} />
                        </Link>
                    </span>
                </Tooltip>
                <Tooltip text={t("actions.delete") + " " + t("scenario.one")}>
                    <span className="action-container" onClick={() => setShowConfirm(true)}><IconCloseSquare color={"red"} size={25} /></span>
                </Tooltip>
            </div>
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => deleteScenario([scenario.id.toString()])} onCancel={() => { }}>
                <p>{t("confirm.delete", { name: scenario.name })}</p>
            </ModalConfirm>
        </div>
    );
};

export default ScenarioItem;