import classnames from "classnames";
import { useTranslation } from "i18nano";
import React, { RefObject, useEffect, useState } from "react";
import { Edge, Node } from "reactflow";
import { ExpandableWrapperHeight } from "../../../../components/UI";
import { useCreateScenarioStore } from "../../../../stores";
import { getCvLabels, ICvLabels } from "./consts";
import styles from "./styles.module.scss";

const ContentList = () => {
    const { nodes, edges, scenarioItems, currentNode, currentEdge, onNodeClick, onEdgeClick } = useCreateScenarioStore(state => state);
    const t = useTranslation();

    const [conditionViewMode, setConditionViewMode] = useState<Record<string, ICvLabels>>({});

    const [relevantNodes, setRelevantNodes] = useState<Node[]>([]);
    const [relevantEdges, setRelevantEdges] = useState<Edge[]>([]);

    const [expandedNodes, setExpandedNodes] = useState(true);
    const [expandedEdges, setExpandendEdges] = useState(false);

    const nodesRefs = nodes.reduce((acc: Record<string, RefObject<HTMLDivElement>>, node) => {
        acc[node.id] = React.createRef();
        return acc;
    }, {});

    const edgesRefs = edges.reduce((acc: Record<string, RefObject<HTMLDivElement>>, edge) => {
        acc[edge.id] = React.createRef();
        return acc;
    }, {});

    useEffect(() => {
        for (const edge of edges) {
            const label = getCvLabels(edge.data, t("triggers.cv"));
            setConditionViewMode(prev => ({
                ...prev,
                [edge.id]: label
            }));
        }
    }, [t, edges]);

    useEffect(() => {
        if (currentNode) {
            setExpandedNodes(true);

            nodesRefs[currentNode.id].current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
        if (currentEdge) {
            setExpandendEdges(true);

            edgesRefs[currentEdge.id].current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [currentEdge, currentNode, edgesRefs, nodesRefs]);

    useEffect(() => {
        setRelevantNodes(nodes.filter(node => !["start", "end"].includes(node.id)));
        setRelevantEdges(edges.filter(edge => edge.source !== "start" && edge.target !== "end"));
    }, [nodes, edges]);

    return (
        <div className={styles.content_list_container}>
            <div>
                <h2 onClick={() => setExpandedNodes(!expandedNodes)}>
                    {t("create_scenario.nodes_label")}
                    <span className={classnames(styles.icon_plus, { [styles.active]: expandedNodes })}></span>
                </h2>
                {relevantNodes.map(node =>
                    <ExpandableWrapperHeight isExpanded={expandedNodes} key={node.id}>
                        <div
                            className={classnames(
                                styles.item_container,
                                { [styles.active]: currentNode?.id === node.id }
                            )}
                            onClick={() => onNodeClick(node)}
                            ref={nodesRefs[node.id]}
                        >
                            <span className={styles.node_name}>{node.data.label}</span>
                            <div>
                                <span>{t("create_scenario.settings_node.duration")}: {scenarioItems[node.id]?.duration}s</span>
                                <span>{t("create_scenario.settings_node.blackout_duration")}: {scenarioItems[node.id]?.blackout_duration}ms</span>
                            </div>
                        </div>
                    </ExpandableWrapperHeight>
                )}
            </div>
            <div>
                <h2 onClick={() => setExpandendEdges(!expandedEdges)}>
                    {t("create_scenario.edges_label")}
                    <span className={classnames(styles.icon_plus, { [styles.active]: expandedEdges })}></span>
                </h2>
                {relevantEdges.map(edge =>
                    <ExpandableWrapperHeight isExpanded={expandedEdges} key={edge.id}>
                        <div
                            className={classnames(
                                styles.item_container,
                                { [styles.active]: currentEdge?.id === edge.id }
                            )}
                            onClick={() => onEdgeClick(edge)}
                            ref={edgesRefs[edge.id]}
                        >
                            <span>{scenarioItems[edge.source]?.content} {t("create_scenario.to_label")} {scenarioItems[edge.target]?.content}</span>

                            {Object.values(conditionViewMode[edge.id]).length
                                ? <span className={styles.conditions}>
                                    <h3>{t("triggers.label")}</h3>
                                    {conditionViewMode[edge.id].emotion &&
                                        <div className={"name-item"}>
                                            {t("triggers.cv.emotion.label")}
                                            <span>&nbsp;({conditionViewMode[edge.id].emotion})</span>
                                        </div>
                                    }
                                    {conditionViewMode[edge.id].gender &&
                                        <div className={"name-item"}>
                                            {t("triggers.cv.gender.label")}
                                            <span>&nbsp;({conditionViewMode[edge.id].gender})</span>
                                        </div>
                                    }
                                    {conditionViewMode[edge.id].age &&
                                        <div className={"name-item"}>
                                            {t("triggers.cv.age_group.label")}
                                            <span>&nbsp;({conditionViewMode[edge.id].age})</span>
                                        </div>
                                    }
                                </span>
                                : <></>
                            }
                        </div>
                    </ExpandableWrapperHeight>
                )
                }
            </div >
        </div >
    );
};

export default ContentList;