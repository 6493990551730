import axios from "./axios";
import { IContentList } from "../types/contentList";
import { IFilters } from "../types/default";

interface IContentGetShortApiResponse {
    data: IContentShort[];
}

interface IContentShort {
    id: string;
    name: string;
}

export const getOneContentApi = async (url: string): Promise<string | undefined> => {
    const res = await fetch(url, {
        credentials: "include"
    });

    if (!res) {
        return;
    }

    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);

    return imageObjectURL;
};

export const getAllContentApi = async (filters: IFilters): Promise<IContentList | undefined> => {
    return await axios.post("/content/", {
        page: filters.page,
        per_page: filters.perPage,
        filters: filters.filters,
        order_by: filters.orderBy,
        order_desc: filters.orderDesk
    });
};

export const getContentListShortApi = async (): Promise<IContentGetShortApiResponse | undefined> => {
    return await axios.get("/content/get-short/");
}

export const createContentApi = async (content: FormData) => {
    await axios.post("/content/create/", content);
};

export const deleteContentApi = async (data: string[]) => {
    return await axios.delete("/content/delete/", { data });
};