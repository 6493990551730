export interface ICvLabels {
    emotion?: string;
    gender?: string;
    age?: string;
}

export const getCvLabels = (condition: string, translation: any): ICvLabels => {
    const emotionNames = translation.emotion;
    const genderName = translation.gender;
    const ageName = translation.age_group;
    const labels = translation.labels;

    let newEmotion = "";
    let newGender = "";
    let newAge = "";

    const longName: ICvLabels = {};

    for (const item of condition.split("_")) {
        if (item) {
            if (Object.keys(emotionNames).includes(item)) {
                if (newEmotion.length) {
                    newEmotion += ` ${labels.or} ${emotionNames[item]}`;
                } else {
                    newEmotion += emotionNames[item];
                }
            } else if (Object.keys(genderName).includes(item)) {
                if (newGender.length) {
                    newGender += ` ${labels.or} ${genderName[item]}`;
                } else {
                    newGender += genderName[item];
                }
            } else if (Object.keys(ageName).includes(item)) {
                if (newAge.length) {
                    newAge += ` ${labels.or} ${ageName[item]}`;
                } else {
                    newAge += ageName[item];
                }
            }
        }
    }

    if (newEmotion.length) {
        longName.emotion = newEmotion;
    }
    if (newGender.length) {
        longName.gender = newGender;
    }
    if (newAge.length) {
        longName.age = newAge;
    }

    return longName;
};