import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import styles from "./styles.module.scss";
import DemoSetup from "./DemoSetup";
import DisplaySetup from "./DisplaySetup";
import NetworkSetup from "./NetworkSetup";
import SetupList from "./SetupList";
import classnames from "classnames";
import { useTerminalSetupStore } from "../../stores";
import { Loader, useNotification } from "../../components/UI";
import { useTranslation } from "i18nano";

export type TSetup = "display" | "network" | "demo";

const TerminalSetupPage = () => {
    const { terminal, fetchTerminal, error } = useTerminalSetupStore(state => state);
    const { addNotific } = useNotification();
    const t = useTranslation();

    const [setupPageType, setSetupPageType] = useState<TSetup>("network");
    const [setupPage, setSetupPage] = useState<React.ReactElement>(<></>);

    const params = useParams();

    useEffect(() => {
        if (params.terminalId) {
            fetchTerminal(params.terminalId);
        }
    }, [params]);

    useEffect(() => {
        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body, { name: t("content.genitive") }),
                type: "danger"
            });
        }
    }, [error]);

    useEffect(() => {
        switch (setupPageType) {
            case "network": {
                setSetupPage(<NetworkSetup />);
                break;
            }
            case "display": {
                setSetupPage(<DisplaySetup />);
                break;
            }
            case "demo": {
                setSetupPage(<DemoSetup />);
                break;
            }
        }
    }, [setupPageType]);

    if (!terminal) {
        return (
            <PageContainer className={styles.loader_container}>
                <Loader />
            </PageContainer>
        );
    }

    return (
        <PageContainer className={styles.root}>
            <div className={styles.control_panel}>
                <h2>
                    <span className={styles.terminal_name}>{terminal.name}</span>
                    <span className={classnames(styles.connection_state, { [styles.active]: terminal.server_connected })}>
                        {terminal.server_connected ? "Server connected" : "No server connected"}
                    </span>
                </h2>
                <SetupList setupPageType={setupPageType} setSetupPageType={setSetupPageType} />
            </div>
            <div className={styles.setup_container}>
                {setupPage}
            </div>
        </PageContainer>
    );
};

export default TerminalSetupPage;