import classnames from "classnames";
import React from "react";
import styles from "./styles.module.scss";


interface IProps {
    children: React.ReactElement[] | React.ReactElement;
}

const colsList: Record<number, string> = {
    4: styles.cols_4,
    5: styles.cols_5
};

const SortPanelContainer: React.FC<IProps> = ({ children }) => {
    const cols = React.Children.map(children, (item => item)).length;

    return (
        <div className={classnames(styles.sort_panel_container, colsList[cols])}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child);
            })}
        </div>
    );
};

export default SortPanelContainer;