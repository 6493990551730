import { useState } from "react";

const useExpandableWrapper = (minWidth: number, isExpanded: boolean) => {
    const [childWidth, setChildWidth] = useState<string | number>(isExpanded ? "100%" : minWidth);

    const onEnter = (element: HTMLElement) => {
        setChildWidth("100%");
    };

    const onEntered = () => {
        setChildWidth("100%");
    };

    const onExit = (element: HTMLElement) => {
        const width = element.offsetWidth;
        setChildWidth(width);
    };

    const onExiting = () => {
        const width = minWidth;
        setChildWidth(width);
    };

    return {
        onEnter,
        onEntered,
        onExit,
        onExiting,
        childWidth
    };
};

export default useExpandableWrapper;