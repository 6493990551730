import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { IAnaliticStore } from "../../types/analytics";
import { getDemographyApi, getGeneralAnalyticApi } from "../../api/analytic-api";


export const useAnalyticStore = create<IAnaliticStore>()(
    immer(
        (set) => ({
            generalAnalytic: undefined,
            demographyAnalytic: undefined,
            loading: false,
            error: undefined,
            clearStore: () => {
                set(state => {
                    state.generalAnalytic = undefined;
                    state.loading = false;
                    state.error = undefined;
                });
            },
            fetchGeneralAnalytic: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getGeneralAnalyticApi(filters).then(data => {
                    set((state) => {
                        state.generalAnalytic = data;
                    });
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set((state) => {
                        state.loading = false;
                    });
                });
            },
            fetchDemography: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getDemographyApi(filters).then((data) => {
                    set((state) => {
                        state.demographyAnalytic = data;
                    });
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set((state) => {
                        state.loading = false;
                    });
                });
            }
        })
    )
);