import classnames from "classnames";
import React, { useRef, useState } from "react";
import { ExpandableWrapperHeight } from "../ExpandableWrapper";
import { IconArrowDown } from "../Icons";
import styles from "./styles.module.scss";

export interface IOption {
    id: string | number;
    name: string;
}

interface IProps {
    options: Array<IOption>;
    value: any;
    setValue: (value: any) => void;
    closeOnSelect?: boolean;
}

const Select: React.FC<IProps> = ({ value, options, setValue, closeOnSelect = true }) => {
    const selectRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState<"up" | "down">("up");

    const selectContainerStyles = {
        [styles.open]: isOpen,
        [styles.up]: position === "up",
        [styles.down]: position === "down",
    };

    const onOpenSelect = () => {
        if (selectRef && selectRef.current) {
            const selectHeight = selectRef.current.getBoundingClientRect().height + selectRef.current.getBoundingClientRect().bottom;
            if (selectHeight < (window.innerHeight / 2)) {
                setPosition("down");
            } else {
                setPosition("up");
            }
        }
        setIsOpen(true);
    };

    const onSelectHandler = (val: any) => {
        setValue(val);

        if (closeOnSelect) {
            setIsOpen(false);
        }
    };

    if (!options.length) {
        return (
            <div className={classnames(styles.select_container, selectContainerStyles)} ref={selectRef} onMouseEnter={onOpenSelect} onMouseLeave={() => setIsOpen(false)}>
                <div className={styles.selected_container}>
                    No options
                </div>
                <span className={styles.select_icon}>
                    <IconArrowDown color="default" />
                </span>
            </div>
        );
    }

    return (
        <div className={classnames(styles.select_container, selectContainerStyles)} ref={selectRef} onMouseEnter={onOpenSelect} onMouseLeave={() => setIsOpen(false)}>
            <div className={styles.selected_container}>
                {options.filter(item => item.id === value)[0].name}
            </div>
            <div className={classnames(styles.option_list)}>
                {options.map((option) =>
                    <ExpandableWrapperHeight isExpanded={isOpen} key={option.id}>
                        <div className={classnames(styles.option, { [styles.active]: option.id === value })} onClick={() => onSelectHandler(option.id)}>{option.name}</div>
                    </ExpandableWrapperHeight>
                )}
            </div>
            <span className={styles.select_icon}>
                <IconArrowDown color="default" />
            </span>
        </div>
    );
};

export default Select;