import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { IScenarioStore } from "../../types/scenarioList";
import { defaultState, defaultFilters } from "../consts";
import { deleteScenarioApi, getAllScenarioApi } from "../../api/scenario-api";


export const useScenarioStore = create<IScenarioStore>()(
    immer(
        (set, get) => ({
            scenarioList: defaultState,
            loading: false,
            error: undefined,
            clearStore: () => {
                set(state => {
                    state.scenarioList = defaultState;
                    state.loading = false;
                    state.error = undefined;
                });
            },
            fetchScenario: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getAllScenarioApi(filters).then((data) => {
                    set(state => {
                        state.scenarioList = { ...state.scenarioList, ...data };
                    });
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            deleteScenario: async (scenario_ids) => {
                const { fetchScenario } = get();

                await deleteScenarioApi(scenario_ids).then(() => {
                    fetchScenario(defaultFilters);
                }).catch(() => {
                    set((state) => {
                        state.loading = false;
                    });
                }).finally(() => {
                    set((state) => {
                        state.loading = false;
                    });
                });
            },
            selectScenario: (selectStatus) => {
                set((state) => {
                    state.scenarioList.data = [...state.scenarioList.data.map(content => {
                        if (selectStatus.selectedData === "all" || selectStatus.selectedData === content.id) {
                            return { ...content, isChecked: selectStatus.selectState };
                        }
                        return content;
                    })];
                });
            }
        })
    )
);