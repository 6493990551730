import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import classNames from "classnames";
import { useTranslation } from "i18nano";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    ArcElement
);

const optionsBar = {
    responsive: true,
    plugins: {
        legend: {
            position: "bottom" as const,
            labels: {
                pointStyle: "rectRounded",
                usePointStyle: true
            }
        }
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: false,
        },
    },
};

const optionsPie = {
    responsive: true,
    plugins: {
        legend: {
            position: "right" as const,
            fullSize: true,
            labels: {
                pointStyle: "rectRounded",
                usePointStyle: true
            }
        }
    },
};

export interface IChartProps {
    headerLabel: string;
    labels: string[];
    data: number[] | undefined;
    withPieOption?: boolean;
}

const AbsoluteRelativeChart: React.FC<IChartProps> = ({ headerLabel, labels, data, withPieOption = true }) => {
    const t: (path: string) => string = useTranslation();
    const [isPie, setIsPie] = useState(true);

    const colors: { [id: string] : string; } = {
        [t("analytic.chart_color_labels.kid_male")]: "rgba(21, 97, 172, 1)",
        [t("analytic.chart_color_labels.young_male")]: "rgba(28, 125, 239, 1)",
        [t("analytic.chart_color_labels.adult_male")]: "rgba(73, 152, 229, 1)",
        [t("analytic.chart_color_labels.old_male")]: "rgba(119, 178, 236, 1)",
        [t("analytic.chart_color_labels.kid_female")]: "rgba(234, 145, 137, 1)",
        [t("analytic.chart_color_labels.young_female")]: "rgba(228, 108, 98, 1)",
        [t("analytic.chart_color_labels.adult_female")]: "rgba(221, 71, 59, 1)",
        [t("analytic.chart_color_labels.old_female")]: "rgba(214, 17, 1, 1)",
        [t("analytic.chart_color_labels.ots")]: "rgba(56, 104, 195, 1)",
        [t("analytic.chart_color_labels.watchers")]: "rgba(0, 102, 43, 1)",
        [t("analytic.chart_color_labels.dwell_time")]: "rgba(56, 104, 195, 1)",
        [t("analytic.chart_color_labels.attention_time")]: "rgba(0, 102, 43, 1)",
        [t("analytic.chart_color_labels.happy")]: "rgba(10, 134, 35, 1)",
        [t("analytic.chart_color_labels.negative")]: "rgba(88, 88, 88, 1)",
        [t("analytic.chart_color_labels.neutral")]: "rgba(119, 178, 236, 1)",
        [t("analytic.chart_color_labels.surprise")]: "rgba(234, 145, 137, 1)",
        [t("analytic.chart_color_labels.male")]: "rgba(119, 178, 236, 1)",
        [t("analytic.chart_color_labels.female")]: "rgba(234, 145, 137, 1)",
        [t("analytic.chart_color_labels.kid")]: "rgba(21, 97, 172, 1)",
        [t("analytic.chart_color_labels.young")]: "rgba(28, 125, 239, 1)",
        [t("analytic.chart_color_labels.adult")]: "rgba(73, 152, 229, 1)",
        [t("analytic.chart_color_labels.old")]: "rgba(119, 178, 236, 1)",
    };

    /**
     * For pie chart
     */
    const charData = {
        labels: labels,
        datasets: [
            {
                label: "asd",
                data: data,
                backgroundColor: labels.map(
                    function(l) {
                        return colors[l]
                    }
                ),
                borderWidth: 1,
            },
        ],
    };

    /**
     * For bar chart
     */
    const datasets: {
        label: string,
        data: number[],
        backgroundColor: string,
        borderRadius: number
    }[] = [];
    data?.forEach((value: number, index: number) => {
        datasets.push({
            label: labels ? labels[index] : "",
            data: data.map((dataValue: number) => dataValue === value ? value : 0),
            backgroundColor: labels ? colors[labels[index]] : "",
            borderRadius: 4
        })
    })
    const barChart = { 
        labels: labels,
        datasets: datasets
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div>{headerLabel}</div>
                {withPieOption
                 ? <div className={styles.chart_controls}>
                        <div className={styles.chart_switcher}>
                            <div className={classNames(styles.switcher_item, { [styles.active]: isPie })} onClick={() => setIsPie(true)}>Pie</div>
                            <div className={classNames(styles.switcher_item, { [styles.active]: !isPie })} onClick={() => setIsPie(false)}>Bar</div>
                        </div>
                    </div>
                 : ""
                }
            </div>
            <div className={styles.chart_container}>
                {isPie && withPieOption
                    ? <Pie data={charData} options={optionsPie} />
                    : <Bar data={barChart} options={optionsBar} />
                }
            </div>
        </div>
    );
};

export default AbsoluteRelativeChart;