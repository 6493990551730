import classnames from "classnames";
import React, { useState } from "react";
import { EdgeProps, getBezierPath, BaseEdge, EdgeLabelRenderer } from "reactflow";
import { ExpandableWrapperWidth } from "../../../../components/UI";
import { useCreateScenarioStore } from "../../../../stores";
import styles from "./styles.module.scss";

const CustomEdge: React.FC<EdgeProps> = (props) => {
    const { chartConfig } = useCreateScenarioStore(state => state);
    const { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, label, selected } = props;
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition
    });

    const [showLabel, setShowLabel] = useState(false);

    return (
        <>
            <BaseEdge path={edgePath} {...props} />
            {label
                ? <EdgeLabelRenderer>
                    <div
                        style={{
                            position: "absolute",
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            pointerEvents: "all"
                        }}
                        className={classnames(styles.custom_label, { [styles.active]: selected || showLabel })}
                        onMouseEnter={() => setShowLabel(true)}
                        onMouseLeave={() => setShowLabel(false)}
                    >
                        <ExpandableWrapperWidth isExpanded={selected || showLabel || chartConfig.showMode === true} minWidth={76}>
                            <div className={styles.custom_label_conteiner}>
                                <span className={styles.custom_label_text}>{label}</span>
                            </div>
                        </ExpandableWrapperWidth>
                    </div>
                </EdgeLabelRenderer>
                : null
            }
        </>
    );
};

export default CustomEdge;
