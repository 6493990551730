import React, { useState } from "react";
import "./LoadContent.scss";
import LoadedItem from "./LoadedItem";
import { useContentStore } from "../../../stores";
import { Button, FileLoader } from "../../../components/UI";
import { useTranslation } from "i18nano";


interface IProps {
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadContent: React.FC<IProps> = ({ setIsShow }) => {
    const createContent = useContentStore(state => state.createContent);
    const t = useTranslation();

    const [contentList, setContentList] = useState<File[]>([]);

    const handleChange = (loadedFiles: Array<File>) => {
        setContentList(prev => [...prev, ...loadedFiles]);
    };

    const loadCancel = () => {
        setContentList([]);
        setIsShow(false);
    };

    const discardFileHandler = (val: number) => {
        setContentList(contentList.filter((_, index) => index !== val));
    };

    const sendContent = () => {
        if (contentList.length) {
            const formData = new FormData();
            for (const content of contentList) {
                formData.append("content", content);
            }
            createContent(formData);
        }
        loadCancel();
    };

    return (
        <div className={"loadForm"}>
            <div className={"loadInputFile"}>
                <FileLoader handleChange={handleChange} />
            </div>
            <div className={"loadedItems"}>
                {contentList.length === 0
                    ? null
                    : contentList.map((file, index) =>
                        <LoadedItem
                            file={file}
                            index={index}
                            discardFileHandler={discardFileHandler}
                            key={index}
                        />
                    )}
            </div>
            <div className={"loadSuccsess"}>
                <div className={"loadInfo"}>{t("upload_file.uploaded_files")}: {contentList.length}</div>
                <div className={"loadSuccsessButtons"}>
                    <Button variant={"secondary"} onClick={loadCancel}>{t("button.cancel")}</Button>
                    <Button variant={"primary"} onClick={sendContent}>{t("button.save")}</Button>
                </div>
            </div>
        </div>
    );
};

export default LoadContent;