import React, { useEffect, useState } from "react";
import "./RightControlPanel.scss";
import classnames from "classnames";
import SettingsEdge from "./SettingsEdge";
import AddContent from "./AddContent";
import { useCreateScenarioStore } from "../../../stores/";
import { IconArrowUp } from "../../../components/UI";
import { useTranslation } from "i18nano";
import SettingsNode from "./SettingsNode";


type TSwitchMenu = "settings" | "addContent";
type TSelectedElement = "edge" | "node";

const RightControlPanel = () => {
    const { currentEdge, currentNode, onPaneClick, edges, setLabelEdge, scenarioItems } = useCreateScenarioStore(state => state);

    const t = useTranslation();

    const [menuSelect, setMenuSelect] = useState<TSwitchMenu>("addContent");
    const [selectedElement, setSelectedElement] = useState<TSelectedElement>();

    useEffect(() => {
        if (currentEdge || currentNode) {
            setMenuSelect("settings");

            if (currentEdge) {
                setSelectedElement("edge");
            }

            if (currentNode) {
                setSelectedElement("node");
            }
        } else {
            setMenuSelect("addContent");
            setSelectedElement(undefined);
        }
    }, [currentEdge, currentNode]);

    const valueGender: string[] = Object.keys(t("triggers.cv.gender")).filter(item => item !== "label");
    const valueAge: string[] = Object.keys(t("triggers.cv.age_group")).filter(item => item !== "label");
    const valueEmotion: string[] = Object.keys(t("triggers.cv.emotion")).filter(item => item !== "label");

    useEffect(() => {
        for (const edge of edges) {
            let newLabel = "";

            if (!edge.data) {
                continue;
            }

            for (const item of edge.data) {
                if (valueAge.includes(item)) {
                    newLabel += newLabel.length ? ", " + t(`triggers.cv.age_group.${item}`) : t(`triggers.cv.age_group.${item}`);
                }
                if (valueEmotion.includes(item)) {
                    newLabel += newLabel.length ? ", " + t(`triggers.cv.emotion.${item}`) : t(`triggers.cv.emotion.${item}`);
                }
                if (valueGender.includes(item)) {
                    newLabel += newLabel.length ? ", " + t(`triggers.cv.gender.${item}`) : t(`triggers.cv.gender.${item}`);
                }
            }

            setLabelEdge(edge, newLabel);
        }
    }, [t, scenarioItems]);

    return (
        <div className={classnames("controlPanel rightControlPanel")}>
            <div className="controlsContainer">
                <div className="switchControlPanel" onClick={onPaneClick}>
                    <div className={classnames({ "active": menuSelect === "settings" })} onClick={() => (currentEdge || currentNode) ? setMenuSelect("settings") : null}>
                        <span>
                            {t("triggers.label")}
                        </span>
                        <IconArrowUp color={menuSelect === "settings" ? "blue" : "default"} />
                    </div>
                    <div className={classnames({ "active": menuSelect === "addContent" })} onClick={() => setMenuSelect("addContent")}>
                        <span>
                            {t("content.one")}
                        </span>
                        <IconArrowUp color={menuSelect === "addContent" ? "blue" : "default"} />
                    </div>
                </div>
                <div className="settings__addContent">
                    {menuSelect === "settings"
                        ? <>
                            {selectedElement === "edge" && currentEdge ? <SettingsEdge /> : null}
                            {selectedElement === "node" && currentNode ? <SettingsNode /> : null}
                        </>
                        : <AddContent />
                    }
                </div>
            </div>
        </div>
    );
};


export default RightControlPanel;