import { useTranslation } from "i18nano";
import React from "react";
import { useCreateScenarioStore } from "../../../../stores";
import styles from "./styles.module.scss";


const SettingsEdge = () => {
    const { currentEdge, setDataEdge } = useCreateScenarioStore(state => state);

    const t = useTranslation();

    const valueGender: string[] = Object.keys(t("triggers.cv.gender")).filter(item => item !== "label");
    const valueAge: string[] = Object.keys(t("triggers.cv.age_group")).filter(item => item !== "label");
    const valueEmotion: string[] = Object.keys(t("triggers.cv.emotion")).filter(item => item !== "label");

    const updateConditionsType = (newConditionsType: Array<string>, isConditionType: boolean, conditionType: string) => {
        const conditionTypeIndex = newConditionsType.indexOf(conditionType);
        if (isConditionType) {
            if (conditionTypeIndex === -1) {
                newConditionsType.push(conditionType);
            }
        } else if (conditionTypeIndex > -1) {
            newConditionsType.splice(conditionTypeIndex, 1);
        }
    };

    const setConditions = (condition: string) => {
        if (currentEdge) {
            let isAgeCondition = false;
            let isGenderCondition = false;
            let isEmotionCondition = false;
            let newConditions: string[] = currentEdge.data ? [...currentEdge.data] : [];
            const newConditionsType: string[] = [];

            if (newConditions.includes(condition)) {
                newConditions = newConditions.filter(item => item !== condition);
            } else {
                newConditions.push(condition);
            }

            for (const item of newConditions) {
                if (valueAge.includes(item)) {
                    isAgeCondition = true;
                }
                if (valueEmotion.includes(item)) {
                    isEmotionCondition = true;
                }
                if (valueGender.includes(item)) {
                    isGenderCondition = true;
                }
            }

            updateConditionsType(newConditionsType, isAgeCondition, "age_group");
            updateConditionsType(newConditionsType, isGenderCondition, "gender");
            updateConditionsType(newConditionsType, isEmotionCondition, "emotion");

            setDataEdge(currentEdge, newConditions, newConditionsType);
        }
    };

    return (
        <div className={styles.settings_container}>
            <ul>
                <li>{t("triggers.cv.gender.label")}</li>
                {valueGender.map(item => <li className={`${currentEdge?.data?.includes(item) ? styles.active : null}`} onClick={() => setConditions(item)} key={item}>{t(`triggers.cv.gender.${item}`)}</li>)}
            </ul>
            <ul>
                <li>{t("triggers.cv.age_group.label")}</li>
                {valueAge.map(item => <li className={`${currentEdge?.data?.includes(item) ? styles.active : null}`} onClick={() => setConditions(item)} key={item}>{t(`triggers.cv.age_group.${item}`)}</li>)}
            </ul>
            <ul>
                <li>{t("triggers.cv.emotion.label")}</li>
                {valueEmotion.map(item => <li className={`${currentEdge?.data?.includes(item) ? styles.active : null}`} onClick={() => setConditions(item)} key={item}>{t(`triggers.cv.emotion.${item}`)}</li>)}
            </ul>
        </div >
    );
};

export default SettingsEdge;