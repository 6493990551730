import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { Button, Input, Textarea } from "../../../../components/UI";
import { useCreatePlaylistStore } from "../../../../stores";
import "./PlaylistSettings.scss";


interface IProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlaylistSettings: React.FC<IProps> = ({ setShowModal }) => {
    const t = useTranslation();

    const { setPlaylistName, playlist } = useCreatePlaylistStore(state => state);

    const [playlistName, setPlaylistNamee] = useState(playlist.name);
    const [playlistDesc, setPlaylitDesc] = useState(playlist.description);

    const setPlaylistNameHandler = () => {
        setPlaylistName(playlistName, playlistDesc);
        setShowModal(false);
    };

    return (
        <div className={"playlistSettings-container"}>
            <div className={"item"}>
                <span>{t("label.name", { name: t("playlist.genitive") })}</span>
                <Input
                    value={playlistName}
                    onChange={setPlaylistNamee}
                    type={"text"}
                    placeholder={t("placeholder.text", { name: t("table_head.name") })}
                />
            </div>
            <div className={"item"}>
                <span>{t("label.description", { name: t("playlist.genitive") })}</span>
                <Textarea
                    value={playlistDesc}
                    onChange={setPlaylitDesc}
                    maxLength={100}
                    placeholder={t("placeholder.text", { name: t("table_head.description") })}
                />
            </div>
            <div className={"buttons-container"}>
                <Button variant={"secondary"} onClick={() => setShowModal(false)} >{t("button.cancel")}</Button>
                <Button variant={"primary"} onClick={setPlaylistNameHandler} >{t("button.save")}</Button>
            </div>
        </div>
    );
};

export default PlaylistSettings;