import React, { useEffect } from "react";
import { Button } from "../../../components/UI";
import styles from "./styles.module.scss";
import { demonstrationControlApi } from "../../../api/terminal-api";
import { useTerminalSetupStore } from "../../../stores";
import { useTranslation } from "i18nano";


const DemoSetup = () => {
    const t = useTranslation();

    const { terminal } = useTerminalSetupStore(state => state);

    const demoControlHandler = (start: boolean) => {
        if (!terminal) {
            return;
        }

        demonstrationControlApi({
            terminal_id: terminal.id,
            start: start
        });
    };

    if (!terminal) {
        return null;
    }

    return (
        <>
            <h2>{t("terminal_setup_page.demo_setup.title")}</h2>
            <h3>{t("terminal_setup_page.demo_setup.subtitle")}</h3>
            <div className={styles.root}>
                <Button variant="primary" onClick={() => demoControlHandler(true)}>{t("terminal_setup_page.demo_setup.start_button")}</Button>
                <Button variant="primary" onClick={() => demoControlHandler(false)}>{t("terminal_setup_page.demo_setup.stop_button")}</Button>
            </div>
        </>
    );
};

export default DemoSetup;