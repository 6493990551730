import classnames from "classnames";
import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { IconUploadFile, IconUploadFileOver } from "../Icons";
import styles from "./styles.module.scss";


interface IProps {
    handleChange: (files: Array<File>) => void
}

const FileLoader: React.FC<IProps> = ({ handleChange }) => {
    const [isOver, setIsOver] = useState(false);
    const t = useTranslation();

    const onLoadInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files;

        if (file) {
            const files = [];

            for (let i = 0; i < file.length; i += 1) {
                files.push(file[i]);
            }

            handleChange(files);
        }
    };

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const files = [];

        for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
            files.push(e.dataTransfer.files[i]);
        }

        handleChange(files);

        setIsOver(false);
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setIsOver(true);
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setIsOver(false);
    };

    return (
        <div
            className={classnames(styles.drop_zone, { [styles.drop_zone_over]: isOver })}
        >
            <div
                className={styles.drag_and_drop_container}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
            >
            </div>
            <div className={styles.label_container} onDragEnter={onDragEnter}>
                <label htmlFor="inputContent" className={styles.icon_container}>
                    {isOver
                        ? <IconUploadFileOver color={"soft"} size={80} />
                        : <IconUploadFile color={"default"} size={80} />
                    }

                </label>
                {isOver
                    ? null
                    : <>
                        <div>
                            {t("upload_file.text")}
                        </div>
                        <div>
                            <label className={styles.upload_label} htmlFor="inputContent">{t("upload_file.button")}</label>
                            <span>
                                {t("upload_file.text_2")}
                            </span>
                        </div>
                    </>

                }
            </div>
            <input type="file" id="inputContent" multiple onChange={onLoadInput} />
        </div>
    );
};

export default FileLoader;