import classnames from "classnames";
import React, { useState } from "react";
import { Button, IconWifi, Input, Modal, Tooltip, useNotification } from "../../../components/UI";
import { useTerminalSetupStore } from "../../../stores";
import styles from "./styles.module.scss";
import { useTranslation } from "i18nano";

const NetworkSetup = () => {
    const t = useTranslation();

    const { terminal, connectWifi } = useTerminalSetupStore(state => state);
    const { addNotific } = useNotification();

    const [showWifiConnect, setShowWifiConnect] = useState(false);
    const [ssid, setSsid] = useState("");
    const [password, setPassword] = useState("");
    const [currentInterface, setCurrentInterface] = useState("");

    if (!terminal) {
        return null;
    }

    const maskBits = (mask: string) => {
        const bits = mask.split(".").map(item => parseInt(item).toString(2)).join("");
        return bits.replace(/0/g, "").length;
    };

    const connectWifiHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        connectWifi({
            interface_id: currentInterface,
            terminal_id: terminal.id,
            ssid,
            password,
            security: ""
        }).then((isConnect) => {
            if (isConnect) {
                addNotific({
                    title: t("terminal_setup_page.network_setup.connect_to_wifi.notifications.success.title"),
                    body: t("terminal_setup_page.network_setup.connect_to_wifi.notifications.success.body"),
                    type: "success"
                });

                setShowWifiConnect(false);
            } else {
                addNotific({
                    title: t("terminal_setup_page.network_setup.connect_to_wifi.notifications.error.title"),
                    body: t("terminal_setup_page.network_setup.connect_to_wifi.notifications.error.body"),
                    type: "danger"
                });
            }
        });
    };

    return (
        <>
            <h2>{t("terminal_setup_page.network_setup.title")}</h2>
            <h3>{t("terminal_setup_page.network_setup.subtitle")}</h3>
            <div className={styles.intarfaces_list_table}>
                <div className={styles.table_head}>
                    <div className={styles.row}>
                        <div className={classnames(styles.col, styles.checkbox)}>{t("terminal_setup_page.network_setup.table.state")}</div>
                        <div className={styles.col}>{t("terminal_setup_page.network_setup.table.interface")}</div>
                        <div className={styles.col}>{t("terminal_setup_page.network_setup.table.parameters")}</div>
                        <div className={classnames(styles.col, styles.settings)}>{t("terminal_setup_page.network_setup.table.actions")}</div>
                    </div>
                </div>
                <div className={styles.table_body}>
                    {terminal.network_setup.interfaces.map((item =>
                        <div key={item.id} className={styles.row}>
                            <div className={classnames(styles.col, styles.checkbox)}>
                                <span className={classnames(styles.connection_status, { [styles.active]: item.is_active })}></span>
                            </div>
                            <div className={classnames(styles.col, styles.name)}>
                                {item.id}
                                {item.connected
                                    ? <span className={styles.connected_wifi_name}>{item.connected}</span>
                                    : null
                                }
                            </div>
                            <div className={classnames(styles.col, styles.ip_setup)}>{item.ip
                                ? `${item.ip}, ${item.mask}, ${item.gateway || t("terminal_setup_page.network_setup.status.no_gateway")}, ${item.dns_1 || t("terminal_setup_page.network_setup.status.no_dns_1")}, ${item.dns_2 || t("terminal_setup_page.network_setup.status.no_dns_2")}`
                                : t("terminal_setup_page.network_setup.status.no_connection")
                            }</div>
                            <div className={classnames(styles.col, styles.settings)}>
                                {item.type === "wifi"
                                    ? <Tooltip text={t("terminal_setup_page.network_setup.connect_to_wifi.title")}>
                                        <span onClick={() => {
                                            setShowWifiConnect(true);
                                            setCurrentInterface(item.id);
                                        }}>
                                            <IconWifi size={23} />
                                        </span>
                                    </Tooltip>
                                    : null
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal isShow={showWifiConnect} setIsShow={setShowWifiConnect} title={t("terminal_setup_page.network_setup.connect_to_wifi.title")} onClose={() => {
                setPassword("");
                setSsid("");
            }}>
                <form action="" onSubmit={connectWifiHandler} autoComplete="off" name="connect-wifi" className={styles.wifi_connect}>
                    <Input type="text" placeholder={t("terminal_setup_page.network_setup.connect_to_wifi.ssid")} autocomplete={"off"} value={ssid} onChange={setSsid} />
                    <Input type="password" placeholder={t("terminal_setup_page.network_setup.connect_to_wifi.password")} autocomplete={"off"} value={password} onChange={setPassword} />
                    <Button variant={ssid.length && password.length ? "primary" : "default"}>{t("terminal_setup_page.network_setup.connect_to_wifi.button")}</Button>
                </form>
            </Modal>
        </>
    );
};

export default NetworkSetup;