import classnames from "classnames";
import { useTranslation } from "i18nano";
import React, { useEffect, useState } from "react";
import { Button, IconCloseX, Modal } from "../../../../../components/UI";
import { useCreatePlaylistStore } from "../../../../../stores";
import { getCvLabels, ICvLabels } from "./consts";
import "./CvItem.scss";


interface IProps {
    cvList: Record<string, string>;
    itemKey: string;
    currentId: string;
}

interface IModal {
    defaultCondition: string;
    confirmHandler: (val: string) => void;
    closeHandler: () => void;
}

const ModalContent: React.FC<IModal> = ({ defaultCondition, confirmHandler, closeHandler }) => {
    const t = useTranslation();

    const [itemConditions, setItemConditions] = useState(defaultCondition);
    const [conditionViewMode, setConditionViewMode] = useState<ICvLabels>({});

    useEffect(() => {
        setConditionViewMode(getCvLabels(itemConditions, t("triggers.cv")));
    }, [itemConditions, t]);

    const setConditionHandler = (condition: string) => {
        const conditionsList = itemConditions.length ? itemConditions.split("_") : [];

        if (conditionsList.includes(condition)) {
            setItemConditions(conditionsList.filter(item => item !== condition).join("_"));
        } else {
            setItemConditions([...conditionsList, condition].join("_"));
        }
    };

    const emotionNames = Object.keys(t("triggers.cv.emotion")).filter(item => item !== "label");
    const genderName = Object.keys(t("triggers.cv.gender")).filter(item => item !== "label");
    const ageName = Object.keys(t("triggers.cv.age_group")).filter(item => item !== "label");

    return (
        <div className={"conditionsModal-container"}>
            <div className={"conditionsCreate-container"}>
                <div className={"conditionsList-container"}>
                    <details open>
                        <summary>{t("triggers.cv.emotion.label")}</summary>
                        <ul>
                            {emotionNames.map((item, index) =>
                                <li
                                    onClick={() => setConditionHandler(item)}
                                    className={classnames({ "active": itemConditions.split("_").includes(item) })}
                                    key={index}
                                >{t(`triggers.cv.emotion.${item}`)}</li>
                            )}
                        </ul>
                    </details>
                    <details open>
                        <summary>{t("triggers.cv.gender.label")}</summary>
                        <ul>
                            {genderName.map((item, index) =>
                                <li
                                    onClick={() => setConditionHandler(item)}
                                    className={classnames({ "active": itemConditions.split("_").includes(item) })}
                                    key={index}
                                >{t(`triggers.cv.gender.${item}`)}</li>
                            )}
                        </ul>
                    </details>
                    <details open>
                        <summary>{t("triggers.cv.age_group.label")}</summary>
                        <ul>
                            {ageName.map((item, index) =>
                                <li
                                    onClick={() => setConditionHandler(item)}
                                    className={classnames({ "active": itemConditions.split("_").includes(item) })}
                                    key={index}
                                >{t(`triggers.cv.age_group.${item}`)}</li>
                            )}
                        </ul>
                    </details>
                </div>
                <div className={"conditionView-container"}>
                    {Object.keys(conditionViewMode)
                        ? <>
                            {conditionViewMode.emotion &&
                                <div className={"condition-item"}>
                                    <span>{t("triggers.cv.emotion.label")}&nbsp;</span>
                                    ({conditionViewMode.emotion})
                                </div>
                            }
                            {conditionViewMode.gender &&
                                <div className={"condition-item"}>
                                    <span>{t("triggers.cv.gender.label")}&nbsp;</span>
                                    ({conditionViewMode.gender})
                                </div>
                            }
                            {conditionViewMode.age &&
                                <div className={"condition-item"}>
                                    <span>{t("triggers.cv.age_group.label")}&nbsp;</span>
                                    ({conditionViewMode.age})
                                </div>
                            }
                        </>
                        : <></>
                    }
                </div>
            </div>
            <div className={"buttons-container"}>
                <Button onClick={closeHandler} variant={"secondary"}>{t("button.cancel")}</Button>
                <Button onClick={() => itemConditions.length ? confirmHandler(itemConditions) : closeHandler()} variant={"primary"}>{t("button.apply")}</Button>
            </div>
        </div >
    );
};

const CvItem: React.FC<IProps> = ({ cvList, itemKey, currentId }) => {
    const { setTriggers, triggers } = useCreatePlaylistStore(state => state);
    const t = useTranslation();

    const [conditionViewMode, setConditionViewMode] = useState<ICvLabels>({});
    const [itemConditions, setItemConditions] = useState("");
    const [show, setShow] = useState(cvList[itemKey].length === 0);

    useEffect(() => {
        if (cvList[itemKey].length) {
            setConditionViewMode(getCvLabels(cvList[itemKey], t("triggers.cv")));
        }
    }, []);

    useEffect(() => {
        if (itemConditions.length) {
            setConditionViewMode(getCvLabels(itemConditions, t("triggers.cv")));
        }
    }, [t, itemConditions]);

    const deleteHandler = () => {
        const newCvList = { ...cvList };
        delete newCvList[itemKey];

        setTriggers({
            ...triggers[currentId],
            cv: newCvList
        });
        setShow(false);
    };

    const closeHandler = () => {
        const newCvList = { ...cvList };

        if (!newCvList[itemKey].length) {
            delete newCvList[itemKey];

            setTriggers({
                ...triggers[currentId],
                cv: newCvList
            });
        }

        setShow(false);
    };

    const confirmHandler = (itemConditions: string) => {
        if (itemConditions.length) {
            const newCvList = { ...cvList };
            newCvList[itemKey] = itemConditions;
            setItemConditions(itemConditions);

            setTriggers({
                ...triggers[currentId],
                cv: newCvList
            });
            setShow(false);
        } else {
            deleteHandler();
        }
    };

    return (
        <div className={"trigger-item cv-item-container"} >
            <div className="short-name-container">
                <span className={"name"} onClick={() => setShow(true)}>
                    {Object.keys(conditionViewMode)
                        ? <>
                            {conditionViewMode.emotion &&
                                <div className={"name-item"}>
                                    {t("triggers.cv.emotion.label")}
                                    <span>&nbsp;({conditionViewMode.emotion})</span>
                                </div>
                            }
                            {conditionViewMode.gender &&
                                <div className={"name-item"}>
                                    {t("triggers.cv.gender.label")}
                                    <span>&nbsp;({conditionViewMode.gender})</span>
                                </div>
                            }
                            {conditionViewMode.age &&
                                <div className={"name-item"}>
                                    {t("triggers.cv.age_group.label")}
                                    <span>&nbsp;({conditionViewMode.age})</span>
                                </div>
                            }
                        </>
                        : <></>
                    }
                </span>
                <span className={"delete-icon"} onClick={deleteHandler}>
                    <IconCloseX color={"black"} />
                </span>
            </div>
            <Modal
                isShow={show}
                setIsShow={setShow}
                title={t("create_playlist.select_triggers_cv.title")}
                subtitle={t("create_playlist.select_triggers_cv.subtitle")}
                disableOverlay
            >
                <ModalContent
                    defaultCondition={cvList[itemKey]}
                    closeHandler={closeHandler}
                    confirmHandler={confirmHandler}
                />
            </Modal>
        </div>
    );
};

export default CvItem;