import React from "react";

const colors = new Map([
    ["red", "#DD473B"],
    ["blue", "#1C7EDF"],
    ["white", "#FFFFFF"],
    ["default", "#ADADAD"],
    ["soft", "#1C7EDF"],
    ["black", "#000000"]
]);

type ColorTypes = "red" | "blue" | "white" | "soft" | "black" | "default"

interface IProps {
    color?: ColorTypes,
    size?: number
}

export const IconOts: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1473 5.8275C13.0948 5.82 13.0423 5.82 12.9898 5.8275C11.8273 5.79 10.9048 4.8375 10.9048 3.6675C10.9048 2.475 11.8723 1.5 13.0723 1.5C14.2648 1.5 15.2398 2.4675 15.2398 3.6675C15.2323 4.8375 14.3098 5.79 13.1473 5.8275Z" fill={colors.get(color)} />
            <path d="M15.5927 11.0251C14.7527 11.5876 13.5752 11.7976 12.4877 11.6551C12.7727 11.0401 12.9227 10.3576 12.9302 9.63756C12.9302 8.88756 12.7652 8.17506 12.4502 7.55256C13.5602 7.40256 14.7377 7.61256 15.5852 8.17506C16.7702 8.95506 16.7702 10.2376 15.5927 11.0251Z" fill={colors.get(color)} />
            <path d="M4.83012 5.8275C4.88262 5.82 4.93512 5.82 4.98762 5.8275C6.15012 5.79 7.07262 4.8375 7.07262 3.6675C7.07262 2.4675 6.10512 1.5 4.90512 1.5C3.71262 1.5 2.74512 2.4675 2.74512 3.6675C2.74512 4.8375 3.66762 5.79 4.83012 5.8275Z" fill={colors.get(color)} />
            <path d="M4.91234 9.63746C4.91234 10.365 5.06984 11.055 5.35484 11.6775C4.29734 11.79 3.19484 11.565 2.38484 11.0325C1.19984 10.245 1.19984 8.96246 2.38484 8.17496C3.18734 7.63496 4.31984 7.41746 5.38484 7.53746C5.07734 8.16746 4.91234 8.87996 4.91234 9.63746Z" fill={colors.get(color)} />
            <path d="M9.0901 11.9025C9.0301 11.895 8.9626 11.895 8.8951 11.9025C7.5151 11.8575 6.4126 10.725 6.4126 9.33C6.4201 7.905 7.5676 6.75 9.0001 6.75C10.4251 6.75 11.5801 7.905 11.5801 9.33C11.5726 10.725 10.4776 11.8575 9.0901 11.9025Z" fill={colors.get(color)} />
            <path d="M6.6526 13.455C5.5201 14.2125 5.5201 15.4575 6.6526 16.2075C7.9426 17.07 10.0576 17.07 11.3476 16.2075C12.4801 15.45 12.4801 14.205 11.3476 13.455C10.0651 12.5925 7.9501 12.5925 6.6526 13.455Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconWatchers: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9375 6.86251C14.205 4.14001 11.67 2.57251 9 2.57251C7.665 2.57251 6.3675 2.96251 5.1825 3.69001C3.9975 4.42501 2.9325 5.49751 2.0625 6.86251C1.3125 8.04001 1.3125 9.95251 2.0625 11.13C3.795 13.86 6.33 15.42 9 15.42C10.335 15.42 11.6325 15.03 12.8175 14.3025C14.0025 13.5675 15.0675 12.495 15.9375 11.13C16.6875 9.96001 16.6875 8.04001 15.9375 6.86251ZM9 12.03C7.32 12.03 5.97 10.6725 5.97 9.00001C5.97 7.32751 7.32 5.97001 9 5.97001C10.68 5.97001 12.03 7.32751 12.03 9.00001C12.03 10.6725 10.68 12.03 9 12.03Z" fill={colors.get(color)} />
            <path d="M8.99981 6.85498C7.82231 6.85498 6.8623 7.81498 6.8623 8.99998C6.8623 10.1775 7.82231 11.1375 8.99981 11.1375C10.1773 11.1375 11.1448 10.1775 11.1448 8.99998C11.1448 7.82248 10.1773 6.85498 8.99981 6.85498Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconDwell: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00006 3.48755C5.41506 3.48755 2.49756 6.40505 2.49756 9.99005C2.49756 13.575 5.41506 16.5 9.00006 16.5C12.5851 16.5 15.5026 13.5825 15.5026 9.99755C15.5026 6.41255 12.5851 3.48755 9.00006 3.48755ZM9.56256 9.75005C9.56256 10.0575 9.30756 10.3125 9.00006 10.3125C8.69256 10.3125 8.43756 10.0575 8.43756 9.75005V6.00005C8.43756 5.69255 8.69256 5.43755 9.00006 5.43755C9.30756 5.43755 9.56256 5.69255 9.56256 6.00005V9.75005Z" fill={colors.get(color)} />
            <path d="M11.1675 2.5875H6.83248C6.53248 2.5875 6.29248 2.3475 6.29248 2.0475C6.29248 1.7475 6.53248 1.5 6.83248 1.5H11.1675C11.4675 1.5 11.7075 1.74 11.7075 2.04C11.7075 2.34 11.4675 2.5875 11.1675 2.5875Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconAttention: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1675 2.5875H6.83248C6.53248 2.5875 6.29248 2.3475 6.29248 2.0475C6.29248 1.7475 6.53248 1.5 6.83248 1.5H11.1675C11.4675 1.5 11.7075 1.74 11.7075 2.04C11.7075 2.34 11.4675 2.5875 11.1675 2.5875Z" fill={colors.get(color)} />
            <path d="M14.9775 11.25H12.7725C11.82 11.25 11.25 11.82 11.25 12.7725V14.9775C11.25 15.93 11.82 16.5 12.7725 16.5H14.9775C15.93 16.5 16.5 15.93 16.5 14.9775V12.7725C16.5 11.82 15.93 11.25 14.9775 11.25ZM14.7675 14.595L13.8825 15.105C13.7025 15.21 13.5225 15.2625 13.3575 15.2625C13.23 15.2625 13.1175 15.2325 13.0125 15.1725C12.7725 15.03 12.6375 14.7525 12.6375 14.385V13.365C12.6375 12.9975 12.7725 12.72 13.0125 12.5775C13.2525 12.435 13.56 12.465 13.8825 12.645L14.7675 13.155C15.0825 13.3425 15.2625 13.5975 15.2625 13.875C15.2625 14.1525 15.09 14.4075 14.7675 14.595Z" fill={colors.get(color)} />
            <path d="M10.5001 14.9775V12.7725C10.5001 11.415 11.4151 10.5 12.7726 10.5H14.9776C15.1501 10.5 15.3151 10.515 15.4726 10.545C15.4876 10.365 15.5026 10.185 15.5026 9.99755C15.5026 6.40505 12.5851 3.48755 9.00006 3.48755C5.41506 3.48755 2.49756 6.40505 2.49756 9.99755C2.49756 13.5825 5.41506 16.5 9.00006 16.5C9.63756 16.5 10.2451 16.395 10.8301 16.23C10.6201 15.8775 10.5001 15.4575 10.5001 14.9775ZM9.56256 9.75005C9.56256 10.0575 9.30756 10.3125 9.00006 10.3125C8.69256 10.3125 8.43756 10.0575 8.43756 9.75005V6.00005C8.43756 5.69255 8.69256 5.43755 9.00006 5.43755C9.30756 5.43755 9.56256 5.69255 9.56256 6.00005V9.75005Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconConversionRatio: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4324 8.04017H11.1149V2.64017C11.1149 1.38017 10.4324 1.12517 9.59989 2.07017L8.99989 2.75267L3.92239 8.52767C3.22489 9.31517 3.51739 9.96017 4.56739 9.96017H6.88489V15.3602C6.88489 16.6202 7.56739 16.8752 8.39989 15.9302L8.99989 15.2477L14.0774 9.47267C14.7749 8.68517 14.4824 8.04017 13.4324 8.04017Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconAttentionRatio: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.97754 1.50012C4.83754 1.50012 1.47754 4.86012 1.47754 9.00012C1.47754 13.1401 4.83754 16.5001 8.97754 16.5001C13.1175 16.5001 16.4775 13.1401 16.4775 9.00012C16.4775 4.86012 13.125 1.50012 8.97754 1.50012ZM11.79 9.26262L9.00004 12.4351L8.67004 12.8101C8.21254 13.3276 7.83754 13.1926 7.83754 12.4951V9.52512H6.56254C5.98504 9.52512 5.82754 9.17262 6.21004 8.73762L9.00004 5.56512L9.33004 5.19012C9.78754 4.67262 10.1625 4.80762 10.1625 5.50512V8.47512H11.4375C12.015 8.47512 12.1725 8.82762 11.79 9.26262Z" fill={colors.get(color)} />
        </svg>
    );
};
