import React, { useEffect } from "react";
import "./AddContent.scss";
import { useContentStore } from "../../../../stores/";
import AddContentItem from "./AddContentItem";


const AddContent = () => {
    const { contentList, fetchContent } = useContentStore(state => state);

    useEffect(() => {
        fetchContent({
            page: 1,
            perPage: 1000,
            filters: { name: "" },
            orderBy: "name",
            orderDesk: false
        });
    }, []);

    return (
        <ul className={"addContentContainer"}>
            {contentList.data.map(content =>
                <AddContentItem content={content} key={content.id} />
            )}
        </ul>
    );
};

export default AddContent;