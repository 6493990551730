import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { rtlLangs } from "../../../../assets/locale";
import Portal from "../../Portal";
import { Button } from "../../Button";
import { IconCloseX } from "../../Icons";

import useMount from "../../../../hooks/useMount";

import { useTranslation } from "i18nano";
import classnames from "classnames";

import styles from "./styles.module.scss";
import modalAnimation from "../animation/modal-animation.module.scss";
import overlayAnimation from "../animation/overlay-animation.module.scss";

interface IProps {
    title?: string;
    subtitle?: string;
    okText?: string;
    cancleText?: string; 
    isShow: boolean;
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
}

const ANIMATION_TIME = 300;

const ModalContent: React.FC<IProps> = ({ children, isShow, setIsShow, onCancel, onConfirm, title, subtitle, okText, cancleText }) => {
    const t = useTranslation();

    const overlayRef = useRef(null);
    const modalRef = useRef(null);

    const [animationIn, setAnimationIn] = useState(false);

    useEffect(() => {
        const handleClick = (event: KeyboardEvent) => {
            event.preventDefault();

            if (event.code === "Escape") {
                onCacnelHandler();
            }
            if (event.code === "Enter") {
                onConfirmHandler();
            }
        };

        document.body.addEventListener("keydown", handleClick);

        return () => {
            document.body.removeEventListener("keydown", handleClick);
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow = isShow ? "hidden" : "";
        setAnimationIn(isShow);
    }, [isShow]);

    const onConfirmHandler = () => {
        onConfirm();
        setIsShow(false);
    };

    const onCacnelHandler = () => {
        onCancel();
        setIsShow(false);
    };

    return (
        <div className={classnames(styles.modal_container, styles.position_up)}>
            <CSSTransition
                in={animationIn}
                nodeRef={overlayRef}
                timeout={ANIMATION_TIME}
                mountOnEnter
                unmountOnExit
                classNames={overlayAnimation}
            >
                <div ref={overlayRef} className={styles.modal_overlay}></div>
            </CSSTransition>

            <CSSTransition
                in={animationIn}
                nodeRef={modalRef}
                timeout={ANIMATION_TIME}
                mountOnEnter
                unmountOnExit
                classNames={modalAnimation}
            >
                <div ref={modalRef} className={styles.modal_content}>
                    {title ?
                        <div className={styles.modal_control}>
                            <div className={styles.modal_title}>
                                <div className={styles.title}>{title}</div>
                                {subtitle ?
                                    <div className={styles.subtitle}>
                                        {subtitle}
                                    </div>
                                    : null
                                }
                            </div>
                            <div className={styles.close} onClick={onCacnelHandler}>
                                <IconCloseX size={25} />
                            </div>
                        </div>
                        : null
                    }

                    <div className={styles.modal_body}>
                        {children}
                    </div>

                    <div className={styles.buttons_container}>
                        <Button variant={"default"} onClick={onCacnelHandler}>{cancleText || t("button.cancel")}</Button>
                        <Button variant={"primary"} onClick={onConfirmHandler}>{okText || t("button.ok")}</Button>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

const ModalConfirm: React.FC<IProps> = (props) => {
    const lang = window.localStorage.getItem("lang");
    const { mounted } = useMount(props.isShow, ANIMATION_TIME);

    if (!mounted) {
        return null;
    }

    return (
        <Portal>
            <div dir={rtlLangs.includes(lang!) ? "rtl" : "ltr"}>
                <ModalContent {...props} >
                    {props.children}
                </ModalContent>
            </div>
        </Portal>
    );
};

export default ModalConfirm;