import { useTranslation } from "i18nano";
import React, { useEffect, useState } from "react";
import { Input, Select } from "../../../../components/UI";
import { useCreateScenarioStore } from "../../../../stores";
import { IChangeItemDuration } from "../../../../types/createScenario";
import styles from "./styles.module.scss";

const blackoutOptions = [
    {
        id: 0,
        name: "0 ms"
    },
    {
        id: 100,
        name: "100 ms"
    },
    {
        id: 200,
        name: "200 ms"
    },
    {
        id: 300,
        name: "300 ms"
    },
    {
        id: 400,
        name: "400 ms"
    },
    {
        id: 500,
        name: "500 ms"
    }
];

const SettingsNode = () => {
    const { currentNode, scenarioItems, changeItemDuration } = useCreateScenarioStore(state => state);

    const t = useTranslation();

    const currentScenarioItem = scenarioItems[currentNode!.id];

    const [playDuration, setPlayDuration] = useState("0");
    const [blackoutDuration, setBlackoutDuration] = useState(blackoutOptions[2].id);

    useEffect(() => {
        const data: IChangeItemDuration = {
            scanerioItemId: currentNode!.id,
            duration: currentScenarioItem.content_type === "image" ? +playDuration : undefined,
            blackout_duration: +blackoutDuration
        };

        changeItemDuration(data);
    }, [playDuration, blackoutDuration]);

    useEffect(() => {
        const currentScenarioItem = scenarioItems[currentNode!.id];

        setPlayDuration(currentScenarioItem.duration + "");
        setBlackoutDuration(blackoutOptions.filter(item => item.id === currentScenarioItem.blackout_duration)[0].id);
    }, [currentNode]);

    return (
        <div className={styles.settings_node_container}>
            <div className={styles.name}>{currentScenarioItem.content}</div>
            <div className={styles.settings_container}>
                {currentScenarioItem.content_type === "image"
                    ? <div>
                        <label htmlFor={"playDurationInput"}>{t("create_scenario.settings_node.duration")}</label>
                        <div className={styles.input_container}>
                            <Input type={"number"} id={"playDurationInput"} value={playDuration} onChange={setPlayDuration} min={0} />
                            <span className={styles.seconds}>{t("measurement.seconds")}</span>
                        </div>
                    </div>
                    : null
                }
                <div>
                    <label htmlFor={"blackoutDurationInput"}>{t("create_scenario.settings_node.blackout_duration")}</label>
                    <Select options={blackoutOptions} value={blackoutDuration} setValue={setBlackoutDuration} />
                </div>
            </div>
        </div>
    );
};

export default SettingsNode;