import React, { useEffect, useState } from "react";
import { getOneContentApi } from "../../api/content-api";

interface IProps {
    url: string;
    contentType: "image" | "video" | "preview";
    width?: string;
    height?: string;
    controls?: boolean;
}

const noAvailable = {
    image: require("../../assets/images/image_no_available.png"),
    video: require("../../assets/images/video_no_available.webp"),
    preview: require("../../assets/images/no_preview_available.png"),
};

const ContentContainer: React.FC<IProps> = ({ url, contentType, width = "auto", height = "auto", controls = true }) => {
    // const [content, setContent] = useState<string>();

    // useEffect(() => {
    //     getOneContentApi(url).then(data => setContent(data));
    // }, [url]);

    // if (!content || !url) {
    //     return (
    //         <img src={noAvailable[contentType]} alt="no available" width={width} height={height} />
    //     );
    // }

    if (contentType === "video") {
        return (
            <video src={url} width={width} height={height} controls={controls} />
        );
    }

    return (
        <img src={url} alt={contentType} />
    );
};

export default ContentContainer;