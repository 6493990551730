import axios from "./axios";
import { IGeneralAnalytic } from "../types/analytics";
import { IAnalyticFilters, IDemographyFilters, IDownloadStatsFilters } from "../types/default";

const baseApiURL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const getGeneralAnalyticApi = async (filters: IAnalyticFilters): Promise<{ general_stats: IGeneralAnalytic} | undefined> => {
    return await axios.post("/statistic/general/", filters);
};

export const getDemographyApi = async (filters: IDemographyFilters): Promise<any | undefined> => {
    return await axios.post("/statistic/demographic/", filters);
};

export const downloadStats = async (filters: IDownloadStatsFilters): Promise<any | undefined> => {
    const filters_query = `?start_date=${filters.start_date}&end_date=${filters.end_date}`
    +`&content_id=${filters.content_id}&terminal_id=${filters.terminal_id}&timezone=${filters.timezone}`
    +`&period=${filters.period}&include_general=${filters.include_general}&include_demographic=${filters.include_demographic}`;
    
    window.open(`${baseApiURL}/statistic/download/` + filters_query, "_blank");
};
