import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LeftControlPanel.scss";
import classnames from "classnames";
import { useCreateScenarioStore } from "../../../stores/";
import { Button, IconEdit, Modal, ModalConfirm } from "../../../components/UI";
import { useTranslation } from "i18nano";
import ContentList from "./ContentList";
import ScenarioSettings from "./ScenarioSettings";


const LeftControlPanel = () => {
    const { createScenario, checkValid, scenarioData } = useCreateScenarioStore(state => state);
    const t = useTranslation();

    const navigate = useNavigate();

    const [showConfirm, setShowConfirm] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [saveOnChange, setSaveOnChange] = useState(false);

    const saveData = () => {
        if (!scenarioData.name.length) {
            setSaveOnChange(true);
            setShowSettings(true);
            return;
        }

        if (checkValid()) {
            createScenario().then(val => {
                setShowConfirm(val);
            });
        }
    };

    const onCancel = () => {
        navigate("/scenarios");
    };

    useEffect(() => {
        if (saveOnChange && scenarioData.name.length) {
            setSaveOnChange(false);
            saveData();
        }
    }, [scenarioData.name]);

    return (
        <div className={classnames("controlPanel leftControlPanel")}>
            <div className={"controlPanelTop"}>
                <div className={"name-container"}>
                    <span className="name">{scenarioData.name.length ? scenarioData.name : t("label.name", { name: t("scenario.genitive") })}</span>
                    <span className="icon" onClick={() => setShowSettings(true)}><IconEdit size={22} /></span>
                </div>
                <ContentList />
            </div>
            <div className={"controlPanelBot"}>
                <Button variant={"primary"} onClick={() => saveData()}>{t("button.save")}</Button>
                <Button variant={"secondary"} onClick={onCancel}>{t("button.cancel")}</Button>
            </div>
            <Modal isShow={showSettings} setIsShow={setShowSettings} title={t("button.settings")}>
                <ScenarioSettings setShowModal={setShowSettings} />
            </Modal>
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => setSaveOnChange(false)} onCancel={onCancel} okText={t("button.yes")} cancleText={t("button.no")}>
                <p>{t("confirm.create", { name: t("scenario.one") })}</p>
            </ModalConfirm>
        </div>
    );
};

export default LeftControlPanel;