import { useTranslation } from "i18nano";
import React, { SetStateAction } from "react";
import { TAnalyticSwitch } from "../AnalyticsPage";
import styles from "./styles.module.scss";


interface IProps {
    analyticPage: TAnalyticSwitch;
    setAnalitycPage: React.Dispatch<SetStateAction<TAnalyticSwitch>>;
}

const AnalyticSwitcher: React.FC<IProps> = ({ analyticPage, setAnalitycPage }) => {
    const t = useTranslation();

    return (
        <div className={styles.switcher}>
            <div className={`${styles.switcher_item} ${analyticPage === "general" ? styles.active : ""}`} onClick={() => setAnalitycPage("general")}>{t("analytic.menu.total")}</div>
            <div className={`${styles.switcher_item} ${analyticPage === "demography" ? styles.active : ""}`} onClick={() => setAnalitycPage("demography")}>{t("analytic.menu.demography")}</div>
        </div>
    );
};

export default AnalyticSwitcher;