import React, { useEffect, useState } from "react";
import { useCreatePlaylistStore } from "../../../../../stores";
import { v4 as uuidv4 } from "uuid";
import "./SelectTriggerType.scss";
import { IconCv, IconDateTime, IconEyeContact } from "../../../../../components/UI";
import { useTranslation } from "i18nano";


type TTriggerType = "datetime" | "cv" | "gesture" | "eye_contact";

interface IProps {
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectTriggerType: React.FC<IProps> = ({ setIsShow }) => {
    const { currentPlaylistItem, triggers, setTriggers } = useCreatePlaylistStore(state => state);
    const t = useTranslation();

    // const [hideGesture, setHideGesture] = useState(false);
    const [hideEye, setHideEye] = useState(false);

    useEffect(() => {
        if (currentPlaylistItem && triggers[currentPlaylistItem.id]) {
            // setHideGesture(triggers[currentPlaylistItem.id].gesture !== null);
            setHideEye(triggers[currentPlaylistItem.id].eye_contact !== null);
        }
    }, [triggers, currentPlaylistItem]);

    const setTriggersHandler = (triggersType: TTriggerType) => {
        if (currentPlaylistItem) {
            if (triggersType === "datetime") {
                setTriggers({
                    ...triggers[currentPlaylistItem.id],
                    datetime: {
                        ...triggers[currentPlaylistItem.id].datetime,
                        [`${uuidv4()}`]: {
                            date_start: "",
                            interval: {
                                type: "day",
                                value: 1
                            },
                            days: null,
                            date_end: null,
                            time_end: null,
                            time_start: null,
                            repetitions: null
                        }
                    }
                });
            } else if (triggersType === "cv") {
                setTriggers({
                    ...triggers[currentPlaylistItem.id],
                    cv: {
                        ...triggers[currentPlaylistItem.id].cv,
                        [`${uuidv4()}`]: ""
                    }
                });
            } else if (triggersType === "eye_contact") {
                setTriggers({
                    ...triggers[currentPlaylistItem.id],
                    eye_contact: 0
                });
            } else if (triggersType === "gesture") {
                setTriggers({
                    ...triggers[currentPlaylistItem.id],
                    gesture: []
                });
            }
        }

        setIsShow(false);
    };
    return (
        <div className={"selectTriggerType-container"}>
            <div className={"selectTriggerType-item"} onClick={() => {
                setTriggersHandler("cv");
            }}>
                <IconCv color={"soft"} size={120} />
                <div>
                    <span className={"trigger-name"}>{t("triggers.cv.label")}</span>
                    <span className={"trigger-description"}>{t("triggers.description.cv")}</span>
                </div>
            </div>
            <div className={"selectTriggerType-item"} onClick={() => {
                setTriggersHandler("datetime");
            }}>
                <IconDateTime color={"soft"} size={120} />
                <div>
                    <span className={"trigger-name"}>{t("triggers.datetime.label")}</span>
                    <span className={"trigger-description"}>{t("triggers.description.datetime")}</span>
                </div>
            </div>
            {/* <div className={`selectTriggerType-item ${hideGesture ? "disabled" : ""}`} onClick={() =>
                hideGesture ? null : setTriggersHandler("gesture")
            }>
                {hideGesture
                    ? <div className="disabled"></div>
                    : null
                }
                <IconHands color={"soft"} size={120} />
                <div>
                    <span className={"trigger-name"}>{t("triggers.gesture.label")}</span>
                    <span className={"trigger-description"}>{t("triggers.description.gesture")}</span>
                </div>
            </div> */}
            <div className={`selectTriggerType-item ${hideEye ? "disabled" : ""}`} onClick={() =>
                hideEye ? null : setTriggersHandler("eye_contact")
            }>
                {hideEye
                    ? <div className="disabled"></div>
                    : null
                }
                <IconEyeContact color={"soft"} size={120} />
                <div>
                    <span className={"trigger-name"}>{t("triggers.eye_contact.label")}</span>
                    <span className={"trigger-description"}>{t("triggers.description.eye_contact")}</span>
                </div>
            </div>

        </div>
    );
};

export default SelectTriggerType;