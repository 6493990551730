import React from "react";

const colors = new Map([
    ["red", "#DD473B"],
    ["blue", "#0061AE"],
    ["white", "#FFFFFF"],
    ["default", "#ADADAD"],
    ["soft", "#1C7EDF"],
    ["black", "#000000"],
    ["green", "#198754"]
]);

type ColorTypes = "red" | "blue" | "white" | "soft" | "black" | "default" | "green"

interface IProps {
    color?: ColorTypes,
    size?: number
}

export const IconEye: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.823 7.24376C14.9942 4.37001 12.3184 2.71542 9.50004 2.71542C8.09087 2.71542 6.72129 3.12709 5.47046 3.89501C4.21962 4.67084 3.09546 5.80292 2.17712 7.24376C1.38546 8.48667 1.38546 10.5054 2.17712 11.7483C4.00587 14.63 6.68171 16.2767 9.50004 16.2767C10.9092 16.2767 12.2788 15.865 13.5296 15.0971C14.7805 14.3213 15.9046 13.1892 16.823 11.7483C17.6146 10.5133 17.6146 8.48667 16.823 7.24376ZM9.50004 12.6983C7.72671 12.6983 6.30171 11.2654 6.30171 9.50001C6.30171 7.73459 7.72671 6.30167 9.50004 6.30167C11.2734 6.30167 12.6984 7.73459 12.6984 9.50001C12.6984 11.2654 11.2734 12.6983 9.50004 12.6983Z" fill={colors.get(color)} />
            <path d="M9.50002 7.23584C8.25711 7.23584 7.24377 8.24917 7.24377 9.50001C7.24377 10.7429 8.25711 11.7563 9.50002 11.7563C10.7429 11.7563 11.7642 10.7429 11.7642 9.50001C11.7642 8.25709 10.7429 7.23584 9.50002 7.23584Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconEyeCrossed: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8387 7.2675C16.6092 6.90333 16.3637 6.56292 16.1104 6.24625C15.8175 5.87417 15.2633 5.8425 14.9308 6.175L12.5558 8.55C12.73 9.0725 12.7617 9.67417 12.6033 10.2996C12.3262 11.4158 11.4237 12.3183 10.3075 12.5954C9.68208 12.7538 9.08042 12.7221 8.55792 12.5479C8.55792 12.5479 7.42583 13.68 6.61042 14.4954C6.21458 14.8913 6.34125 15.5879 6.87167 15.7938C7.71875 16.1183 8.5975 16.2846 9.5 16.2846C10.9092 16.2846 12.2787 15.8729 13.5296 15.105C14.8042 14.3133 15.9521 13.1496 16.8783 11.6692C17.6304 10.4737 17.5908 8.46292 16.8387 7.2675Z" fill={colors.get(color)} />
            <path d="M11.0992 7.90083L7.90084 11.0992C7.49709 10.6875 7.23584 10.1175 7.23584 9.5C7.23584 8.25708 8.24917 7.23583 9.50001 7.23583C10.1175 7.23583 10.6875 7.49708 11.0992 7.90083Z" fill={colors.get(color)} />
            <path d="M14.4479 4.55208L11.7642 7.23583C11.1863 6.65 10.3867 6.30167 9.50001 6.30167C7.72668 6.30167 6.30168 7.73458 6.30168 9.5C6.30168 10.3867 6.65793 11.1862 7.23584 11.7642L4.56001 14.4479H4.55209C3.67334 13.7354 2.86584 12.825 2.17709 11.7483C1.38543 10.5054 1.38543 8.48667 2.17709 7.24375C3.09543 5.80292 4.21959 4.67083 5.47043 3.895C6.72126 3.135 8.09084 2.71542 9.50001 2.71542C11.2654 2.71542 12.9754 3.36458 14.4479 4.55208Z" fill={colors.get(color)} />
            <path d="M11.7642 9.5C11.7642 10.7429 10.7508 11.7642 9.5 11.7642C9.4525 11.7642 9.41292 11.7642 9.36542 11.7483L11.7483 9.36542C11.7642 9.41292 11.7642 9.4525 11.7642 9.5Z" fill={colors.get(color)} />
            <path d="M17.2346 1.76542C16.9971 1.52792 16.6092 1.52792 16.3717 1.76542L1.76541 16.3796C1.52791 16.6171 1.52791 17.005 1.76541 17.2425C1.88416 17.3533 2.03457 17.4167 2.19291 17.4167C2.35124 17.4167 2.50166 17.3533 2.62041 17.2346L17.2346 2.62042C17.48 2.38292 17.48 2.00292 17.2346 1.76542Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconSettings: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.075 6.91501C13.7175 6.91501 13.1625 5.95501 13.8375 4.77751C14.2275 4.09501 13.995 3.22501 13.3125 2.83501L12.015 2.09251C11.4225 1.74001 10.6575 1.95001 10.305 2.54251L10.2225 2.68501C9.5475 3.86251 8.4375 3.86251 7.755 2.68501L7.6725 2.54251C7.335 1.95001 6.57 1.74001 5.9775 2.09251L4.68 2.83501C3.9975 3.22501 3.765 4.10251 4.155 4.78501C4.8375 5.95501 4.2825 6.91501 2.925 6.91501C2.145 6.91501 1.5 7.55251 1.5 8.34001V9.66001C1.5 10.44 2.1375 11.085 2.925 11.085C4.2825 11.085 4.8375 12.045 4.155 13.2225C3.765 13.905 3.9975 14.775 4.68 15.165L5.9775 15.9075C6.57 16.26 7.335 16.05 7.6875 15.4575L7.77 15.315C8.445 14.1375 9.555 14.1375 10.2375 15.315L10.32 15.4575C10.6725 16.05 11.4375 16.26 12.03 15.9075L13.3275 15.165C14.01 14.775 14.2425 13.8975 13.8525 13.2225C13.17 12.045 13.725 11.085 15.0825 11.085C15.8625 11.085 16.5075 10.4475 16.5075 9.66001V8.34001C16.5 7.56001 15.8625 6.91501 15.075 6.91501ZM9 11.4375C7.6575 11.4375 6.5625 10.3425 6.5625 9.00001C6.5625 7.65751 7.6575 6.56251 9 6.56251C10.3425 6.56251 11.4375 7.65751 11.4375 9.00001C11.4375 10.3425 10.3425 11.4375 9 11.4375Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconEdit: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        // <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* <path d="M15.075 6.91501C13.7175 6.91501 13.1625 5.95501 13.8375 4.77751C14.2275 4.09501 13.995 3.22501 13.3125 2.83501L12.015 2.09251C11.4225 1.74001 10.6575 1.95001 10.305 2.54251L10.2225 2.68501C9.5475 3.86251 8.4375 3.86251 7.755 2.68501L7.6725 2.54251C7.335 1.95001 6.57 1.74001 5.9775 2.09251L4.68 2.83501C3.9975 3.22501 3.765 4.10251 4.155 4.78501C4.8375 5.95501 4.2825 6.91501 2.925 6.91501C2.145 6.91501 1.5 7.55251 1.5 8.34001V9.66001C1.5 10.44 2.1375 11.085 2.925 11.085C4.2825 11.085 4.8375 12.045 4.155 13.2225C3.765 13.905 3.9975 14.775 4.68 15.165L5.9775 15.9075C6.57 16.26 7.335 16.05 7.6875 15.4575L7.77 15.315C8.445 14.1375 9.555 14.1375 10.2375 15.315L10.32 15.4575C10.6725 16.05 11.4375 16.26 12.03 15.9075L13.3275 15.165C14.01 14.775 14.2425 13.8975 13.8525 13.2225C13.17 12.045 13.725 11.085 15.0825 11.085C15.8625 11.085 16.5075 10.4475 16.5075 9.66001V8.34001C16.5 7.56001 15.8625 6.91501 15.075 6.91501ZM9 11.4375C7.6575 11.4375 6.5625 10.3425 6.5625 9.00001C6.5625 7.65751 7.6575 6.56251 9 6.56251C10.3425 6.56251 11.4375 7.65751 11.4375 9.00001C11.4375 10.3425 10.3425 11.4375 9 11.4375Z" fill={colors.get(color)} /> */}
            <path d="M55.944,51.712l0,4.201l-33.652,-0.027l4.71,-4.174l28.942,0Zm-7.555,-43.712c1.649,0 2.505,0.128 4.752,2.011c2.294,1.921 2.707,3.419 2.803,5.087c0.102,1.795 -0.504,3.976 -2.188,5.681l-31.961,31.961c-0.52,0.475 -0.629,0.45 -0.977,0.553l-10.226,2.557c-1.472,0.299 -2.854,-1.049 -2.55,-2.55l2.557,-10.226c0.1,-0.334 0.133,-0.517 0.553,-0.976c10.696,-10.697 21.195,-21.594 32.09,-32.087c1.421,-1.335 3.497,-2.011 5.147,-2.011Zm-32.375,35.182l-1.477,1.477l-1.566,6.262l6.262,-1.566c0.493,-0.492 0.985,-0.983 1.479,-1.474l-4.698,-4.699Zm30.176,-20.573l-4.802,-4.801l-22.493,22.493l4.712,4.713c7.549,-7.448 15.196,-14.801 22.583,-22.405Zm2.826,-2.936c0.618,-0.648 1.234,-1.298 1.848,-1.951c1.673,-1.826 0.443,-5.454 -2.307,-5.578c-0.056,-0.002 -0.112,-0.002 -0.168,-0.002c-0.861,0.016 -1.699,0.372 -2.312,0.977l-1.807,1.808l4.746,4.746Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconCloseSquare: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8171 1.58333H6.18296C3.30129 1.58333 1.58337 3.30124 1.58337 6.18291V12.8092C1.58337 15.6987 3.30129 17.4167 6.18296 17.4167H12.8092C15.6909 17.4167 17.4088 15.6987 17.4088 12.8171V6.18291C17.4167 3.30124 15.6988 1.58333 12.8171 1.58333ZM12.16 11.3208C12.3896 11.5504 12.3896 11.9304 12.16 12.16C12.0413 12.2787 11.8909 12.3342 11.7405 12.3342C11.59 12.3342 11.4396 12.2787 11.3209 12.16L9.50004 10.3392L7.67921 12.16C7.56046 12.2787 7.41004 12.3342 7.25962 12.3342C7.10921 12.3342 6.95879 12.2787 6.84004 12.16C6.61046 11.9304 6.61046 11.5504 6.84004 11.3208L8.66087 9.5L6.84004 7.67916C6.61046 7.44958 6.61046 7.06958 6.84004 6.84C7.06962 6.61041 7.44962 6.61041 7.67921 6.84L9.50004 8.66083L11.3209 6.84C11.5505 6.61041 11.9305 6.61041 12.16 6.84C12.3896 7.06958 12.3896 7.44958 12.16 7.67916L10.3392 9.5L12.16 11.3208Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconSearch: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.70833 15.0417C12.2061 15.0417 15.0417 12.2061 15.0417 8.70833C15.0417 5.21053 12.2061 2.375 8.70833 2.375C5.21053 2.375 2.375 5.21053 2.375 8.70833C2.375 12.2061 5.21053 15.0417 8.70833 15.0417Z" stroke={colors.get(color)} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.625 16.625L13.1813 13.1812" stroke={colors.get(color)} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const IconKey: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6671 3.34083C13.3238 1.00542 9.52375 1.00542 7.19625 3.34083C5.5575 4.96375 5.06667 7.29917 5.7 9.3575L1.97917 13.0783C1.71792 13.3475 1.53583 13.8779 1.59125 14.2579L1.82875 15.9837C1.91583 16.5537 2.44625 17.0921 3.01625 17.1712L4.74208 17.4087C5.12208 17.4642 5.6525 17.29 5.92167 17.0129L6.57083 16.3637C6.72917 16.2133 6.72917 15.96 6.57083 15.8017L5.035 14.2658C4.80542 14.0362 4.80542 13.6562 5.035 13.4267C5.26458 13.1971 5.64458 13.1971 5.87417 13.4267L7.41792 14.9704C7.56833 15.1208 7.82167 15.1208 7.97208 14.9704L9.65042 13.3C11.7008 13.9412 14.0363 13.4425 15.6671 11.8196C18.0025 9.48417 18.0025 5.67625 15.6671 3.34083ZM11.4792 9.5C10.3867 9.5 9.5 8.61333 9.5 7.52083C9.5 6.42833 10.3867 5.54167 11.4792 5.54167C12.5717 5.54167 13.4583 6.42833 13.4583 7.52083C13.4583 8.61333 12.5717 9.5 11.4792 9.5Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconUserInput: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 9.5C11.6861 9.5 13.4583 7.7278 13.4583 5.54167C13.4583 3.35554 11.6861 1.58334 9.5 1.58334C7.31387 1.58334 5.54167 3.35554 5.54167 5.54167C5.54167 7.7278 7.31387 9.5 9.5 9.5Z" fill={colors.get(color)} />
            <path d="M9.5 11.4792C5.53375 11.4792 2.30375 14.1392 2.30375 17.4167C2.30375 17.6383 2.47792 17.8125 2.69958 17.8125H16.3004C16.5221 17.8125 16.6962 17.6383 16.6962 17.4167C16.6962 14.1392 13.4662 11.4792 9.5 11.4792Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconLogOut: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2 1.33331H9.46667C7.33333 1.33331 6 2.66665 6 4.79998V7.49998H10.1667C10.44 7.49998 10.6667 7.72665 10.6667 7.99998C10.6667 8.27331 10.44 8.49998 10.1667 8.49998H6V11.2C6 13.3333 7.33333 14.6666 9.46667 14.6666H11.1933C13.3267 14.6666 14.66 13.3333 14.66 11.2V4.79998C14.6667 2.66665 13.3333 1.33331 11.2 1.33331Z" fill={colors.get(color)} />
            <path d="M3.03996 7.49998L4.41996 6.11998C4.51996 6.01998 4.56663 5.89331 4.56663 5.76664C4.56663 5.63998 4.51996 5.50664 4.41996 5.41331C4.22663 5.21998 3.90663 5.21998 3.71329 5.41331L1.47996 7.64664C1.28663 7.83998 1.28663 8.15998 1.47996 8.35331L3.71329 10.5866C3.90663 10.78 4.22663 10.78 4.41996 10.5866C4.61329 10.3933 4.61329 10.0733 4.41996 9.87998L3.03996 8.49998H5.99996V7.49998H3.03996Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconUser: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5099 5.85L13.5699 2.42C12.5999 1.86 11.3999 1.86 10.4199 2.42L4.48992 5.85C3.51992 6.41 2.91992 7.45 2.91992 8.58V15.42C2.91992 16.54 3.51992 17.58 4.48992 18.15L10.4299 21.58C11.3999 22.14 12.5999 22.14 13.5799 21.58L19.5199 18.15C20.4899 17.59 21.0899 16.55 21.0899 15.42V8.58C21.0799 7.45 20.4799 6.42 19.5099 5.85ZM11.9999 7.34C13.2899 7.34 14.3299 8.38 14.3299 9.67C14.3299 10.96 13.2899 12 11.9999 12C10.7099 12 9.66992 10.96 9.66992 9.67C9.66992 8.39 10.7099 7.34 11.9999 7.34ZM14.6799 16.66H9.31992C8.50992 16.66 8.03992 15.76 8.48992 15.09C9.16992 14.08 10.4899 13.4 11.9999 13.4C13.5099 13.4 14.8299 14.08 15.5099 15.09C15.9599 15.75 15.4799 16.66 14.6799 16.66Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrows: React.FC<IProps> = ({ color = "default", size = 19 }, position?: "up" | "down") => {
    return (
        <svg width={size} height={size} viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.631104 5L4.26527 5L7.53777 5C8.09777 5 8.37777 4.32333 7.9811 3.92667L4.95944 0.905C4.47527 0.420834 3.68777 0.420834 3.2036 0.905L2.05444 2.05417L0.181936 3.92667C-0.208897 4.32333 0.0711031 5 0.631104 5Z" fill={colors.get(color)} />
            <path d="M7.53766 8H3.90349H0.630992C0.0709925 8 -0.209007 8.67667 0.187659 9.07333L3.20933 12.095C3.69349 12.5792 4.48099 12.5792 4.96516 12.095L6.11433 10.9458L7.98683 9.07333C8.37766 8.67667 8.09766 8 7.53766 8Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconCloseX: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 4.5L4.5 13.5" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 4.5L13.5 13.5" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const IconVideoFile: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.104 2.08331H6.24984C5.6973 2.08331 5.1674 2.30281 4.7767 2.69351C4.386 3.08421 4.1665 3.61411 4.1665 4.16665V20.8333C4.1665 21.3858 4.386 21.9158 4.7767 22.3065C5.1674 22.6972 5.6973 22.9166 6.24984 22.9166H18.7498C19.3024 22.9166 19.8323 22.6972 20.223 22.3065C20.6137 21.9158 20.8332 21.3858 20.8332 20.8333V7.81248L15.104 2.08331Z" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.5835 2.08331V8.33331H20.8335" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.4165 11.4583L15.6248 14.5833L10.4165 17.7083V11.4583Z" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const IconImageFile: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.104 2.08334H6.24984C5.6973 2.08334 5.1674 2.30284 4.7767 2.69354C4.386 3.08424 4.1665 3.61414 4.1665 4.16668V20.8333C4.1665 21.3859 4.386 21.9158 4.7767 22.3065C5.1674 22.6972 5.6973 22.9167 6.24984 22.9167H18.7498C19.3024 22.9167 19.8323 22.6972 20.223 22.3065C20.6137 21.9158 20.8332 21.3859 20.8332 20.8333V7.81251L15.104 2.08334Z" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.5835 2.08334V8.33334H20.8335" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.4168 15.625C11.5674 15.625 12.5002 14.6923 12.5002 13.5417C12.5002 12.3911 11.5674 11.4583 10.4168 11.4583C9.26624 11.4583 8.3335 12.3911 8.3335 13.5417C8.3335 14.6923 9.26624 15.625 10.4168 15.625Z" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.8332 17.7083L19.6978 16.5729C19.3074 16.1849 18.7794 15.9671 18.229 15.9671C17.6786 15.9671 17.1506 16.1849 16.7603 16.5729L10.4165 22.9167" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const IconScenarioFile: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 455 455" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={colors.get(color)} d="M415,102.509c-22.091,0-40,17.909-40,40c0,5.542,1.128,10.821,3.166,15.62l-83.791,83.792
	c-4.799-2.038-10.078-3.167-15.621-3.167s-10.822,1.129-15.621,3.167l-50.053-50.053c2.038-4.799,3.166-10.078,3.166-15.621
	c0-22.091-17.909-40-40-40c-22.091,0-40,17.909-40,40c0,5.542,1.128,10.821,3.166,15.62l-83.792,83.791
	c-4.799-2.038-10.078-3.167-15.621-3.167c-22.091,0-40,17.909-40,40s17.909,40,40,40s40-17.909,40-40
	c0-5.542-1.128-10.821-3.166-15.62l83.792-83.791c4.799,2.038,10.078,3.166,15.621,3.166c5.542,0,10.821-1.128,15.62-3.166
	l50.054,50.054c-2.038,4.799-3.166,10.078-3.166,15.62c0,22.091,17.909,40,40,40c22.091,0,40-17.909,40-40
	c0-5.542-1.128-10.821-3.166-15.62l83.791-83.792c4.799,2.038,10.078,3.166,15.621,3.166c22.091,0,40-17.909,40-40
	S437.091,102.509,415,102.509z"/>
        </svg>
    );
};

export const IconPlaylistFile: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="244.872" y="109.344" fill={colors.get(color)} width="194.896" height="23.456" />
                <rect x="244.872" y="174.336" fill={colors.get(color)} width="267.128" height="22.264" />
                <rect x="244.872" y="315.456" fill={colors.get(color)} width="184.96" height="22.264" />
                <rect x="244.872" y="382.224" fill={colors.get(color)} width="267.128" height="22.264" />
            </g>
            <rect fill={colors.get(color)} y="282.016" width="178.088" height="155.824" />
            <polygon fill={"#FFFFFF"} points="55.536,318.536 127.288,359.96 55.536,401.384 " />
            <rect fill={colors.get(color)} y="74.16" width="178.088" height="155.824" />
            <polygon fill={"#FFFFFF"} points="55.536,110.648 127.288,152.072 55.536,193.496 " />
        </svg>
    );
};

export const IconArrowLeft: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5242 14.1867L12.5242 9.25458L12.5242 4.81333C12.5242 4.05333 11.6058 3.67333 11.0675 4.21167L6.96667 8.3125C6.30959 8.96958 6.30959 10.0383 6.96667 10.6954L8.52625 12.255L11.0675 14.7962C11.6058 15.3267 12.5242 14.9467 12.5242 14.1867Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowRight: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.47583 4.81333L6.47583 9.74542L6.47583 14.1867C6.47583 14.9467 7.39416 15.3267 7.9325 14.7883L12.0333 10.6875C12.6904 10.0304 12.6904 8.96167 12.0333 8.30458L10.4737 6.745L7.9325 4.20375C7.39416 3.67333 6.47583 4.05334 6.47583 4.81333Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowDown: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1867 6.47583L9.25463 6.47583L4.81338 6.47583C4.05338 6.47583 3.67338 7.39417 4.21171 7.9325L8.31254 12.0333C8.96963 12.6904 10.0384 12.6904 10.6955 12.0333L12.255 10.4737L14.7963 7.9325C15.3267 7.39417 14.9467 6.47583 14.1867 6.47583Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowUp: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.54706 9.22833L7.18122 9.22833L10.4537 9.22833C11.0137 9.22833 11.2937 8.55167 10.8971 8.155L7.87539 5.13333C7.39122 4.64917 6.60372 4.64917 6.11956 5.13333L4.97039 6.2825L3.09789 8.155C2.70706 8.55167 2.98706 9.22833 3.54706 9.22833Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowSquareLeft: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM13.79 15C14.08 15.29 14.08 15.77 13.79 16.06C13.64 16.21 13.45 16.28 13.26 16.28C13.07 16.28 12.88 16.21 12.73 16.06L9.2 12.53C8.91 12.24 8.91 11.76 9.2 11.47L12.73 7.94C13.02 7.65 13.5 7.65 13.79 7.94C14.08 8.23 14.08 8.71 13.79 9L10.79 12L13.79 15Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconDanger: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z" fill={colors.get(color)} />
            <path d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z" fill={colors.get(color)} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconSuccess: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={colors.get(color)} fillRule="evenodd" d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M15.2928932,8.29289322 L10,13.5857864 L8.70710678,12.2928932 C8.31658249,11.9023689 7.68341751,11.9023689 7.29289322,12.2928932 C6.90236893,12.6834175 6.90236893,13.3165825 7.29289322,13.7071068 L9.29289322,15.7071068 C9.68341751,16.0976311 10.3165825,16.0976311 10.7071068,15.7071068 L16.7071068,9.70710678 C17.0976311,9.31658249 17.0976311,8.68341751 16.7071068,8.29289322 C16.3165825,7.90236893 15.6834175,7.90236893 15.2928932,8.29289322 Z" />
        </svg>
    );
};

export const IconPlusSquare: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.19 2.00003H7.81C4.17 2.00003 2 4.17003 2 7.81003V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81003C22 4.17003 19.83 2.00003 16.19 2.00003ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8.00003C11.25 7.59003 11.59 7.25003 12 7.25003C12.41 7.25003 12.75 7.59003 12.75 8.00003V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowSquareUp: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0572 2H8.67719C5.03719 2 2.86719 4.17 2.86719 7.81V16.18C2.86719 19.83 5.03719 22 8.67719 22H17.0472C20.6872 22 22.8572 19.83 22.8572 16.19V7.81C22.8672 4.17 20.6972 2 17.0572 2ZM16.9272 14C16.7772 14.15 16.5872 14.22 16.3972 14.22C16.2072 14.22 16.0172 14.14 15.8672 14L12.8672 11L9.86719 14C9.57719 14.29 9.09719 14.29 8.80719 14C8.51719 13.71 8.51719 13.23 8.80719 12.94L12.3372 9.41C12.6272 9.12 13.1072 9.12 13.3972 9.41L16.9272 12.94C17.2172 13.23 17.2172 13.7 16.9272 14Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconArrowSquareDown: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.43256 22.6224L16.8126 22.6224C20.4526 22.6224 22.6226 20.4524 22.6226 16.8124L22.6226 8.44244C22.6226 4.79244 20.4526 2.62244 16.8126 2.62244L8.44256 2.62244C4.80256 2.62243 2.63256 4.79243 2.63256 8.43243L2.63256 16.8124C2.62256 20.4524 4.79256 22.6224 8.43256 22.6224ZM8.56256 10.6224C8.71256 10.4724 8.90256 10.4024 9.09256 10.4024C9.28256 10.4024 9.47256 10.4824 9.62256 10.6224L12.6226 13.6224L15.6226 10.6224C15.9126 10.3324 16.3926 10.3324 16.6826 10.6224C16.9726 10.9124 16.9726 11.3924 16.6826 11.6824L13.1526 15.2124C12.8626 15.5024 12.3826 15.5024 12.0926 15.2124L8.56256 11.6824C8.27256 11.3924 8.27256 10.9224 8.56256 10.6224Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconTrushSquare: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1905 2H7.81049C4.17049 2 2.00049 4.17 2.00049 7.81V16.18C2.00049 19.83 4.17049 22 7.81049 22H16.1805C19.8205 22 21.9905 19.83 21.9905 16.19V7.81C22.0005 4.17 19.8305 2 16.1905 2ZM15.7605 15.76C15.7005 16.61 15.6305 17.67 13.7105 17.67H10.2905C8.38049 17.67 8.30049 16.61 8.24049 15.76L7.93049 11.8C7.91049 11.54 8.00049 11.29 8.17049 11.1C8.34049 10.91 8.59049 10.81 8.84049 10.81H15.1605C15.4105 10.81 15.6605 10.92 15.8305 11.1C16.0005 11.29 16.0905 11.54 16.0705 11.79L15.7605 15.76ZM16.8005 9.82C16.7805 9.82 16.7605 9.82 16.7405 9.82C15.7005 9.72 14.7505 9.65 13.8405 9.61C12.6205 9.55 11.4005 9.53 10.1705 9.58C9.57049 9.61 8.96049 9.65 8.36049 9.71L7.27049 9.82C7.25049 9.82 7.22049 9.82 7.20049 9.82C6.85049 9.82 6.55049 9.56 6.52049 9.2C6.48049 8.83 6.76049 8.49 7.13049 8.46L8.22049 8.35C8.65049 8.31 9.07049 8.28 9.50049 8.26L9.58049 7.79C9.66049 7.29 9.81049 6.33 11.3105 6.33H12.7005C14.2105 6.33 14.3605 7.32 14.4305 7.8L14.5105 8.28C15.2605 8.32 16.0305 8.38 16.8705 8.46C17.2505 8.5 17.5205 8.83 17.4905 9.21C17.4505 9.56 17.1505 9.82 16.8005 9.82Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconClock: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24.984375 6.9863281 A 1.0001 1.0001 0 0 0 24 8 L 24 22.173828 A 3 3 0 0 0 22 25 A 3 3 0 0 0 22.294922 26.291016 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 23.708984 27.705078 A 3 3 0 0 0 25 28 A 3 3 0 0 0 28 25 A 3 3 0 0 0 26 22.175781 L 26 8 A 1.0001 1.0001 0 0 0 24.984375 6.9863281 z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconCamera: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 223.708 223.708" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="m222.399,60.893c-1.276-1.782-3.314-2.863-5.505-2.922l-20.248-.539 2.301-7.406c1.147-3.692-0.916-7.615-4.607-8.762l-124.146-38.574c-3.694-1.151-7.614,0.916-8.761,4.607l-19.287,62.073c-1.147,3.692 0.916,7.615 4.607,8.762l25.148,7.814-4.154,13.37c-0.551,1.773-0.375,3.692 0.489,5.335 0.864,1.643 2.345,2.875 4.118,3.426l11.424,3.55-28.371,45.868c-4.106-1.812-8.639-2.825-13.407-2.825h-35c-3.866,0-7,3.134-7,7v52.667c0,3.866 3.134,7 7,7h35c18.38,0 33.333-14.953 33.333-33.333 0-8.571-3.254-16.395-8.588-22.307 0.132-0.174 0.265-0.347 0.382-0.536l30.459-49.243 14.876,4.622c0.692,0.215 1.391,0.317 2.079,0.317 2.985,0 5.75-1.925 6.683-4.925l4.154-13.37 45.52,14.144c0.679,0.211 1.379,0.315 2.077,0.315 1.125,0 2.244-0.271 3.258-0.805 1.643-0.864 2.875-2.345 3.426-4.118l2.301-7.406 16.989,11.031c1.149,0.746 2.476,1.129 3.813,1.129 0.801,0 1.606-0.138 2.379-0.417 2.062-0.745 3.655-2.413 4.305-4.506l13.946-44.883c0.651-2.093 0.283-4.371-0.993-6.153zm-208.399,107.776h9.333v38.667h-9.333v-38.667zm28,38.667h-4.667v-38.667h4.667c10.66,0 19.333,8.673 19.333,19.333s-8.673,19.334-19.333,19.334zm67.932-102.243l-26.739-8.309 2.077-6.685 26.739,8.309-2.077,6.685zm58.436-3.833l-110.776-34.421 15.133-48.703 110.776,34.42-2.967,9.549-9.199,29.604c0,0.002-0.001,0.003-0.001,0.005l-2.966,9.546zm30.452-2.313l-12.543-8.144 6.053-19.481 14.95,.398-8.46,27.227z" fill={colors.get(color)} />
                <path d="m74.084,51.697c-1.84,0-3.65,0.75-4.95,2.05-1.3,1.31-2.05,3.11-2.05,4.95s0.75,3.65 2.05,4.95c1.3,1.31 3.11,2.05 4.95,2.05 1.84,0 3.65-0.74 4.95-2.05 1.3-1.3 2.05-3.11 2.05-4.95s-0.75-3.64-2.05-4.95c-1.3-1.3-3.11-2.05-4.95-2.05z" fill={colors.get(color)} />
            </g>
        </svg>
    );
};

export const BackgroundCreateContent = () => {
    return (
        <svg width="1280" height="941" viewBox="0 0 1280 941" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0, zIndex: 0 }}>
            <rect width="1280" height="941" fill="#F2F2F2" />
            <rect width="1280" height="941" fill="url(#pattern0)" fillOpacity="0.1" />
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0046875" height="0.0063762">
                    <use xlinkHref="#image0_223_1581" transform="translate(0.0046875) scale(0.000390625 0.00053135) rotate(90)" />
                </pattern>
                <image id="image0_223_1581" width="12" height="12" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAArSURBVHgB7dGhEQBACAPB5OtK+enrwWBxCGZYfe5o+yNJIhrVPZyVOH46AF2yDp2J2FtXAAAAAElFTkSuQmCC" />
            </defs>
        </svg>
    );
};

export const IconUploadFile: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M75.1666 47.4917V55.3158C75.1666 67.7525 67.7524 75.1667 55.3158 75.1667H26.6841C17.9716 75.1667 11.6849 71.5108 8.74658 65.0192L9.12242 64.7458L25.9324 53.4708C28.6657 51.6258 32.5266 51.8308 34.9524 53.9492L36.1141 54.9058C38.7791 57.195 43.0841 57.195 45.7491 54.9058L59.9624 42.7083C62.6274 40.4192 66.9324 40.4192 69.5974 42.7083L75.1666 47.4917Z" fill={colors.get(color)} />
            <path opacity="0.4" d="M71.6477 27.3333H61.6027C57.2635 27.3333 54.6668 24.7366 54.6668 20.3975V10.3525C54.6668 8.98581 54.9402 7.82415 55.4185 6.83331C55.3843 6.83331 55.3502 6.83331 55.316 6.83331H26.6843C14.2477 6.83331 6.8335 14.2475 6.8335 26.6841V55.3158C6.8335 59.04 7.48266 62.2858 8.74683 65.0191L9.12266 64.7458L25.9327 53.4708C28.666 51.6258 32.5268 51.8308 34.9527 53.9491L36.1143 54.9058C38.7793 57.195 43.0843 57.195 45.7493 54.9058L59.9627 42.7083C62.6277 40.4191 66.9327 40.4191 69.5977 42.7083L75.1668 47.4916V26.6841C75.1668 26.65 75.1668 26.6158 75.1668 26.5816C74.176 27.06 73.0143 27.3333 71.6477 27.3333Z" fill={colors.get(color)} />
            <path d="M30.7498 35.465C35.2408 35.465 38.8815 31.8243 38.8815 27.3333C38.8815 22.8423 35.2408 19.2017 30.7498 19.2017C26.2588 19.2017 22.6182 22.8423 22.6182 27.3333C22.6182 31.8243 26.2588 35.465 30.7498 35.465Z" fill={colors.get(color)} />
            <path d="M71.6473 3.41669H61.6023C57.2632 3.41669 54.6665 6.01335 54.6665 10.3525V20.3975C54.6665 24.7367 57.2632 27.3334 61.6023 27.3334H71.6473C75.9865 27.3334 78.5832 24.7367 78.5832 20.3975V10.3525C78.5832 6.01335 75.9865 3.41669 71.6473 3.41669ZM74.859 16.8442C74.5173 17.1859 74.0048 17.425 73.4582 17.4592H68.6407L68.6748 22.2084C68.6407 22.7892 68.4357 23.2675 68.0257 23.6775C67.684 24.0192 67.1715 24.2584 66.6248 24.2584C65.4973 24.2584 64.5748 23.3359 64.5748 22.2084V17.425L59.7915 17.4592C58.664 17.4592 57.7415 16.5025 57.7415 15.375C57.7415 14.2475 58.664 13.325 59.7915 13.325L64.5748 13.3592V8.57585C64.5748 7.44835 65.4973 6.49169 66.6248 6.49169C67.7523 6.49169 68.6748 7.44835 68.6748 8.57585L68.6407 13.325H73.4582C74.5857 13.325 75.5082 14.2475 75.5082 15.375C75.474 15.9559 75.2348 16.4342 74.859 16.8442Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconUploadFileOver: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
                <path opacity="0.4" d="M70.0415 34.8158H60.1673C52.0698 34.8158 45.4757 28.2216 45.4757 20.1241V10.25C45.4757 8.37081 43.9382 6.83331 42.059 6.83331H27.5723C17.049 6.83331 8.5415 13.6666 8.5415 25.8641V56.1358C8.5415 68.3333 17.049 75.1666 27.5723 75.1666H54.4273C64.9507 75.1666 73.4582 68.3333 73.4582 56.1358V38.2325C73.4582 36.3533 71.9207 34.8158 70.0415 34.8158Z" fill={colors.get(color)} />
                <path d="M53.9834 7.55083C52.5826 6.15 50.1567 7.10666 50.1567 9.05416V20.9783C50.1567 25.9667 54.3934 30.1008 59.5526 30.1008C62.7984 30.135 67.3084 30.135 71.1692 30.135C73.1167 30.135 74.1417 27.8458 72.7751 26.4792C67.8551 21.525 59.0401 12.6075 53.9834 7.55083Z" fill={colors.get(color)} />
                <path d="M41.9568 50.2933C40.9659 49.3025 39.3259 49.3025 38.3351 50.2933L35.8751 52.7533V38.4375C35.8751 37.0367 34.7134 35.875 33.3126 35.875C31.9118 35.875 30.7501 37.0367 30.7501 38.4375V52.7533L28.2901 50.2933C27.2993 49.3025 25.6593 49.3025 24.6684 50.2933C23.6776 51.2842 23.6776 52.9242 24.6684 53.915L31.5018 60.7483C31.5359 60.7825 31.5701 60.7825 31.5701 60.8167C31.7751 61.0217 32.0484 61.1925 32.3218 61.3292C32.6634 61.4317 32.9709 61.5 33.3126 61.5C33.6543 61.5 33.9618 61.4317 34.2693 61.295C34.5768 61.1583 34.8501 60.9875 35.1234 60.7483L41.9568 53.915C42.9476 52.9242 42.9476 51.2842 41.9568 50.2933Z" fill={colors.get(color)} />
            </g>
        </svg>
    );
};

export const IconNetwork: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.41 9.83C15.8 11.57 14.16 12.74 12.32 12.74C12.31 12.74 12.31 12.74 12.3 12.74L10.24 12.73C10.24 12.73 10.24 12.73 10.23 12.73C9.47 12.73 8.81 13.24 8.61 13.97C9.5 14.25 10.15 15.08 10.15 16.06C10.15 17.27 9.16 18.26 7.95 18.26C6.74 18.26 5.75 17.27 5.75 16.06C5.75 15.17 6.29 14.4 7.05 14.06V9.7C6.29 9.4 5.75 8.66 5.75 7.8C5.75 6.67 6.67 5.75 7.8 5.75C8.93 5.75 9.85 6.67 9.85 7.8C9.85 8.67 9.31 9.4 8.55 9.7V11.72C9.04 11.41 9.62 11.23 10.23 11.23H10.24L12.3 11.24C13.48 11.28 14.53 10.52 14.95 9.42C14.46 9.04 14.14 8.46 14.14 7.8C14.14 6.67 15.06 5.75 16.19 5.75C17.32 5.75 18.24 6.67 18.24 7.8C18.25 8.86 17.44 9.72 16.41 9.83Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconDisplay: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m 3 0 c -1.660156 0 -3 1.339844 -3 3 v 7 c 0 1.660156 1.339844 3 3 3 h 10 c 1.660156 0 3 -1.339844 3 -3 v -1 c 0 -0.550781 -0.449219 -1 -1 -1 s -1 0.449219 -1 1 v 1 c 0 0.554688 -0.445312 1 -1 1 h -10 c -0.554688 0 -1 -0.445312 -1 -1 v -7 c 0 -0.554688 0.445312 -1 1 -1 h 4 c 0.550781 0 1 -0.449219 1 -1 s -0.449219 -1 -1 -1 z m 7 0 c -0.550781 0 -1 0.449219 -1 1 s 0.449219 1 1 1 h 2.585938 l -5.292969 5.289062 c -0.1875 0.191407 -0.292969 0.445313 -0.292969 0.710938 s 0.105469 0.519531 0.292969 0.707031 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 l 5.292969 -5.292969 v 2.585938 c 0 0.550781 0.449219 1 1 1 s 1 -0.449219 1 -1 v -5 c 0 -0.085938 -0.011719 -0.171875 -0.035156 -0.257812 c -0.023438 -0.085938 -0.054688 -0.167969 -0.101563 -0.242188 c -0.042969 -0.074219 -0.09375 -0.144531 -0.15625 -0.207031 c 0 0 0 0 0 -0.003907 c -0.015625 -0.011718 -0.03125 -0.023437 -0.046875 -0.035156 c -0.054687 -0.046875 -0.117187 -0.089844 -0.183594 -0.128906 c -0.035156 -0.015625 -0.074218 -0.03125 -0.113281 -0.046875 c -0.050781 -0.0195312 -0.101562 -0.0351562 -0.15625 -0.0507812 c -0.039062 -0.0078126 -0.082031 -0.0117188 -0.121093 -0.015625 c -0.027344 -0.0039063 -0.058594 -0.00781255 -0.085938 -0.0117188 z m -5 14 c -1.105469 0 -2 0.894531 -2 2 h 10 c 0 -1.105469 -0.894531 -2 -2 -2 z m 0 0" fill={colors.get(color)} />
            <path d="m 3 3 v 7 h 10 v -4.171875 l -3.585938 3.585937 c -0.773437 0.773438 -2.054687 0.773438 -2.828124 0 c -0.773438 -0.773437 -0.773438 -2.058593 0 -2.832031 l 3.585937 -3.582031 z m 0 0" fill={colors.get(color)} fillOpacity="0.34902" />
        </svg>
    );
};

export const IconDemo: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 1920 1920" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={colors.get(color)} d="M155.676 1349.19v337.3c0 42.99 34.849 77.83 77.838 77.83h337.297V1920H233.514C104.548 1920 0 1815.45 0 1686.49v-337.3h155.676Zm1764.324 0v337.3c0 128.96-104.55 233.51-233.51 233.51h-337.3v-155.68h337.3c42.99 0 77.83-34.84 77.83-77.83v-337.3H1920ZM960 544.865c229.27 0 415.14 185.862 415.14 415.135 0 229.27-185.87 415.14-415.14 415.14-229.273 0-415.135-185.87-415.135-415.14 0-229.273 185.862-415.135 415.135-415.135ZM1686.49 0C1815.45 0 1920 104.548 1920 233.514v337.297h-155.68V233.514c0-42.989-34.84-77.838-77.83-77.838h-337.3V0ZM570.811 0v155.676H233.514c-42.989 0-77.838 34.849-77.838 77.838v337.297H0V233.514C0 104.548 104.548 0 233.514 0h337.297Z" />
        </svg>
    );
};

export const IconUpdate: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.22 20.75H5.78C5.43322 20.7359 5.09262 20.6535 4.77771 20.5075C4.4628 20.3616 4.17975 20.155 3.94476 19.8996C3.70977 19.6442 3.52745 19.3449 3.40824 19.019C3.28903 18.693 3.23525 18.3468 3.25 18V15C3.25 14.8011 3.32902 14.6103 3.46967 14.4697C3.61033 14.329 3.80109 14.25 4 14.25C4.19892 14.25 4.38968 14.329 4.53033 14.4697C4.67099 14.6103 4.75 14.8011 4.75 15V18C4.72419 18.2969 4.81365 18.5924 4.99984 18.8251C5.18602 19.0579 5.45465 19.21 5.75 19.25H18.22C18.5154 19.21 18.784 19.0579 18.9702 18.8251C19.1564 18.5924 19.2458 18.2969 19.22 18V15C19.22 14.8011 19.299 14.6103 19.4397 14.4697C19.5803 14.329 19.7711 14.25 19.97 14.25C20.1689 14.25 20.3597 14.329 20.5003 14.4697C20.641 14.6103 20.72 14.8011 20.72 15V18C20.75 18.6954 20.5041 19.3744 20.0359 19.8894C19.5677 20.4045 18.9151 20.7137 18.22 20.75Z" fill={colors.get(color)} />
            <path d="M16 8.74995C15.9015 8.75042 15.8038 8.7312 15.7128 8.69342C15.6218 8.65564 15.5392 8.60006 15.47 8.52995L12 5.05995L8.53 8.52995C8.38782 8.66243 8.19978 8.73455 8.00548 8.73113C7.81118 8.7277 7.62579 8.64898 7.48838 8.51157C7.35096 8.37416 7.27225 8.18877 7.26882 7.99447C7.2654 7.80017 7.33752 7.61213 7.47 7.46995L11.47 3.46995C11.6106 3.3295 11.8012 3.25061 12 3.25061C12.1987 3.25061 12.3894 3.3295 12.53 3.46995L16.53 7.46995C16.6705 7.61058 16.7493 7.8012 16.7493 7.99995C16.7493 8.1987 16.6705 8.38932 16.53 8.52995C16.4608 8.60006 16.3782 8.65564 16.2872 8.69342C16.1962 8.7312 16.0985 8.75042 16 8.74995Z" fill={colors.get(color)} />
            <path d="M12 15.75C11.8019 15.7474 11.6126 15.6676 11.4725 15.5275C11.3324 15.3874 11.2526 15.1981 11.25 15V4C11.25 3.80109 11.329 3.61032 11.4697 3.46967C11.6103 3.32902 11.8011 3.25 12 3.25C12.1989 3.25 12.3897 3.32902 12.5303 3.46967C12.671 3.61032 12.75 3.80109 12.75 4V15C12.7474 15.1981 12.6676 15.3874 12.5275 15.5275C12.3874 15.6676 12.1981 15.7474 12 15.75Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconWifi: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path xmlns="http://www.w3.org/2000/svg" d="M12,16.5 C12.8284,16.5 13.5,17.1716 13.5,18 C13.5,18.8284 12.8284,19.5 12,19.5 C11.1716,19.5 10.5,18.8284 10.5,18 C10.5,17.1716 11.1716,16.5 12,16.5 Z M11.9999,12.5 C13.5183,12.5 14.8954,13.1173 15.889,14.1109 C16.4748,14.6967 16.4748,15.6464 15.889,16.2322 C15.3398125,16.7813875 14.470791,16.8157117 13.8816243,16.3351727 L13.7677,16.2322 C13.3136,15.7782 12.6907,15.5 11.9999,15.5 C11.37827,15.5 10.811477,15.725342 10.3733687,16.1013764 L10.2322,16.2322 C9.64638,16.818 8.69663,16.818 8.11084,16.2322 C7.52506,15.6464 7.52506,14.6967 8.11084,14.1109 C9.10451,13.1173 10.4816,12.5 11.9999,12.5 Z M11.9999,8.5 C14.6229,8.5 16.9999,9.56497 18.7174,11.2825 C19.3032,11.8683 19.3032,12.818 18.7174,13.4038 C18.1317,13.9896 17.1819,13.9896 16.5961,13.4038 C15.4182,12.2259 13.7953,11.5 11.9999,11.5 C10.2046,11.5 8.58165,12.2259 7.40374,13.4038 C6.81795,13.9896 5.8682,13.9896 5.28242,13.4038 C4.69663,12.818 4.69663,11.8683 5.28242,11.2825 C6.99994,9.56497 9.37699,8.5 11.9999,8.5 Z M11.9999,4.5 C15.7274,4.5 19.1045,6.01268 21.5459,8.45406 C22.1317,9.03984 22.1317,9.98959 21.5459,10.5754 C20.9601,11.1612 20.0103,11.1612 19.4245,10.5754 C17.5228,8.67361 14.8998,7.5 11.9999,7.5 C9.10002,7.5 6.47708,8.67361 4.57531,10.5754 C3.98952,11.1612 3.03977,11.1612 2.45399,10.5754 C1.8682,9.98959 1.8682,9.03984 2.45399,8.45406 C4.89537,6.01268 8.27242,4.5 11.9999,4.5 Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconHelp: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_528_5051)">
                <path d="M8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8.00004C14.6668 4.31814 11.6821 1.33337 8.00016 1.33337C4.31826 1.33337 1.3335 4.31814 1.3335 8.00004C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667Z" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.06006 6.00001C6.21679 5.55446 6.52616 5.17875 6.93336 4.93944C7.34056 4.70012 7.81932 4.61264 8.28484 4.69249C8.75036 4.77234 9.1726 5.01436 9.47678 5.3757C9.78095 5.73703 9.94743 6.19436 9.94672 6.66668C9.94672 8.00001 7.94673 8.66668 7.94673 8.66668" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 11.3334H8.00667" stroke={colors.get(color)} strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_528_5051">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
