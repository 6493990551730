import React from "react";
import { Bar } from "react-chartjs-2";
import styles from "../AbsoluteRelativeChart/styles.module.scss";
import { useTranslation } from "i18nano";


export interface IBarChartProps {
    labels: string[] | undefined;
    headerLabel: string;
    datasets: { label: string, data: number[] }[] | undefined,
    stackedBar?: boolean
}

const GenderAgeBar: React.FC<IBarChartProps> = ({labels, headerLabel, datasets, stackedBar = true }) => {
    const t: (path: string) => string = useTranslation();

    const colors: { [id: string] : string; } = {
        [t("analytic.chart_color_labels.kid_male")]: "rgba(21, 97, 172, 1)",
        [t("analytic.chart_color_labels.young_male")]: "rgba(28, 125, 239, 1)",
        [t("analytic.chart_color_labels.adult_male")]: "rgba(73, 152, 229, 1)",
        [t("analytic.chart_color_labels.old_male")]: "rgba(119, 178, 236, 1)",
        [t("analytic.chart_color_labels.kid_female")]: "rgba(234, 145, 137, 1)",
        [t("analytic.chart_color_labels.young_female")]: "rgba(228, 108, 98, 1)",
        [t("analytic.chart_color_labels.adult_female")]: "rgba(221, 71, 59, 1)",
        [t("analytic.chart_color_labels.old_female")]: "rgba(214, 17, 1, 1)",
        [t("analytic.chart_color_labels.ots")]: "rgba(56, 104, 195, 1)",
        [t("analytic.chart_color_labels.watchers")]: "rgba(0, 102, 43, 1)",
        [t("analytic.chart_color_labels.dwell_time")]: "rgba(56, 104, 195, 1)",
        [t("analytic.chart_color_labels.attention_time")]: "rgba(0, 102, 43, 1)",
        [t("analytic.chart_color_labels.happy")]: "rgba(10, 134, 35, 1)",
        [t("analytic.chart_color_labels.negative")]: "rgba(88, 88, 88, 1)",
        [t("analytic.chart_color_labels.neutral")]: "rgba(119, 178, 236, 1)",
        [t("analytic.chart_color_labels.surprise")]: "rgba(234, 145, 137, 1)",
        [t("analytic.chart_color_labels.male")]: "rgba(119, 178, 236, 1)",
        [t("analytic.chart_color_labels.female")]: "rgba(234, 145, 137, 1)",
        [t("analytic.chart_color_labels.kid")]: "rgba(21, 97, 172, 1)",
        [t("analytic.chart_color_labels.young")]: "rgba(28, 125, 239, 1)",
        [t("analytic.chart_color_labels.adult")]: "rgba(73, 152, 229, 1)",
        [t("analytic.chart_color_labels.old")]: "rgba(119, 178, 236, 1)",
    };

    const optionsBar = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom" as const,
                labels: {
                    pointStyle: "rectRounded",
                    usePointStyle: true
                }
            }
        },
        scales: {
            x: {
                stacked: stackedBar,
            },
            y: {
                stacked: stackedBar,
            },
        },
    };

    const daysDatasets: {
        label: string,
        data: number[],
        backgroundColor: string,
        borderRadius: number
    }[] = [];

    datasets?.forEach((daySets: { label: string, data: number[] }, index: number) => {
        daysDatasets.push({
            label: daySets?.label ? daySets.label : '',
            data: daySets.data,
            backgroundColor: colors[daySets.label],
            borderRadius: 4
        })
    });
    const barChart = { 
        labels: labels,
        datasets: daysDatasets
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div>{headerLabel}</div>
            </div>
            <div className={styles.chart_container}>
                <Bar data={barChart} options={optionsBar} />
            </div>
        </div>
    );
};

export default GenderAgeBar;