import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContentContainer from "../../../components/ContentContainer";
import { IconCloseSquare, IconPlaylistFile, IconEdit, ModalConfirm, Tooltip } from "../../../components/UI";
import { usePlaylistStore } from "../../../stores";
import { IPlaylist } from "../../../types/playlist";


interface IProps {
    playlist: IPlaylist,
}

const PlaylistItem: React.FC<IProps> = ({ playlist }) => {
    const { selectPlaylist, deletePlaylist } = usePlaylistStore(state => state);

    const t = useTranslation();
    const [showConfirm, setShowConfirm] = useState(false);

    return (
        <div className="table-item cols-3">
            <div className={"checkbox-container"}>
                <input
                    type="checkbox"
                    checked={playlist.isChecked}
                    onChange={() => selectPlaylist({ selectState: !playlist.isChecked, selectedData: playlist.id })}
                />
            </div>
            <div className={"preview-container"}>
                <div className={playlist.preview ? "preview" : ""}>
                    {playlist.preview?.length
                        ? <ContentContainer url={playlist.preview} contentType={"preview"} />
                        : <IconPlaylistFile color={"black"} size={40} />
                    }
                </div>
                <div className={"name"}>{playlist.name}</div>
            </div>
            <div>{playlist.description}</div>
            <div className={"settings-container"}>
                <Tooltip text={t("actions.change") + " " + t("playlist.one")}>
                    <span className="action-container">
                        <Link to={`/playlists/edit/${playlist.id}`}>
                            <IconEdit color={"blue"} size={25} />
                        </Link>
                    </span>
                </Tooltip>
                <Tooltip text={t("actions.delete") + " " + t("playlist.one")}>
                    <span className="action-container" onClick={() => setShowConfirm(true)}><IconCloseSquare color={"red"} size={25} /></span>
                </Tooltip>
            </div>
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => deletePlaylist([playlist.id.toString()])} onCancel={() => { }}>
                <p>{t("confirm.delete", { name: playlist.name })}</p>
            </ModalConfirm>
        </div>
    );
};

export default PlaylistItem;