import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { rtlLangs } from "../../../../assets/locale";
import useMount from "../../../../hooks/useMount";
import { IconCloseX } from "../../Icons";
import Portal from "../../Portal";

import styles from "./styles.module.scss";
import modalAnimation from "../animation/modal-animation.module.scss";
import overlayAnimation from "../animation/overlay-animation.module.scss";

interface IProps {
    isShow: boolean;
    setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    title?: string;
    subtitle?: string;
    disableOverlay?: boolean;
    onClose?: () => void;
}

const ANIMATION_TIME = 300;

const ModalContent: React.FC<IProps> = ({ children, isShow, setIsShow, title, subtitle, disableOverlay, onClose = (() => { }) }) => {
    const overlayRef = useRef(null);
    const modalRef = useRef(null);

    const [animationIn, setAnimationIn] = useState(false);

    useEffect(() => {
        document.body.style.overflow = isShow ? "hidden" : "";
        setAnimationIn(isShow);
    }, [isShow]);

    useEffect(() => {
        return () => {
            onClose();
        };
    }, []);

    return (
        <div className={styles.modal_container}>
            <CSSTransition
                in={animationIn}
                nodeRef={overlayRef}
                timeout={ANIMATION_TIME}
                mountOnEnter
                unmountOnExit
                classNames={overlayAnimation}
            >
                <div ref={overlayRef} className={styles.modal_overlay} onClick={() => disableOverlay ? null : setIsShow(false)}></div>
            </CSSTransition>

            <CSSTransition
                in={animationIn}
                nodeRef={modalRef}
                timeout={ANIMATION_TIME}
                mountOnEnter
                unmountOnExit
                classNames={modalAnimation}
            >
                <div ref={modalRef} className={styles.modal_content}>
                    {title ?
                        <div className={styles.modal_control}>
                            <div className={styles.modal_title}>
                                <div className={styles.title}>{title}</div>
                                {subtitle ?
                                    <div className={styles.subtitle}>
                                        {subtitle}
                                    </div>
                                    : null
                                }
                            </div>
                            {!disableOverlay
                                ? <div className={styles.close} onClick={() => setIsShow(false)}>
                                    <IconCloseX size={25} />
                                </div>
                                : null
                            }
                        </div>
                        : null
                    }

                    <div className={styles.modal_body}>
                        {children}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

const ModalDefault: React.FC<IProps> = (props) => {
    const lang = window.localStorage.getItem("lang");
    const { mounted } = useMount(props.isShow, ANIMATION_TIME);

    if (!mounted) {
        return null;
    }

    return (
        <Portal>
            <div dir={rtlLangs.includes(lang!) ? "rtl" : "ltr"}>
                <ModalContent {...props} >
                    {props.children}
                </ModalContent>
            </div>
        </Portal>
    );
};

export default ModalDefault;