import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ITerminalSetupStore } from "../../types/terminalSetup";
import { changeDisplaySetupApi, connectTerminalToWifiApi, getTerminalApi } from "../../api/terminal-api";


export const useTerminalSetupStore = create<ITerminalSetupStore>()(
    immer(
        (set, get) => ({
            loading: false,
            error: undefined,
            fetchTerminal: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getTerminalApi(filters).then((data) => {
                    setTimeout(() => {
                        set((state) => {
                            state.terminal = data;
                        });
                    }, 300);
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            connectWifi: async (data) => {
                return await connectTerminalToWifiApi(data).then(() => {
                    return true;
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                    return false;
                });
            },
            setDisaplySetup: async (data) => {
                const { terminal } = get();

                if (!terminal) {
                    return;
                }

                await changeDisplaySetupApi(data).then(() => {
                    set(state => {
                        state.terminal!.display_setup = { ...terminal.display_setup, ...data };
                        state.successMessage = {
                            title: "Success",
                            body: "The settings have been changed successfully. Their application may take some time."
                        };
                    });
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                });
            }
        })
    )
);
