import React from "react";
import "./LoadedItem.scss";
import { IconCloseSquare, IconImageFile, IconVideoFile } from "../../../../components/UI";


interface IProps {
    file: File;
    index: number;
    discardFileHandler: (val: number) => void;
}

const formatSize = (length: number) => {
    let i = 0;
    const type = ["b", "Kb", "Mb", "Gb"];

    while ((length / 1000 | 0) && i < type.length - 1) {
        length /= 1024;
        i += 1;
    }

    return length.toFixed(2) + type[i];
};


const LoadedItem: React.FC<IProps> = ({ file, discardFileHandler, index }) => {

    return (
        <div className={"loadedItem"}>
            <div className={"loadFileIcon"}>
                {file.type.split("/")[0] === "image"
                    ? <IconImageFile color={"blue"} size={30} />
                    : <IconVideoFile color={"blue"} size={30} />
                }
            </div>
            <div className={"loadItemName"}>
                <span className="item-name">
                    {file.name}
                </span>
                <span className="item-size">
                    {formatSize(file.size)}
                </span>
            </div>
            <div className={"loadFileCheck"} onClick={() => discardFileHandler(index)}>
                <IconCloseSquare color={"red"} size={22} />
            </div>
        </div>
    );
};

export default LoadedItem;