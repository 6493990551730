import { ITriggers } from "../../types/createPlaylist";
import { IPlaylist, IPlaylistItemTriggers, ITriggerCv } from "../../types/playlist";

export const defaultState: IPlaylist = {
    id: "",
    name: "",
    description: "",
    preview: "",
    duration: 150,
    required_duration: 100,
    playlist_items: [],
    isChecked: false
};

export const changeRespPlaylist = (playlist: IPlaylist, triggers: Record<string, ITriggers>): IPlaylist => {
    const genderList = ["male", "female"];
    const ageList = ["kid", "young", "adult", "old"];
    const emotionList = ["happy", "neutral", "surprise", "negative"];

    const respPlaylist = { ...playlist };
    const newTriggers: Record<string, IPlaylistItemTriggers> = {};

    let cvList: string[];
    let newCvTrigger: ITriggerCv;

    for (const [playlistItemId, playlistItemTriggers] of Object.entries(triggers)) {
        newTriggers[playlistItemId] = {
            cv: [],
            datetime: [],
            eye_contact: null,
            gesture: null
        };

        if (Object.keys(playlistItemTriggers.cv).length > 0) {
            cvList = Object.values(playlistItemTriggers.cv);
            newCvTrigger = {
                condition_types: [],
                conditions: ""
            };

            for (const cvItem of cvList) {
                newCvTrigger.conditions = cvItem;
                for (const cv of cvItem.split("_")) {
                    if (genderList.includes(cv) && !newCvTrigger.condition_types.includes("gender")) {
                        newCvTrigger.condition_types.push("gender");
                    }
                    if (ageList.includes(cv) && !newCvTrigger.condition_types.includes("age_group")) {
                        newCvTrigger.condition_types.push("age_group");
                    }
                    if (emotionList.includes(cv) && !newCvTrigger.condition_types.includes("emotion")) {
                        newCvTrigger.condition_types.push("emotion");
                    }
                }
            }

            newTriggers[playlistItemId].cv.push(newCvTrigger);
        }

        if (Object.keys(playlistItemTriggers.datetime).length > 0) {
            newTriggers[playlistItemId].datetime = Object.values(playlistItemTriggers.datetime);
        }

        if (playlistItemTriggers.eye_contact) {
            newTriggers[playlistItemId].eye_contact = playlistItemTriggers.eye_contact;
        }

        if (playlistItemTriggers.gesture) {
            newTriggers[playlistItemId].gesture = playlistItemTriggers.gesture;
        }
    }

    respPlaylist.playlist_items = respPlaylist.playlist_items.map(item => {
        return {
            ...item,
            triggers: newTriggers[item.id]
        };
    });

    return respPlaylist;
};