import classnames from "classnames";
import { useTranslation } from "i18nano";
import React from "react";
import ContentContainer from "../../../../components/ContentContainer";
import { IconCloseX, IconImageFile, IconScenarioFile, IconVideoFile, useNotification } from "../../../../components/UI";
import { useCreatePlaylistStore } from "../../../../stores";
import { IPlaylistItem, TPlaylistItemType } from "../../../../types/playlist";
import "./PlaylistItem.scss";


interface IProps {
    playlistItem: IPlaylistItem;
    index: number;
}

const PlaylistItem: React.FC<IProps> = ({ playlistItem, index }) => {
    const {
        currentPlaylistItem,
        deletePlaylistItem,
        setCurrentPlaylist
    } = useCreatePlaylistStore(state => state);

    const t = useTranslation();
    const { addNotific } = useNotification();

    const deletePlaylistItemHandler = (deleteId?: string) => {
        if (deleteId) {
            deletePlaylistItem(deleteId);
        } else {
            if (currentPlaylistItem) {
                deletePlaylistItem(currentPlaylistItem.id);
            } else {
                addNotific({
                    title: t("notification.error.label"),
                    body: t("notification.error.create_playlsit.left_control_panel.nothing_delete"),
                    type: "danger"
                });
            }
        }
    };

    const icons = (content: TPlaylistItemType, size: number) => {
        switch (content) {
            case "image":
                return <IconImageFile size={size} color={"black"} />;
            case "scenario":
                return <IconScenarioFile size={size} color={"black"} />;
            case "video":
                return <IconVideoFile size={size} color={"black"} />;
        }
    };

    const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData("application/playlistitem", index.toString());
        event.dataTransfer.effectAllowed = "move";
    };

    return (
        <div
            className={classnames("playlistItem", { "active": currentPlaylistItem?.id === playlistItem.id })}
            onClick={() => setCurrentPlaylist(playlistItem.id)}
            onDragStart={onDragStart}
            draggable
        >
            <div className={classnames("previewContainer")}>
                {playlistItem.preview.length
                    ? <>
                        <ContentContainer url={playlistItem.preview} contentType={"preview"} />
                        <span style={{
                            background: playlistItem.type === "video" ? "rgba(0, 96, 174, 0.6)" :
                                playlistItem.type === "image" ? "rgba(222, 134, 26, 0.6)" :
                                    "rgba(222, 26, 140, 0.3)"
                        }}>{icons(playlistItem.type, 15)}</span>
                    </>
                    : <>
                        {icons(playlistItem.type, 50)}
                        <span style={{
                            background: playlistItem.type === "video" ? "rgba(0, 96, 174, 0.6)" :
                                playlistItem.type === "image" ? "rgba(222, 134, 26, 0.6)" :
                                    "rgba(222, 26, 140, 0.3)"
                        }}>{icons(playlistItem.type, 15)}</span>
                    </>
                }
            </div>
            <span className={"playlistItemName"}>
                {index + 1}. {playlistItem.name}
            </span>
            <span className="deleteIcon" onClick={() => deletePlaylistItemHandler(playlistItem.id)}>
                <IconCloseX color={"black"} size={20} />
            </span>
        </div>
    );
};

export default PlaylistItem;
