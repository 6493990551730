import { useTranslation } from "i18nano";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BackgroundCreateContent, useNotification } from "../../components/UI";
import { useCreatePlaylistStore } from "../../stores";
import "./CreatePlaylistPage.scss";
import LeftControlPanel from "./LeftControlPanel";
import RightControlPanel from "./RightControlPanel";
import ViewContent from "./ViewContent";

const CreatePlaylistPage = () => {
    const { getPlaylist, error, clearStore } = useCreatePlaylistStore(state => state);
    const { addNotific } = useNotification();
    const t = useTranslation();

    const params = useParams();

    useEffect(() => {
        return () => {
            clearStore();
        };
    }, []);

    useEffect(() => {
        if (params.playlistId) {
            getPlaylist(params.playlistId);
        }
    }, [params]);

    useEffect(() => {
        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body, { ...error.data }),
                type: "danger"
            });
        }
    }, [error]);

    return (
        <div className={"createPlaylistContainer"}>
            <BackgroundCreateContent />
            <LeftControlPanel />
            <ViewContent />
            <RightControlPanel />
        </div>
    );
};

export default CreatePlaylistPage;