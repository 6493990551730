import React, { useEffect, useRef, useState } from "react";
import "./LeftControlPanel.scss";
import { useCreatePlaylistStore } from "../../../stores";
import PlaylistSettings from "./PlaylistSettings";
import {
    Button,
    IconEdit,
    Modal,
    ModalConfirm,
    useNotification
} from "../../../components/UI";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "i18nano";
import PlaylistItem from "./PlaylistItem";
import AddPlaylistItems from "./AddPlaylistItems";


const LeftControlPanel = () => {
    const {
        playlist,
        changeIndexPlaylistItem,
        createPlaylist,
    } = useCreatePlaylistStore(state => state);
    const { addNotific } = useNotification();
    const t = useTranslation();

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [saveOnChange, setSaveOnChange] = useState(false);

    const [modalContent, setModalContent] = useState<JSX.Element>(<></>);
    const [modalTitle, setModalTitle] = useState({
        title: "",
        subtitle: ""
    });

    const ref = useRef<HTMLDivElement>(null);

    const createPlaylistHandler = () => {
        if (playlist.name.length === 0) {
            setModalContent(<PlaylistSettings setShowModal={setShowModal} />);
            setModalTitle({ title: t("button.settings"), subtitle: "" });
            setSaveOnChange(true);
            setShowModal(true);
            return;
        }
        if (playlist.playlist_items.length === 0) {
            addNotific({
                title: t("notification.error.label"),
                body: t("notification.error.create_playlsit.left_control_panel.missing_items"),
                type: "danger"
            });
            return;
        }

        createPlaylist().then(val => setShowConfirm(val));
    };

    const showModalHandler = (modalContentType: string) => {
        if (modalContentType === "addContent") {
            setModalContent(<AddPlaylistItems setShowModal={setShowModal} />);
            setModalTitle({ title: t("create_playlist.add_playlist_item.title"), subtitle: t("create_playlist.add_playlist_item.subtitle") });
            setShowModal(true);
        } else if (modalContentType === "settingsPlaylist") {
            setModalContent(<PlaylistSettings setShowModal={setShowModal} />);
            setModalTitle({ title: t("button.settings"), subtitle: "" });
            setShowModal(true);
        }
    };

    const onCancel = () => {
        navigate("/playlists");
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    };

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (!ref.current) {
            return;
        }

        const data = parseInt(event.dataTransfer.getData("application/playlistitem"));
        const position = event.clientY - ref.current.getBoundingClientRect().top;
        let newIndex = Math.floor(position / 90);

        if (data === newIndex) {
            return;
        }

        if (newIndex > playlist.playlist_items.length) {
            newIndex = playlist.playlist_items.length;
        }

        changeIndexPlaylistItem(newIndex, data);
    };

    useEffect(() => {
        if (saveOnChange && playlist.name.length) {
            setSaveOnChange(false);
            createPlaylistHandler();
        }
    }, [playlist.name]);

    return (
        <div className={"controlPanel leftControlPanel"}>
            <div className={"controlPanelTop"}>
                <div className={"name-container"}>
                    <span className="name">{playlist.name.length ? playlist.name : t("create_playlist.input_playlist_name")}</span>
                    <span className="icon" onClick={() => showModalHandler("settingsPlaylist")}><IconEdit size={22} /></span>
                </div>
                <div className={"add-content-button-container"}>
                    <Button variant={"primary"} onClick={() => showModalHandler("addContent")} >{t("create_playlist.add_playlist_item.title")}</Button>
                </div>
                <div className={"playlistItemsContainer"} ref={ref} onDragOver={onDragOver} onDrop={onDrop}>
                    {playlist.playlist_items.map((playlistItem, index) =>
                        <PlaylistItem index={index} playlistItem={playlistItem} key={playlistItem.id} />
                    )}
                </div>
            </div>
            <div className={"controlPanelBottom"}>
                <Button onClick={() => createPlaylistHandler()} variant={"primary"}>{t("button.save")}</Button>
                <Button onClick={onCancel} variant={"secondary"}>{t("button.cancel")}</Button>
            </div>
            <Modal isShow={showModal} setIsShow={setShowModal} title={modalTitle.title} subtitle={modalTitle.subtitle}>
                {modalContent}
            </Modal>
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => setSaveOnChange(false)} onCancel={onCancel} okText={t("button.yes")} cancleText={t("button.no")}>
                <p>{t("confirm.create", { name: t("playlist.one") })}</p>
            </ModalConfirm>
        </div>
    );
};

export default LeftControlPanel;