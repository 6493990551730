import { useTranslation } from "i18nano";
import React, { useEffect, useState } from "react";
import { IconTriggerSelect, Modal } from "../../../../components/UI";
import { useCreatePlaylistStore } from "../../../../stores";
import { ITriggerDatetime } from "../../../../types/playlist";
import CvItem from "./CvItem";
import EyeContactItem from "./EyeContactItem";
import GestureItem from "./GestureItem";
import SelectTriggerType from "./SelectTriggerType";
import TimetableItem from "./TimetableItem";
import "./Triggers.scss";


interface IProps {
    currentId: string;
}

const Triggers: React.FC<IProps> = ({ currentId }) => {
    const { triggers } = useCreatePlaylistStore(state => state);
    const t = useTranslation();

    const [timetableList, setTimetableList] = useState<Record<string, ITriggerDatetime>>({});
    const [cvList, setCvList] = useState<Record<string, string>>({});
    const [gestureList, setGestureList] = useState<string[] | null>(null);
    const [eyeContact, setEyeContact] = useState<number | null>(null);

    const [triggersTypeModal, setTriggersTypeModal] = useState(false);

    useEffect(() => {
        if (triggers[currentId]) {
            setCvList({});
            setTimetableList({});
            setGestureList(null);
            setEyeContact(null);

            if (Object.keys(triggers[currentId].datetime).length) {
                setTimetableList(triggers[currentId].datetime);
            }
            if (Object.keys(triggers[currentId].cv).length) {
                setCvList(triggers[currentId].cv);
            }
            if (triggers[currentId].gesture) {
                setGestureList(triggers[currentId].gesture);
            }
            if (triggers[currentId].eye_contact !== null) {
                setEyeContact(triggers[currentId].eye_contact);
            }
        }
    }, [triggers, currentId]);

    return (
        <div className={"triggers-container"}>
            <div className={"add-triggers-container"}>
                <span className="title">{t("create_playlist.select_trigger_type.title")}</span>
                <div className={"add-trigger-but"} onClick={() => setTriggersTypeModal(true)}>
                    <IconTriggerSelect color="soft" />
                    <span>
                        {t("create_playlist.add_triggers")}
                    </span>
                </div>
            </div>
            <div className={"triggers-items"}>
                {eyeContact !== null &&
                    <details open>
                        <summary>{t("triggers.eye_contact.label")}</summary>
                        <div>
                            <EyeContactItem currentId={currentId} eyeContact={eyeContact} />
                        </div>
                    </details>
                }
                {/* {gestureList &&
                    <details open>
                        <summary>{t("triggers.gesture.label")}</summary>
                        <div>
                            <GestureItem currentId={currentId} gestureList={gestureList} />
                        </div>
                    </details>
                } */}
                {Object.keys(timetableList).length !== 0 &&
                    <details open>
                        <summary>{t("triggers.datetime.label")}</summary>
                        <div>
                            {Object.keys(timetableList).map(key =>
                                <TimetableItem timetableList={timetableList} itemKey={key} key={key} />
                            )}
                        </div>
                    </details>
                }
                {Object.keys(cvList).length !== 0 &&
                    <details open>
                        <summary>{t("triggers.cv.label")}</summary>
                        <div>
                            {Object.keys(cvList).map(key =>
                                <CvItem currentId={currentId} cvList={cvList} itemKey={key} key={key} />
                            )}
                        </div>
                    </details>
                }
            </div>

            <Modal isShow={triggersTypeModal} setIsShow={setTriggersTypeModal} title={t("create_playlist.select_trigger_type.title")} subtitle={t("create_playlist.select_trigger_type.subtitle")}>
                <SelectTriggerType setIsShow={setTriggersTypeModal} />
            </Modal>
        </div>
    );
};

export default Triggers;