import React from "react";

const colors = new Map([
    ["red", "#DD473B"],
    ["blue", "#1C7EDF"],
    ["white", "#FFFFFF"],
    ["default", "#ADADAD"],
    ["soft", "#1C7EDF"],
    ["black", "#000000"]
]);

type ColorTypes = "red" | "blue" | "white" | "soft" | "black" | "default"

interface IProps {
    color?: ColorTypes,
    size?: number
}

export const IconCv: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.6496 14.1394C47.71 13.6582 45.7391 13.1082 43.0579 14.9873L39.6662 17.3707C39.6891 17.6915 39.7121 17.9894 39.7121 18.3332V36.6665C39.7121 37.0103 39.6662 37.3082 39.6662 37.629L43.0579 40.0123C44.4787 41.0207 45.7162 41.3415 46.7016 41.3415C47.5496 41.3415 48.2141 41.1123 48.6496 40.8832C49.5891 40.4019 51.1704 39.0957 51.1704 35.8186V19.204C51.1704 15.9269 49.5891 14.6207 48.6496 14.1394Z" fill={colors.get(color)} />
            <path opacity="0.4" d="M29.5854 9.64911H15.7654C7.888 9.64911 4.82455 12.3624 4.82455 19.3396V35.6604C4.82455 40.3526 7.70373 45.3509 15.7654 45.3509H29.5854C37.4629 45.3509 40.5263 42.6375 40.5263 35.6604V19.3396C40.5263 12.3624 37.4629 9.64911 29.5854 9.64911Z" fill={colors.get(color)} />
            <path d="M26.3542 26.0792C28.7336 26.0792 30.6625 24.1503 30.6625 21.7709C30.6625 19.3914 28.7336 17.4625 26.3542 17.4625C23.9747 17.4625 22.0458 19.3914 22.0458 21.7709C22.0458 24.1503 23.9747 26.0792 26.3542 26.0792Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconGesture: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 45 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity={0.6} d="M17.0217 5.87014L14.2885 3.0793C13.7086 2.48716 12.9172 2.14964 12.0884 2.14099C11.2596 2.13235 10.4614 2.45328 9.86923 3.0332C9.27708 3.61311 8.93957 4.4045 8.93092 5.23327C8.92227 6.06204 9.24321 6.8603 9.82312 7.45244L14.2885 10.8375" fill={colors.get(color)} />
            <path opacity={0.6} d="M14.935 10.8377L8.72995 6.33551C8.15003 5.74337 7.35864 5.40585 6.52987 5.39721C5.7011 5.38856 4.90284 5.7095 4.3107 6.28941C3.71856 6.86933 3.38104 7.66072 3.3724 8.48948C3.36375 9.31825 3.68469 10.1165 4.2646 10.7087L11 16" fill={colors.get(color)} />
            <path opacity={0.6} d="M11 16L5.62703 11.779C5.04712 11.1868 3.98641 10.8954 3.15765 10.8868C2.32888 10.8781 1.53062 11.1991 0.938477 11.779C0.346335 12.3589 0.00881608 13.1503 0.000170274 13.9791C-0.00847553 14.8078 0.31246 15.6061 0.892375 16.1982L6.3588 21.7799" fill={colors.get(color)} />
            <path opacity={0.6} d="M16.4751 5.31162C15.8952 4.71948 15.5743 3.92122 15.5829 3.09245C15.5916 2.26368 15.9291 1.47229 16.5212 0.892374C17.1134 0.312459 17.9116 -0.00847561 18.7404 0.000170248C19.5692 0.0088161 20.3606 0.346334 20.9405 0.938476L24.2203 4.28749C26.54 6.65605 27.8237 9.84909 27.7891 13.1642C27.7546 16.4792 26.4045 19.6448 24.0359 21.9645L19.5706 26.3376C17.202 28.6573 14.009 29.941 10.6939 29.9064C7.37882 29.8718 4.2136 28.5219 1.89394 26.1533C1.31402 25.5612 0.99309 24.7629 1.00174 23.9342C1.01038 23.1054 1.3479 22.314 1.94004 21.7341C2.53218 21.1542 3.33044 20.8332 4.15921 20.8419C4.98798 20.8505 5.77889 21.1878 6.3588 21.7799" fill={colors.get(color)} />
            <path d="M36.0678 30.9998L42.2483 27.114C42.9155 26.6223 43.36 25.8856 43.484 25.0662C43.6081 24.2467 43.4015 23.4115 42.9098 22.7443C42.418 22.0771 41.6814 21.6326 40.8619 21.5086C37.8693 20.5847 32.8028 26.3999 32.1357 26.8917" fill={colors.get(color)} />
            <path d="M32.0001 26.9998L41.0558 20.2293C41.723 19.7375 42.1675 19.0009 42.2915 18.1814C42.4156 17.3619 42.209 16.5267 41.7173 15.8596C41.2255 15.1924 40.4889 14.7479 39.6694 14.6238C38.8499 14.4998 38.0147 14.7064 37.3476 15.1981L32.1357 19.438L27.5001 25.0662" fill={colors.get(color)} />
            <path d="M25.7517 24.6173L27.5001 25.0662C27.5001 25.0662 35.9437 15.8232 36.0678 15.0037C36.1918 14.1843 35.9853 13.3491 35.4935 12.6819C35.0018 12.0147 34.2651 11.5702 33.4457 11.4462C32.6262 11.3221 31.791 11.5287 31.1238 12.0204L19.6082 22.0828" fill={colors.get(color)} />
            <path d="M38.0001 29.4998C38.6672 29.0081 41.2353 28.27 42.0547 28.394C42.8742 28.5181 43.6109 28.9626 44.1026 29.6298C44.5943 30.2969 44.8009 31.1321 44.6769 31.9516C44.5528 32.7711 44.1083 33.5077 43.4411 33.9995L35.8944 39.5618C33.2257 41.5288 29.8849 42.3551 26.607 41.8589C23.3291 41.3627 20.3825 39.5847 18.4156 36.916L16.5615 34.4004C13.9657 30.8786 13.4714 27.9431 13.9516 24.6969L15.1422 16.8314C15.3025 16.0454 15.759 15.3512 16.4171 14.8924C17.0752 14.4336 17.8845 14.2455 18.6774 14.367C19.4704 14.4884 20.1863 14.9102 20.6768 15.5449C21.1674 16.1796 21.3951 16.9787 21.3128 17.7767L20.7307 21.622" fill={colors.get(color)} />
        </svg>
    );
};

export const IconDateTime: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M27.4999 48.7501C39.236 48.7501 48.7499 39.2362 48.7499 27.5001C48.7499 15.7641 39.236 6.25012 27.4999 6.25012C15.7639 6.25012 6.24994 15.7641 6.24994 27.5001C6.24994 39.2362 15.7639 48.7501 27.4999 48.7501Z" fill={colors.get(color)} />
            <path d="M35.3838 35.8513C35.1075 35.8513 34.8313 35.7875 34.5763 35.6175L27.9888 31.6863C26.3525 30.7088 25.1413 28.5625 25.1413 26.6713V17.9588C25.1413 17.0876 25.8638 16.3651 26.735 16.3651C27.6063 16.3651 28.3288 17.0876 28.3288 17.9588V26.6713C28.3288 27.4363 28.9663 28.5626 29.625 28.9451L36.2125 32.8763C36.9775 33.3225 37.2113 34.3001 36.765 35.0651C36.4463 35.5751 35.915 35.8513 35.3838 35.8513Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconEyeContact: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M48.6979 20.9687C43.4041 12.65 35.6583 7.86041 27.5 7.86041C23.4208 7.86041 19.4562 9.05208 15.8354 11.275C12.2146 13.5208 8.9604 16.7979 6.30206 20.9687C4.0104 24.5667 4.0104 30.4104 6.30206 34.0083C11.5958 42.35 19.3416 47.1167 27.5 47.1167C31.5791 47.1167 35.5437 45.925 39.1646 43.7021C42.7854 41.4562 46.0396 38.1792 48.6979 34.0083C50.9896 30.4333 50.9896 24.5667 48.6979 20.9687ZM27.5 36.7583C22.3666 36.7583 18.2416 32.6104 18.2416 27.5C18.2416 22.3896 22.3666 18.2417 27.5 18.2417C32.6333 18.2417 36.7583 22.3896 36.7583 27.5C36.7583 32.6104 32.6333 36.7583 27.5 36.7583Z" fill={colors.get(color)} />
            <path d="M27.5 20.9459C23.9021 20.9459 20.9688 23.8792 20.9688 27.5C20.9688 31.0979 23.9021 34.0313 27.5 34.0313C31.0979 34.0313 34.0542 31.0979 34.0542 27.5C34.0542 23.9021 31.0979 20.9459 27.5 20.9459Z" fill={colors.get(color)} />
        </svg>
    );
};

export const IconHands: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={colors.get(color)} d="M330.8 242.3L223.1 209.1C210.3 205.2 197 212.3 193.1 224.9C189.2 237.6 196.3 251 208.9 254.9L256 272H56.9c-11.61 0-22.25 7.844-24.44 19.24C29.51 306.6 41.19 320 56 320h128C188.4 320 192 323.6 192 328S188.4 336 184 336H24.9c-11.61 0-22.25 7.844-24.44 19.24C-2.49 370.6 9.193 384 24 384h160C188.4 384 192 387.6 192 392S188.4 400 184 400H56.9c-11.61 0-22.25 7.844-24.44 19.24C29.51 434.6 41.19 448 56 448h128C188.4 448 192 451.6 192 456S188.4 464 184 464H88.9c-11.61 0-22.25 7.844-24.44 19.24C61.51 498.6 73.19 512 88 512h208c66.28 0 120-53.73 120-120v-32.03C416 306.6 381.1 259.4 330.8 242.3zM197.1 179.5c5.986-2.148 12.32-3.482 18.98-3.482c5.508 0 10.99 .8105 16.5 2.471l16.11 4.975L227.7 117.2C224.2 106.2 213.6 98.39 202 99.74c-15.51 1.807-24.79 16.99-20.33 31.11L197.1 179.5zM487.1 144.5c-13.27 .0977-23.95 10.91-23.86 24.16l-2.082 50.04l-59.98-189.8c-3.496-11.07-14.18-18.86-25.71-17.51c-15.51 1.807-24.79 16.99-20.33 31.11l38.56 122.1c1.332 4.213-1.004 8.707-5.219 10.04c-4.213 1.332-8.707-1.004-10.04-5.217l-47.93-151.7c-3.496-11.07-14.18-18.86-25.71-17.51c-15.51 1.807-24.79 16.99-20.33 31.11l43.37 137.8c1.33 4.213-1.006 8.707-5.219 10.04c-4.213 1.332-8.707-1.004-10.04-5.217l-33.46-106.4C275.6 56.39 264.9 48.6 253.4 49.94c-15.51 1.807-24.79 16.99-20.33 31.11l34.15 108.1l73.7 22.76C404.1 233.3 448 292.8 448 359.9v27.91c38.27-21.17 63.28-61.24 64-106.7V168.4C511.8 155.1 500.3 144.5 487.1 144.5z" />
        </svg>
    );
};

export const IconTriggerSelect: React.FC<IProps> = ({ color = "default", size = 19 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.50001 1.33331C4.82001 1.33331 1.83334 4.31998 1.83334 7.99998C1.83334 11.68 4.82001 14.6666 8.50001 14.6666C12.18 14.6666 15.1667 11.68 15.1667 7.99998C15.1667 4.31998 12.18 1.33331 8.50001 1.33331ZM10.8533 8.81998L7.40001 11.2133C6.89334 11.5666 6.60001 11.36 6.74668 10.7666L7.38001 8.20665L6.28001 7.93331C5.78001 7.81331 5.72001 7.46665 6.14001 7.17331L9.59334 4.77998C10.1 4.42665 10.3933 4.63331 10.2467 5.22665L9.61334 7.78665L10.7133 8.05998C11.2133 8.18665 11.2733 8.52665 10.8533 8.81998Z" fill={colors.get(color)} />
        </svg>
    );
};
