import React from "react";
import { Tooltip, IconHelp } from "../../../../components/UI";
import styles from "./styles.module.scss";
import { useTranslation } from "i18nano";


export interface IProps {
    headerLabel: string;
    total: number | string | undefined;
    footerLabel: string;
    growth: number | undefined | null;
    icon: JSX.Element
    tooltipText: string;
}


const AnalyticCard: React.FC<IProps> = ({ headerLabel, total, footerLabel, growth, icon, tooltipText }) => {
    const t = useTranslation();

    const toDivide = (val: number) => {
        const [whole, decimal] = val.toString().split(".");

        if (whole.length < 4) {
            return val;
        }

        let res = "";
        let space = 0;

        for (let i = whole.length - 1; i >= 0; i -= 1) {
            if (space === 3) {
                res = " " + res;
                space = 0;
            }
            res = whole.charAt(i) + res;
            space += 1;
        }

        if (decimal) {
            res += "." + decimal;
        }

        return res;
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.name}>
                    {icon}
                    <span>{headerLabel}</span>
                </div>
                <div className={styles.question}>
                    {tooltipText ? <Tooltip text={tooltipText}><IconHelp /></Tooltip>: ''}
                </div>
            </div>
            <div className={styles.body}>
                {total ?? t("analytic.no_data_found")}
            </div>
            <div className={styles.footer}>
                <div>{footerLabel}</div>
                { growth ? <div dir="ltr" className={`${growth > 0 ? styles.increase : styles.decrease}`}>{toDivide(growth)}%</div> : ''}
            </div>
            
        </div>
    );
};

export default AnalyticCard;