import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { Button, IconCloseX, Input, Modal } from "../../../../../components/UI";
import { useCreatePlaylistStore } from "../../../../../stores";
import "./EyeContactItem.scss";


interface IProps {
    eyeContact: number | null;
    currentId: string;
}

interface IModal {
    defaultVal: number;
    closeHandler: () => void;
    confirmHandler: (val: number) => void;
}

const ModalContent: React.FC<IModal> = ({ defaultVal, closeHandler, confirmHandler }) => {
    const t = useTranslation();

    const [eyeContact, setEyeContact] = useState<string>(defaultVal ? defaultVal + "" : "150");

    return (
        <div className={"eye-input-modal-container"}>
            <span className="title">{t("triggers.eye_contact.duration")}:</span>
            <div className="eye-input-container">
                <Input
                    type={"number"}
                    onChange={setEyeContact}
                    value={eyeContact}
                    min={0}
                />
                <span className={"millisec"}>{t("measurement.milliseconds")}</span>
            </div>
            <div className={"buttons-container"}>
                <Button onClick={closeHandler} variant={"secondary"}>{t("button.cancel")}</Button>
                <Button onClick={() => confirmHandler(+eyeContact)} variant={"primary"}>{t("button.apply")}</Button>
            </div>
        </div >
    );
};

const EyeContactItem: React.FC<IProps> = ({ eyeContact, currentId }) => {
    const { setTriggers, triggers } = useCreatePlaylistStore(state => state);
    const t = useTranslation();

    const [show, setShow] = useState(eyeContact === 0);

    const confirmHandler = (eyeContact: number) => {
        if (eyeContact) {
            setTriggers({
                ...triggers[currentId],
                eye_contact: eyeContact
            });
        } else {
            deleteHandler();
        }
        setShow(false);
    };

    const deleteHandler = () => {
        setTriggers({
            ...triggers[currentId],
            eye_contact: null
        });
        setShow(false);
    };

    const closeHandler = () => {
        if (!triggers[currentId].eye_contact) {
            setTriggers({
                ...triggers[currentId],
                eye_contact: null
            });
        }
        setShow(false);
    };

    return (
        <div className={"trigger-item eye-item-container"}>
            <div className="short-name-container">
                <span className={"name"} onClick={() => setShow(true)}>{eyeContact} {t("measurement.milliseconds")}</span>
                <span className={"delete-icon"} onClick={deleteHandler}>
                    <IconCloseX color={"black"} />
                </span>
            </div>

            <Modal
                isShow={show}
                setIsShow={setShow}
                title={t("create_playlist.select_triggers_eye.title")}
                disableOverlay
            >
                <ModalContent closeHandler={closeHandler} confirmHandler={confirmHandler} defaultVal={eyeContact ? eyeContact : 0} />
            </Modal>
        </div>
    );
};

export default EyeContactItem;