import classnames from "classnames";
import React from "react";
import { Handle, NodeProps, Position } from "reactflow";
import styles from "./styles.module.scss";


const VideoNode: React.FC<NodeProps> = ({ data, selected }) => {
    return (
        <div className={classnames(styles.node_container, styles.video_node_container, {[styles.active]: selected})}>
            <Handle type="target" position={Position.Top} className={styles.handle} />
            <div>
                <label htmlFor="text">{data.label}</label>
            </div>
            <Handle type="source" position={Position.Bottom} className={styles.handle} />
        </div>
    );
};

export default VideoNode;