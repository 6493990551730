import {
    Node,
    Edge,
    MarkerType,
} from "reactflow";
import { ICreateScenarioItem, onConnectData } from "../../types/createScenario";
import { IError } from "../../types/default";


export const defaultEdgeSettings = {
    animated: true,
    markerEnd: {
        type: MarkerType.ArrowClosed,
    },
    type: "custom",
    interactionWidth: 20
};

export const initialNodes: Node[] = [
    {
        id: "start",
        data: {
            label: "Start"
        },
        position: { x: 100, y: -50 },
        type: "start",
        deletable: false
    },
    {
        id: "end",
        data: {
            label: "End"
        },
        position: { x: 100, y: 350 },
        type: "end",
        deletable: false
    }
];

export const initialEdges: Edge[] = [];

export const defaultState = {
    id: "",
    name: "",
    description: "",
    in_playlist: false,
    preview: "",
    duration: 0,
    scenario_network_nodes: {},
    scenario_network_edges: {},
    scenario_items: {}
};

export const defaultStateScenarioItems: Record<string, ICreateScenarioItem> = {
    "start": {
        id: "start",
        content_id: "",
        content: "start",
        path: "",
        conditions: {},
        condition_types: [],
        nodes: {},
        next: "",
        is_start: false,
        is_end: false,
        content_type: "image",
        duration: 0,
        blackout_duration: 0
    },
    "end": {
        id: "end",
        content_id: "",
        content: "end",
        path: "",
        conditions: {},
        condition_types: [],
        nodes: {},
        next: "",
        is_start: false,
        is_end: false,
        content_type: "image",
        duration: 0,
        blackout_duration: 0
    }
};

export const checkStart = (scenarioItems: Record<string, ICreateScenarioItem>) => {
    for (const key of Object.keys(scenarioItems)) {
        if (scenarioItems[key].is_start) {
            return true;
        }
    }

    return false;
};

export const checkEnd = (scenarioItems: Record<string, ICreateScenarioItem>) => {
    for (const key of Object.keys(scenarioItems)) {
        if (scenarioItems[key].is_end) {
            return true;
        }
    }
    return false;
};

const transitions: Record<string, string[]> = {};
const transitionsComplete: Record<string, number> = {};

const deepCrawl = (from: string, to: string[]) => {
    transitionsComplete[from] = 1;

    for (const node of to) {
        if (transitionsComplete[node] === 0) {
            if (deepCrawl(node, transitions[node])) {
                return true;
            }
        } else if (transitionsComplete[node] === 1) {
            return true;
        }
    }

    transitionsComplete[from] = 2;
    return false;
};

export const addTransitions = (from: string, to: string) => {
    if (!transitions[from]) {
        transitions[from] = [to];
    } else if (!transitions[from].includes(to)) {
        transitions[from].push(to);
    }

    if (!transitions[to]) {
        transitions[to] = [];
    }

    transitionsComplete[from] = 0;
    transitionsComplete[to] = 0;
};

export const deleteTransition = (from: string, to: string) => {
    if (transitions[from].includes(to)) {
        transitions[from] = transitions[from].filter(item => item !== to);
    }
};

const checkCycle = (from: string, to: string): boolean => {
    let isCycle = false;

    addTransitions(from, to);

    for (const key of Object.keys(transitionsComplete)) {
        transitionsComplete[key] = 0;
    }

    for (const key of Object.keys(transitions)) {
        if (deepCrawl(key, transitions[key])) {
            isCycle = true;
            transitions[from].pop();
            break;
        }
    }
    return isCycle;
};

interface IAddConnectReq {
    error?: IError;
    connectData: Record<string, ICreateScenarioItem>
}

export const addConnect = (data: onConnectData): IAddConnectReq => {
    const from = data.from.id;
    const to = data.to.id;

    if (!from || !to) {
        return {
            connectData: {},
            error: {
                title: "notification.error.create_scenario.edges_error.label",
                body: "notification.error.create_scenario.edges_error.label"
            }
        };
    }

    if (checkCycle(from, to)) {
        return {
            connectData: {},
            error: {
                title: "notification.error.create_scenario.edges_error.label",
                body: "notification.error.create_scenario.edges_error.cycle"
            }
        };
    }

    data.to.conditions[`${from}-${to}`] = [];

    if (from === "start") {
        data.to.is_start = true;
    }
    if (to === "end") {
        data.from.is_end = true;
    }

    return {
        connectData: {
            [from]: data.from,
            [to]: data.to
        }
    };
};