import classnames from "classnames";
import React, { ChangeEvent, useState } from "react";
import { IconEye, IconEyeCrossed } from "../Icons";

import styles from "./styles.module.scss";

type TInput = "text" | "number" | "date" | "time" | "password" | "datetime-local"

interface IProps {
    type: TInput;
    value?: string | number;
    onChange?: (value: string) => void;
    iconStart?: JSX.Element;
    iconEnd?: JSX.Element;
    placeholder?: string;
    id?: string;
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    min?: string | number;
    max?: string | number;
    autocomplete?: "on" | "off";
}

const Input: React.FC<IProps> = (
    { iconStart, iconEnd, placeholder, type, id, value, onChange, required, readonly, disabled, min, max, autocomplete = "off" }
) => {
    const [showPassword, setShowPassword] = useState(type !== "password");

    return (
        <div className={classnames(
            styles.input_container,
            {
                [styles.icon_start]: iconStart,
                [styles.icon_end]: iconEnd || type === "password",
                [styles.readonly]: readonly
            }
        )}>
            {disabled && <div className={styles.disabled}></div>}
            {iconStart
                ? <span className={styles.input_icon_start}>{iconStart}</span>
                : null
            }
            <input
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange ? onChange(e.target.value) : null}
                placeholder={placeholder}
                type={showPassword && type === "password" ? "text" : type}
                id={id}
                required={required}
                readOnly={readonly}
                min={min}
                max={max}
                autoComplete={autocomplete}
            />
            {iconEnd
                ? <span className={styles.input_icon_end} onClick={() => {
                    if (type === "password") {
                        setShowPassword(!showPassword);
                    }
                }}>{iconEnd}</span>
                : type === "password"
                    ? <span className={styles.input_icon_end} onClick={() => {
                        if (type === "password") {
                            setShowPassword(!showPassword);
                        }
                    }}>{showPassword
                        ? <IconEye color="blue" size={20} /> : <IconEyeCrossed size={20} />
                        }
                    </span>
                    : null
            }
        </div>
    );
};

export default Input;