import React, { useEffect, useState } from "react";
import "./ScenarioPage.scss";
import ScenarioItem from "./ScenarioItem";
import { useScenarioStore } from "../../stores";
import { Button, Loader, ModalConfirm, Pagination, useNotification } from "../../components/UI";
import { IFilters } from "../../types/default";
import ControlPanel from "../../components/ControlPanel";
import { Link } from "react-router-dom";
import { useTranslation } from "i18nano";
import SortPanelContainer from "../../components/SortPanelContainer";
import SortPanelItem from "../../components/SortPanelContainer/SortPanelItem";
import PageContainer from "../../components/PageContainer";


const ScenarioPage = () => {
    const { scenarioList, loading, error, fetchScenario, selectScenario, deleteScenario, clearStore } = useScenarioStore(state => state);
    const { addNotific } = useNotification();
    const t = useTranslation();

    const [checkedAll, setCheckedAll] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const [filters, setFilters] = useState<IFilters>({
        page: 1,
        perPage: 10,
        filters: { name: "" },
        orderBy: "name",
        orderDesk: false
    });

    useEffect(() => {
        return () => clearStore();
    }, []);

    useEffect(() => {
        if (error) {
            addNotific({
                title: t(error.title),
                body: t(error.body, { name: t("content.genitive") }),
                type: "danger"
            });
        }
    }, [error]);

    useEffect(() => {
        fetchScenario(filters);
        setCheckedAll(false);
    }, [filters]);

    const selectAll = (checkedState: boolean) => {
        selectScenario({ selectState: checkedState, selectedData: "all" });
        setCheckedAll(checkedState);
    };

    const onDelete = () => {
        const deleteList: Array<string> = [];
        for (const file of scenarioList.data) {
            if (file.isChecked) {
                deleteList.push(file.id);
            }
        }
        deleteScenario(deleteList);
        selectScenario({ selectState: false, selectedData: "all" });
        setCheckedAll(false);
    };

    const filtersContent = (order_by: string) => {
        if (order_by === filters.orderBy) {
            setFilters(prev => ({ ...prev, orderDesk: !filters.orderDesk }));
        }
        setFilters(prev => ({ ...prev, orderBy: order_by }));
    };

    return (
        <PageContainer>
            <ControlPanel
                onSelectAll={selectAll}
                onDelete={() => setShowConfirm(true)}
                onSearch={(val) => setFilters(prev => ({ ...prev, filters: { name: val } }))}
                righrPanel={
                    <Link to={"/scenarios/create"}>
                        <Button variant={"primary"}>
                            {`${t("button.create")} ${t("scenario.one")}`}
                        </Button>
                    </Link>
                }
                selectedLenght={scenarioList.data.filter(item => item.isChecked).length}
            />
            <SortPanelContainer>
                <SortPanelItem
                    type={"checkbox"}
                    onChange={(e) => selectAll(e.target.checked)}
                    checked={checkedAll}
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.name")}
                    onClick={() => filtersContent("name")}
                    filterable
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.description")}
                    onClick={() => filtersContent("description")}
                    filterable
                />
                <SortPanelItem
                    type={"text"}
                    text={t("table_head.actions")}
                />
            </SortPanelContainer>
            {loading
                ? <h1 style={{
                    "textAlign": "center",
                    "fontSize": "50px",
                    "marginTop": "100px"
                }}><Loader /></h1>
                : <>
                    {scenarioList.data.length
                        ? <>
                            <div className={"content-table"}>
                                {scenarioList.data.map((scenario, index) =>
                                    <ScenarioItem
                                        scenario={scenario}
                                        key={scenario.id}
                                    />
                                )}
                            </div>
                            <Pagination
                                perPage={filters.perPage}
                                setPerPage={(val) => setFilters(prev => ({ ...prev, perPage: val }))}
                                page={filters.page}
                                setPage={(val) => setFilters(prev => ({ ...prev, page: val }))}
                                count={scenarioList.count}
                            />
                        </>
                        : <h1 style={{
                            "textAlign": "center",
                            "fontSize": "50px",
                            "marginTop": "100px"
                        }}>{t("missing.scenario")}</h1>
                    }
                </>
            }
            <ModalConfirm isShow={showConfirm} setIsShow={setShowConfirm} onConfirm={() => onDelete()} onCancel={() => { }}>
                <p>{t("confirm.delete", { name: `elements (${scenarioList.data.filter(item => item.isChecked).length})` })}</p>
            </ModalConfirm>
        </PageContainer>
    );
};

export default ScenarioPage;