import React from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./styles.module.scss";
import animation from "./animation.module.scss";
import useExpandableWrapper from "./useExpandableWrapper";


interface IPropsWidth {
    isExpanded: boolean;
    children: React.ReactNode;
    minWidth?: number
}

const ExpandableWrapper: React.FC<IPropsWidth> = ({ children, isExpanded, minWidth = 0 }) => {
    const { onEnter, onEntered, onExit, onExiting, childWidth } = useExpandableWrapper(minWidth, isExpanded);

    return (
        <div style={{ width: childWidth }} className={styles.wrapper}>
            <CSSTransition
                in={isExpanded}
                timeout={300}
                classNames={animation}
                onEnter={onEnter}
                onEntered={onEntered}
                onExit={onExit}
                onExiting={onExiting}
            >
                {children}
            </CSSTransition>
        </div>
    );
};

export default ExpandableWrapper;