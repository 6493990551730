import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { IContentStore } from "../../types/contentList";
import { defaultState, defaultFilters } from "../consts";
import {
    getAllContentApi,
    createContentApi,
    deleteContentApi
} from "../../api/content-api";


export const useContentStore = create<IContentStore>()(
    immer(
        (set, get) => ({
            contentList: defaultState,
            loading: false,
            error: undefined,
            clearStore: () => {
                set(state => {
                    state.contentList = defaultState;
                    state.loading = false;
                    state.error = undefined;
                });
            },
            fetchContent: async (filters) => {
                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await getAllContentApi(filters).then(data => {
                    set((state) => {
                        state.contentList = { ...state.contentList, ...data };
                    });
                }).catch((e: Error) => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            createContent: async (content) => {
                const { fetchContent } = get();

                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await createContentApi(content).then(() => {
                    fetchContent(defaultFilters);
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.create"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            deleteContent: async (content_ids) => {
                const { fetchContent } = get();

                set((state) => {
                    state.loading = true;
                    state.error = undefined;
                });

                await deleteContentApi(content_ids).then(() => {
                    fetchContent(defaultFilters);
                }).catch(() => {
                    set((state) => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.delete"
                        };
                    });
                }).finally(() => {
                    set(state => {
                        state.loading = false;
                    });
                });
            },
            selectContent: (selectStatus) => {
                set((state) => {
                    state.contentList.data = [...state.contentList.data.map(content => {
                        if (selectStatus.selectedData === "all" || selectStatus.selectedData === content.id) {
                            return { ...content, isChecked: selectStatus.selectState };
                        }
                        return content;
                    })];
                });
            }
        })
    )
);