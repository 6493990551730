import React, { useEffect, useState } from "react";
import AnalyticSwitcher from "./AnalyticSwitcher";
import Demography from "./Demography/Demography";
import GeneralAnalytics from "./GeneralAnalytics";
import styles from "./styles.module.scss";

export type TAnalyticSwitch = "general" | "demography"

const AnalyticsPage = () => {
    const [analyticPage, setAnalitycPage] = useState<TAnalyticSwitch>("general");
    const [currentAnalyticView, setCurrentAnalyticView] = useState<React.ReactNode>();

    useEffect(() => {
        switch (analyticPage) {
            case "general":
                setCurrentAnalyticView(<GeneralAnalytics />);
                break;
            case "demography":
                setCurrentAnalyticView(<Demography />);
                break;
        }
    }, [analyticPage]);

    return (
        <div className={styles.root}>
            <AnalyticSwitcher analyticPage={analyticPage} setAnalitycPage={setAnalitycPage} />
            {currentAnalyticView}
        </div>
    );
};

export default AnalyticsPage;