import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar";
import ContentPage from "./pages/ContentPage";
import ScenarioPage from "./pages/ScenarioPage";
import CreateScenarioPage from "./pages/CreateScenarioPage";
import PlaylistPage from "./pages/PlaylistPage";
import CreatePlaylistPage from "./pages/CreatePlaylistPage";
import StatisticPage from "./pages/AnalyticsPage";
import TerminlsPage from "./pages/TerminlsPage";
import AuthPage from "./pages/AuthPage";

import { NotificationContainer } from "./components/UI";
import { TranslationProvider } from "i18nano";
import { DEFAULT_LANGUAGE, rtlLangs, translation } from "./assets/locale";
import TerminalSetupPage from "./pages/TerminalSetupPage";


function App() {
    const user = localStorage.getItem("user");

    const lang = window.localStorage.getItem("lang");

    return (
        <BrowserRouter>
            <TranslationProvider language={lang ? lang : DEFAULT_LANGUAGE} translations={translation} fallback={DEFAULT_LANGUAGE} transition >
                <Suspense>
                    <div dir={rtlLangs.includes(lang!) ? "rtl" : "ltr"}>
                        <Navbar />
                        {user
                            ? <>
                                <Routes>
                                    <Route path="/content" element={<ContentPage />} />

                                    <Route path="/scenarios">
                                        <Route path="" element={<ScenarioPage />} />
                                        <Route path="create" element={<CreateScenarioPage />} />
                                        <Route path="edit/:scenarioId" element={<CreateScenarioPage />} />
                                    </Route>

                                    <Route path="/playlists">
                                        <Route path="" element={<PlaylistPage />} />
                                        <Route path="create" element={<CreatePlaylistPage />} />
                                        <Route path="edit/:playlistId" element={<CreatePlaylistPage />} />
                                    </Route>

                                    <Route path="/terminals">
                                        <Route path="" element={<TerminlsPage />} />
                                        <Route path="setup/:terminalId" element={<TerminalSetupPage />} />
                                    </Route>

                                    <Route path="/analytics" element={<StatisticPage />} />

                                    <Route
                                        path="*"
                                        element={<Navigate to="/content" replace />}
                                    />
                                </Routes>
                            </>
                            : <>

                                <Routes>
                                    <Route path="/auth" element={<AuthPage />} />

                                    <Route
                                        path="*"
                                        element={<Navigate to="/auth" replace />}
                                    />
                                </Routes>
                            </>
                        }
                        <NotificationContainer />
                    </div>
                </Suspense>
            </TranslationProvider>
        </BrowserRouter>
    );
}

export default App;
