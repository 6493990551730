import classnames from "classnames";
import React from "react";
import { IconDemo, IconDisplay, IconNetwork } from "../../../components/UI";
import { TSetup } from "../TerminalSetupPage";
import styles from "./styles.module.scss";

interface IPoprs {
    setupPageType: TSetup;
    setSetupPageType: React.Dispatch<React.SetStateAction<TSetup>>;
}

const SetupList: React.FC<IPoprs> = ({ setupPageType, setSetupPageType }) => {
    return (
        <div className={styles.root}>
            <ul className={styles.setup_list}>
                <li className={classnames(styles.setup_item, { [styles.active]: setupPageType === "network" })} onClick={() => setSetupPageType("network")}>
                    <IconNetwork size={24} color={setupPageType === "network" ? "blue" : "black"} />
                    <span>Network setup</span>
                </li>
                <li className={classnames(styles.setup_item, { [styles.active]: setupPageType === "display" })} onClick={() => setSetupPageType("display")}>
                    <IconDisplay size={24} color={setupPageType === "display" ? "blue" : "black"} />
                    <span>Display setup</span>
                </li>
                <li className={classnames(styles.setup_item, { [styles.active]: setupPageType === "demo" })} onClick={() => setSetupPageType("demo")}>
                    <IconDemo size={24} color={setupPageType === "demo" ? "blue" : "black"} />
                    <span>Demonstration</span>
                </li>
            </ul>
        </div>
    );
};

export default SetupList;