import axios, { AxiosError } from "axios";
import { IError } from "../types/default";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || "http://localhost:8000";
axios.defaults.withCredentials = true;

axios.interceptors.response.use(resp => resp.data, (error: AxiosError) => {
    if (error.config.url === "/sign-in/") {
        throw error;
    }
    
    switch (error.response?.status) {
        case 401: {
            const error: IError = {
                title: "notification.error.label",
                body: "notification.error.authentication.expired_token"
            };

            localStorage.removeItem("user");
            localStorage.setItem("sessionError", JSON.stringify(error));

            window.location.reload();

            return undefined;
        }
        default: {
            throw error;
        }
    }
});

export default axios;