import React from "react";
import { IconAttention, IconAttentionRatio, IconConversionRatio, IconDwell, IconOts, IconWatchers } from "../../../components/UI";
import { useAnalyticStore } from "../../../stores";
import AnalyticCard, { IProps } from "./AnalyticCard/AnalyticCard";
import Datepicker from "../Demography/Datepicker/Datepicker";
import GenderAgeBar, { IBarChartProps } from "../Demography/GenderAgeBar/GenderAgeBar";
import AbsoluteRelativeChart from "../Demography/AbsoluteRelativeChart";
import { IChartProps } from "../Demography/AbsoluteRelativeChart/AbsoluteRelativeChart";
import { useTranslation } from "i18nano";


const GeneralAnalytics= () => {
    const t = useTranslation();

    const { fetchGeneralAnalytic, generalAnalytic } = useAnalyticStore(state => state);

    // TODO Fix use of icons
    const icons: Record<string, JSX.Element> = {
        ots: <IconOts />,
        watchers: <IconWatchers />,
        conversion_ratio: <IconConversionRatio />,
        dwell: <IconDwell />,
        attention: <IconAttention />,
        attention_ratio: <IconAttentionRatio />
    };

    /**
     * Props for cards
     */
    const OTS: IProps = {
        headerLabel: t("analytic.general.ots.label"),
        total: generalAnalytic?.general_stats?.ots,
        footerLabel: t("analytic.general.ots.unit"),
        growth: null,
        icon: icons.ots,
        tooltipText: t("analytic.general.ots.help")
    };
    const watchers: IProps = {
        headerLabel: t("analytic.general.watchers.label"),
        total: generalAnalytic?.general_stats?.watchers,
        footerLabel: t("analytic.general.watchers.unit"),
        growth: null,
        icon: icons.watchers,
        tooltipText: t("analytic.general.watchers.help")
    };
    const conversionRatio: IProps = {
        headerLabel: t("analytic.general.conversion_ratio.label"),
        total: generalAnalytic?.general_stats?.conversion_ratio,
        footerLabel: t("analytic.general.conversion_ratio.unit"),
        growth: null,
        icon: icons.conversion_ratio,
        tooltipText: t("analytic.general.conversion_ratio.help")
    };
    const dwellTime: IProps = {
        headerLabel: t("analytic.general.dwell.label"),
        total: generalAnalytic?.general_stats?.dwell_time,
        footerLabel: t("analytic.general.dwell.unit"),
        growth: null,
        icon: icons.dwell,
        tooltipText: t("analytic.general.dwell.help")
    };
    const attentionTime: IProps = {
        headerLabel: t("analytic.general.attention.label"),
        total: generalAnalytic?.general_stats?.attention_time,
        footerLabel: t("analytic.general.attention.unit"),
        growth: null,
        icon: icons.attention,
        tooltipText: t("analytic.general.attention.help")
    };
    const attractionRatio: IProps = {
        headerLabel: t("analytic.general.attraction_ratio.label"),
        total: generalAnalytic?.general_stats?.attraction_ratio,
        footerLabel: t("analytic.general.attraction_ratio.unit"),
        growth: null,
        icon: icons.attention_ratio,
        tooltipText: t("analytic.general.attraction_ratio.help")
    };


     /**
     * Props for split charts
     */
    const otsWatchersSplit: IChartProps = {
        headerLabel: t("analytic.general.charts.ots_watchers_total.title"),
        labels: generalAnalytic?.general_stats?.ots_watchers_split?.labels ?? [],
        data: generalAnalytic?.general_stats?.ots_watchers_split?.data,
        withPieOption: false
    };
    const dwellAttentionSplit: IChartProps = {
        headerLabel: t("analytic.general.charts.dwell_attention_total.title"),
        labels: generalAnalytic?.general_stats?.dwell_attention_split?.labels ?? [],
        data: generalAnalytic?.general_stats?.dwell_attention_split?.data,
        withPieOption: false
    };


     /**
     * Props for Average Bar Charts
     */
    const otsWatchersAcrossAvgWeek: IBarChartProps = {
        labels: generalAnalytic?.general_stats?.ots_watchers_across_avg_week?.labels,
        headerLabel: t("analytic.general.charts.ots_watchers_week.title"),
        datasets: generalAnalytic?.general_stats?.ots_watchers_across_avg_week?.dataset,
        stackedBar: false
    };
     const otsWatchersAcrossAvgDay: IBarChartProps = {
        labels: generalAnalytic?.general_stats?.ots_watchers_across_avg_day?.labels,
        headerLabel: t("analytic.general.charts.ots_watchers_day.title"),
        datasets: generalAnalytic?.general_stats?.ots_watchers_across_avg_day?.dataset,
        stackedBar: false
    };
    const dwellAttentionAcrossAvgWeek: IBarChartProps = {
        labels: generalAnalytic?.general_stats?.dwell_attention_across_avg_week?.labels,
        headerLabel: t("analytic.general.charts.dwell_attention_week.title"),
        datasets: generalAnalytic?.general_stats?.dwell_attention_across_avg_week?.dataset,
        stackedBar: false
    };
     const dwellAttentionAcrossAvgDay: IBarChartProps = {
        labels: generalAnalytic?.general_stats?.dwell_attention_across_avg_day?.labels,
        headerLabel: t("analytic.general.charts.dwell_attention_day.title"),
        datasets: generalAnalytic?.general_stats?.dwell_attention_across_avg_day?.dataset,
        stackedBar: false
    };


    return (
        <div style={{ display: "grid", gridTemplateRows: "auto", gap: 20, marginBottom: "20px" }}>
            {/* TODO!! Fix parameter"s name from "fetchDemography" to "fetchFunction", relocate folder "Datepicker" from folder "Demography" */}
            <Datepicker fetchStats={fetchGeneralAnalytic} statsType="general"/>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(6, 1fr)", gap: 20 }}>
                <AnalyticCard { ...OTS }/>
                <AnalyticCard { ...watchers }/>
                <AnalyticCard { ...conversionRatio }/>
                <AnalyticCard { ...dwellTime }/>
                <AnalyticCard { ...attentionTime }/>
                <AnalyticCard { ...attractionRatio }/>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 20 }}>
                {/* TODO!! relocate folder "AbsoluteRelativeChart" from folder "Demography" */}
                <AbsoluteRelativeChart { ...otsWatchersSplit }/>
                <AbsoluteRelativeChart { ...dwellAttentionSplit }/>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 20 }}>
                {/* TODO!! relocate folder "GenderAgeBar" from folder "Demography" */}
                <GenderAgeBar { ...otsWatchersAcrossAvgWeek }/>
                <GenderAgeBar { ...dwellAttentionAcrossAvgWeek }/>
                <GenderAgeBar { ...otsWatchersAcrossAvgDay }/>
                <GenderAgeBar { ...dwellAttentionAcrossAvgDay }/>
            </div>
        </div>
    );
};

export default GeneralAnalytics;