import React from "react";
import styles from "./styles.module.scss";


interface IProps {
    children: React.ReactElement[] | React.ReactElement;
    className?: string;
}

const PageContainer: React.FC<IProps> = ({ children, className }) => {
    return (
        <div className={styles.page_container + " " + className}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child);
            })}
        </div>
    );
};

export default PageContainer;