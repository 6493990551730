import React from "react";
import classsnames from "classnames";
import styles from "./styles.module.scss";

const variants = new Map([
    ["default", styles.default],
    ["primary", styles.primary],
    ["disabled", styles.disabled],
    ["danger", styles.danger],
    ["secondary", styles.secondary],
    ["success", styles.success],
    ["primary-empty", styles.primary_empty],
    ["danger-empty", styles.danger_empty]
]);

type VariantType = "default" | "primary" | "disabled" | "danger" | "secondary" | "success" | "primary-empty" | "danger-empty"

interface IProps {
    children: React.ReactNode;
    variant?: VariantType;
    onClick?: () => void;
    id?: string;
}

const Button: React.FC<IProps> = ({ id, variant = "default", onClick, children }) => {
    return (
        <div className={styles.button_container}>
            <button onClick={onClick} id={id} className={classsnames(styles.button, variants.get(variant))} disabled={variant === "disabled"}>
                {children}
            </button>
        </div>
    );
};

export default Button;