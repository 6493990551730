import axios from "./axios";
import { IFilters } from "../types/default";
import { IBindPlaylist, ITerminalList } from "../types/terminals";
import { IConnectWifi, IDemoControl, IDisplaySetup, ITerminalFull } from "../types/terminalSetup";

interface ITerminalApiResponse {
    data: ITerminalShort[];
}

interface ITerminalShort {
    id: string;
    name: string;
}

export const getAllTerminalApi = async (filters: IFilters): Promise<ITerminalList | undefined> => {
    return await axios.post("/terminal/", {
        page: filters.page,
        per_page: filters.perPage,
        filters: filters.filters,
        order_by: filters.orderBy,
        order_desc: filters.orderDesk
    });
};

export const getTerminalsListApi = async (): Promise<ITerminalApiResponse | undefined> => {
    return await axios.get("/terminal/get-short/");
};

export const getTerminalApi = async (terminal_id: string): Promise<ITerminalFull | undefined> => {
    return await axios.post("/terminal/get-by-id/", {terminal_id});
    // return {
    //     id: "123",
    //     name: "Terminal 1",
    //     binding_playlist: {
    //         playlist_id: "33",
    //         playlist_name: "Playlist 1",
    //         is_last_version: false
    //     },
    //     network_setup: {
    //         interfaces: [
    //             {
    //                 "id": "eth0",
    //                 "type": "ethernet",
    //                 "ip": "192.168.0.11",
    //                 "mask": "255.255.192.0",
    //                 "geteway": "192.168.0.0",
    //                 "dns_1": "4.4.4.4",
    //                 "dns_2": "8.8.8.8",
    //                 "is_active": false,
    //                 connected: "ethernet",
    //             },
    //             {
    //                 "id": "wlan0",
    //                 "type": "wifi",
    //                 "ip": "192.168.0.10",
    //                 "mask": "255.255.255.0",
    //                 "geteway": "192.168.0.0",
    //                 "dns_1": "4.4.4.4",
    //                 "dns_2": "8.8.8.8",
    //                 "is_active": true,
    //                 connected: "What is love",
    //             }
    //         ]
    //     },
    //     display_setup: {
    //         orientation: "horizontal",
    //         resolution: "120x1080",
    //         resolution_variants: ["1920x1080", "1366x760", "1360x760"]
    //     },
    //     server_connected: true
    // };
};

export const connectTerminalToWifiApi = async (data: IConnectWifi) => {
    return await axios.post("/terminal/connect-wifi/", data);
};

export const changeDisplaySetupApi = async (data: IDisplaySetup) => {
    return await axios.post("/terminal/change-display-setup/", data);
};

export const demonstrationControlApi = async (data: IDemoControl) => {
    return await axios.post("/terminal/demonstration-control/", data);
};

export const bindPlaylistToTerminalApi = async (data: IBindPlaylist) => {
    return await axios.post("/terminal/add-playlist/", data);
};

export const updatePlaylistToTerminalApi = async (terminal_id: string) => {
    return await axios.post("/terminal/update-playlist/", { terminal_id });
};

export const unbindPlaylistToTerminalApi = async (terminal_ids: string[]) => {
    return await axios.post("/terminal/delete-playlist/", { terminal_ids });
};