import { useTranslation } from "i18nano";
import React, { useState } from "react";
import { Button, Input, Textarea } from "../../../../components/UI";
import { useCreateScenarioStore } from "../../../../stores";
import "./ScenarioSettings.scss";


interface IProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScenarioSettings: React.FC<IProps> = ({ setShowModal }) => {
    const t = useTranslation();

    const { changeScenarioData, scenarioData } = useCreateScenarioStore(state => state);

    const [name, setName] = useState(scenarioData.name);
    const [description, setDescription] = useState(scenarioData.description);

    const setNameHandler = () => {
        changeScenarioData({ name, description });
        setShowModal(false);
    };

    return (
        <div className={"scenarioSettings-container"}>
            <div className={"item"}>
                <span>{t("label.name", { name: t("scenario.genitive") })}</span>
                <Input
                    value={name}
                    onChange={setName}
                    type={"text"}
                    placeholder={t("placeholder.text", { name: t("table_head.name") })}
                />
            </div>
            <div className={"item"}>
                <span>{t("label.description", { name: t("scenario.genitive") })}</span>
                <Textarea
                    value={description}
                    onChange={setDescription}
                    maxLength={100}
                    placeholder={t("placeholder.text", { name: t("table_head.description") })}
                />
            </div>
            <div className={"buttons-container"}>
                <Button variant={"secondary"} onClick={() => setShowModal(false)} >{t("button.cancel")}</Button>
                <Button variant={"primary"} onClick={setNameHandler} >{t("button.save")}</Button>
            </div>
        </div>
    );
};

export default ScenarioSettings;