import { useTranslation } from "i18nano";
import React from "react";
import { Button, IconSearch, SearchInput } from "../UI";
import styles from "./styles.module.scss";


interface IProps {
    onSearch: (val: string) => void;
    onDelete?: () => void;
    onSelectAll?: (checkedState: boolean) => void;
    righrPanel?: JSX.Element;
    selectedLenght?: number;
}

const PlaylistFilter: React.FC<IProps> = ({ onSearch, onDelete, onSelectAll, righrPanel, selectedLenght }) => {
    const t = useTranslation();

    return (
        <div className={styles.control_panel_container}>
            <div className={styles.panel_left}>
                <SearchInput
                    placeholder={t("placeholder.search")}
                    iconEnd={<IconSearch color={"soft"} />}
                    onSearch={onSearch}
                />
                {onSelectAll
                    ? <Button variant={"primary"} onClick={() => onSelectAll(true)}>{t("button.select_all")}</Button>
                    : null
                }
                {onDelete
                    ? <Button variant={selectedLenght ? "danger" : "disabled"} onClick={onDelete}>{t("button.delete")}</Button>
                    : null
                }
            </div>
            <div className={styles.panel_right}>
                {righrPanel}
            </div>
        </div>
    );
};

export default PlaylistFilter;