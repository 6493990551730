import React, { useState } from "react";
import { usePagination } from "../../../hooks/usePagination";
import classnames from "classnames";
import { Select } from "../Select";
import { IconArrowLeft, IconArrowRight } from "../Icons";
import { useTranslation } from "i18nano";
import styles from "./styles.module.scss";


interface IProps {
    perPage: number;
    setPerPage: (val: number) => void;
    page: number;
    setPage: (val: number) => void;
    count: number;
}

interface ISelectVal {
    id: number,
    name: string
}

const Pagination: React.FC<IProps> = ({ perPage, setPerPage, setPage, page, count }) => {
    const pageList = usePagination(page, perPage, count);
    const t = useTranslation();

    const [selectVal, setSelectVal] = useState(perPage);

    const maxPage = pageList ? pageList[pageList.length - 1] : parseInt((count / perPage).toFixed(0));

    const optionsPagination: ISelectVal[] = [
        { id: 10, name: `${t("pagination")}: 10` },
        { id: 30, name: `${t("pagination")}: 30` },
        { id: 50, name: `${t("pagination")}: 50` },
        { id: 100, name: `${t("pagination")}: 100` },
    ];

    const changePage = (selectedPage: number | string) => {
        switch (selectedPage) {
            case "next":
                page < maxPage && setPage(page + 1);
                break;
            case "previous":
                page > 1 && setPage(page - 1);
                break;
            case "...":
                break;
            default:
                setPage(parseInt(selectedPage.toString()));
        }
    };

    const checkSel = (newPerPage: number) => {
        setSelectVal(newPerPage);
        setPerPage(newPerPage);
        setPage(1);
    };

    return (
        <div className={styles.pagination_container}>
            {maxPage !== 1 &&
                <div>
                    <span className={styles.arrow} onClick={() => changePage("previous")}>
                        <IconArrowLeft size={25} />
                    </span>
                    <div className={styles.numbers_container}>
                        {pageList && pageList.map((pageNum, index) =>
                            <span
                                className={classnames({
                                    [styles.number]: pageNum !== "...",
                                    [styles.current]: pageNum === page
                                })}
                                onClick={() => changePage(pageNum)}
                                key={index}>{pageNum}</span>
                        )}
                    </div>
                    <span className={styles.arrow} onClick={() => changePage("next")}>
                        <IconArrowRight size={25} />
                    </span>
                </div>
            }
            <Select options={optionsPagination} value={selectVal} setValue={checkSel} />
        </div>
    );
};

export default Pagination;