import { ITriggerDatetime } from "../../../../../types/playlist";

export type TEnd = "never" | "date" | "before"

export interface IShortName {
    title: string;
    body: string;
}

export const getShortName = (timetableTrigger: ITriggerDatetime, translation: any): IShortName => {
    let title = "";
    let body = "";

    const daysNames = Object.values(translation.days);
    const daysShortNames = Object.values(translation.short.days);
    const monthNames = Object.values(translation.month);
    const monthShortNames = Object.values(translation.short.month);
    const intervalNames = translation.interval;
    const intervalShortNames = translation.short.interval;

    const labels = translation.labels;

    const dateStart = new Date(timetableTrigger.date_start);
    const dateEnd = new Date(timetableTrigger.date_end ? timetableTrigger.date_end : timetableTrigger.date_start);

    if (timetableTrigger.interval.value === 1) {
        body += intervalNames[timetableTrigger.interval.type];
    } else {
        body += `${labels.every} ${timetableTrigger.interval.value} ${intervalShortNames[timetableTrigger.interval.type]}`;
    }

    if (timetableTrigger.days) {
        body += " - ";
        for (const day of timetableTrigger.days) {
            body += timetableTrigger.days.indexOf(day) === 0 ? daysShortNames[day] : `, ${daysShortNames[day]}`;
        }
    }

    if (timetableTrigger.date_end) {
        title = `${daysNames[dateStart.getDay()]}, ${dateStart.getDate()} ${monthNames[dateStart.getMonth()]} - ` +
            `${daysNames[dateEnd.getDay()]}, ${dateEnd.getDate()} ${monthNames[dateEnd.getMonth()]}`;

        body += `, ${labels.up_to} ${daysShortNames[dateEnd.getDay()]}, ${dateEnd.getDate()} ${monthShortNames[dateEnd.getMonth()]}`;
    }

    if (timetableTrigger.time_start && timetableTrigger.time_end) {
        title = `${daysNames[dateStart.getDay()]}, ${dateStart.getDate()} ${monthNames[dateStart.getMonth()]}: ${timetableTrigger.time_start} - ${timetableTrigger.time_end}`;
    } else {
        title = `${daysNames[dateStart.getDay()]}, ${dateStart.getDate()} ${monthNames[dateStart.getMonth()]}`;
    }

    if (timetableTrigger.repetitions) {
        body += `, ${timetableTrigger.repetitions} ${labels.times}`;
    }

    return { title, body };
};