import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Button, Input, Select, Modal } from "../../../../components/UI";
import { getTerminalsListApi } from "../../../../api/terminal-api";
import { getContentListShortApi } from "../../../../api/content-api";
import DownloadStats from "../../DownloadStats";
import { useTranslation } from "i18nano";


interface IProps {
    statsType: string;
    fetchStats: (filters: { start_date: string, end_date: string, terminal_id: string, content_id: string, timezone: string }) => void;
}

interface ITerminalApiResponse {
    data: IOptionShort[];
}

interface IOptionShort {
    id: string;
    name: string;
}

interface IContentGetShortApiResponse {
    data: IOptionShort[];
}

const minDate = new Date();
minDate.setMonth(minDate.getMonth() - 3);
const minDateValue = new Date();

let startDate: string;
let setStartDate: React.Dispatch<React.SetStateAction<string>>;
let endDate: string;
let setEndDate: React.Dispatch<React.SetStateAction<string>>;

const Datepicker: React.FC<IProps> = ({ fetchStats, statsType }) => {
    const t = useTranslation();
    [startDate, setStartDate] = useState(
        startDate ? new Date(startDate).toISOString().split("T")[0] : minDateValue.toISOString().split("T")[0]
    );
    const handleStartDateChange = (newDateString: string) => setStartDate(newDateString);
    const [showLoadModal, setShowLoadModal] = useState(false);

    [endDate, setEndDate] = useState(endDate ? endDate : new Date().toISOString().split("T")[0]);
    const handleEndDateChange = (newDateString: string) => setEndDate(newDateString);

    const defaultItem: IOptionShort = {
        name: "All",
        id: ""
    };
    const defaultOptions: IOptionShort[] = [defaultItem];

    const [terminalsOptions, setTerminalOptions] = useState(defaultOptions);
    const [ itemTerminal, setItemTerminal ] = useState(defaultItem.id);

    const changeTerminalFilterHandler = (value: string) => {
        setItemTerminal(value);
    };

    const [contentOptions, setContentOptions] = useState(defaultOptions);
    const [ itemContent, setItemContent ] = useState(defaultItem.id);

    const changeContentFilterHandler = (value: string) => {
        setItemContent(value);
    };

    useEffect(() => {
        getTerminalsListApi().then((data: ITerminalApiResponse | undefined) => {
            if (data?.data) {
                const newTerminalsOptions: IOptionShort[] = [defaultItem];
                for (const terminalOption of data.data) {
                    const newTerminalOption: IOptionShort = {
                        name: terminalOption.name,
                        id: terminalOption.id
                    };
                    newTerminalsOptions.push(newTerminalOption);
                }
                setTerminalOptions(newTerminalsOptions);
            }
        });

        getContentListShortApi().then((data: IContentGetShortApiResponse | undefined) => {
            if (data?.data) {
                const newContentOptions: IOptionShort[] = [defaultItem];
                for (const contentOption of data.data) {
                    const newContentOption: IOptionShort = {
                        name: contentOption.name,
                        id: contentOption.id
                    };
                    newContentOptions.push(newContentOption);
                }
                setContentOptions(newContentOptions);
            }
        });
    }, [getTerminalsListApi, getContentListShortApi]);

    return (
        <div className={styles.root}>
            <div className={styles.control_panel_container}>
                <div className={styles.panel_left}>
                    <Input 
                        type={"date"} 
                        id="startDate" 
                        min={minDate.toISOString().split("T")[0]} 
                        max={new Date().toISOString().split("T")[0]}
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                    <Input 
                        type={"date"} 
                        id="endDate"
                        min={minDate.toISOString().split("T")[0]} 
                        max={new Date().toISOString().split("T")[0]}
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                    <div className={styles.dropdown_block}>
                        <label className={styles.label}>Terminals</label>
                        <div className={styles.dropdown}>
                            <Select 
                                options={terminalsOptions} 
                                value={itemTerminal}
                                setValue={changeTerminalFilterHandler} 
                            />
                        </div>
                    </div>
                    <div className={styles.dropdown_block}>
                        <label className={styles.label}>Content</label>
                        <div className={styles.dropdown}>
                            <Select 
                                options={contentOptions} 
                                value={itemContent}
                                setValue={changeContentFilterHandler} 
                            />
                        </div>
                    </div>

                    <Button variant={"primary"} onClick={() => {
                        fetchStats({
                            start_date: startDate,
                            end_date: endDate,
                            terminal_id: itemTerminal,
                            content_id: itemContent,
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                        });
                    }}>{t("analytic.search_button")}</Button>
                    <Button variant={"secondary"} onClick={() => setShowLoadModal(true)}>{t("analytic.download_button")}</Button>
                </div>
            </div>
            <div style={{ paddingTop: "15px" }} className={styles.control_panel_container}>
                <div className={styles.panel_left}>
                    <Button variant={"primary-empty"} onClick={() => {
                        const newStartDate = new Date().toISOString().split("T")[0];
                        const newEndDate = new Date().toISOString().split("T")[0];
                        setStartDate(newStartDate);
                        setEndDate(newEndDate);
                        fetchStats({
                            start_date: newStartDate,
                            end_date: newEndDate,
                            terminal_id: itemTerminal,
                            content_id: itemContent,
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                        });
                    }}>1 {t("analytic.day")}</Button>
                    <Button variant={"primary-empty"} onClick={() => {
                        const newStartDate = new Date();
                        newStartDate.setDate(minDateValue.getDate() - 7);
                        const newEndDate = new Date().toISOString().split("T")[0];
                        setStartDate(newStartDate.toISOString().split("T")[0]);
                        setEndDate(newEndDate);
                        fetchStats({
                            start_date: newStartDate.toISOString().split("T")[0],
                            end_date: newEndDate,
                            terminal_id: itemTerminal,
                            content_id: itemContent,
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                        });
                    }}>1 {t("analytic.week")}</Button>
                    <Button variant={"primary-empty"} onClick={() => {
                        const newStartDate = new Date();
                        newStartDate.setDate(minDateValue.getDate() - 30);
                        const newEndDate = new Date().toISOString().split("T")[0];
                        setStartDate(newStartDate.toISOString().split("T")[0]);
                        setEndDate(newEndDate);
                        fetchStats({
                            start_date: newStartDate.toISOString().split("T")[0],
                            end_date: newEndDate,
                            terminal_id: itemTerminal,
                            content_id: itemContent,
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                        });
                    }}>1 {t("analytic.month")}</Button>
                </div>
            </div>
            <Modal isShow={showLoadModal} setIsShow={setShowLoadModal} title={t("analytic.download_settings")}>
                <DownloadStats startDate={startDate} endDate={endDate} terminalId={itemTerminal} contentId={itemContent} statsType={statsType}/>
            </Modal>
        </div>
    );
};

export default Datepicker;