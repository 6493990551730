import React from "react";
import styles from "./styles.module.scss";
import { IconArrows } from "../../UI";
import classnames from "classnames";


type TSortPanelItem = "checkbox" | "text"

interface IProps {
    type: TSortPanelItem;
    onClick?: () => void;
    onChange?: (val: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    text?: string;
    filterable?: boolean;
    position?: "up" | "down"
}

const SortPanelItem: React.FC<IProps> = ({ type, onChange, onClick, checked, text, filterable }) => {
    if (type === "checkbox") {
        return (
            <div className={classnames(styles.sort_panel_item, styles.sort_panel_checkbox)}>
                <input
                    type="checkbox"
                    onChange={onChange}
                    checked={checked}
                />
            </div>
        );
    }

    return (
        <div className={styles.sort_panel_item} onClick={onClick}>
            <span>{text}</span>
            {filterable ? <IconArrows size={12} /> : null}
        </div>
    );
};

export default SortPanelItem;