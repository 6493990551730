import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { ICreatePlaylistStore, ITriggers } from "../../types/createPlaylist";
import { v4 as uuidv4 } from "uuid";
import { changeRespPlaylist, defaultState } from "./consts";
import { createPlaylistApi, getOnePlaylistApi, updatePlaylistApi } from "../../api/playlist-api";


export const useCreatePlaylistStore = create<ICreatePlaylistStore>()(
    immer(persist(
        (set, get) => ({
            playlist: defaultState,
            currentPlaylistItem: undefined,
            loading: false,
            error: undefined,
            triggers: {},
            isEdited: false,
            clearStore: () => {
                set(state => {
                    state.playlist = defaultState;
                    state.currentPlaylistItem = undefined;
                    state.loading = false;
                    state.error = undefined;
                    state.triggers = {};
                    state.isEdited = false;
                });
            },
            setDefaultState: () => {
                const { playlist } = get();

                const newTriggers: Record<string, ITriggers> = {};

                for (const item of playlist.playlist_items) {
                    newTriggers[item.id] = {
                        cv: {},
                        datetime: {},
                        eye_contact: null,
                        gesture: null
                    };
                    for (const trigger of item.triggers.cv) {
                        newTriggers[item.id].cv[`${uuidv4()}`] = trigger.conditions;
                    }
                    for (const trigger of item.triggers.datetime) {
                        newTriggers[item.id].datetime[`${uuidv4()}`] = trigger;
                    }
                    newTriggers[item.id].eye_contact = item.triggers.eye_contact;
                    newTriggers[item.id].gesture = item.triggers.gesture;
                }

                set(state => {
                    state.triggers = newTriggers;
                });
            },
            addPlaylistItem: (playlistItems) => {
                const newTriggers: Record<string, ITriggers> = {};

                for (const item of playlistItems) {
                    newTriggers[item.id] = { cv: {}, datetime: {}, eye_contact: null, gesture: null };
                }

                set(state => {
                    state.playlist.playlist_items.push(...playlistItems);
                    state.triggers = { ...state.triggers, ...newTriggers };
                });
            },
            changeIndexPlaylistItem: (newIndex, index) => {
                const { playlist } = get();

                const newPlaylistItems = [...playlist.playlist_items];

                const item = newPlaylistItems[index];
                newPlaylistItems.splice(index, 1);
                newPlaylistItems.splice(newIndex, 0, item);

                set(state => {
                    state.playlist.playlist_items = newPlaylistItems;
                });
            },
            deletePlaylistItem: (playlistItemId) => {
                set(state => {
                    state.playlist.playlist_items = state.playlist.playlist_items.filter(item => item.id !== playlistItemId);
                    state.currentPlaylistItem = undefined;
                });
            },
            setCurrentPlaylist: (playlistItemId) => {
                set(state => {
                    state.currentPlaylistItem = state.playlist.playlist_items.filter(item => item.id === playlistItemId)[0];
                });
            },
            setDuration: (duration) => {
                const { currentPlaylistItem, playlist } = get();

                if (currentPlaylistItem) {
                    const newPlaylistItems = playlist.playlist_items.map(item => {
                        if (item.id === currentPlaylistItem.id) {
                            return { ...item, duration: duration };
                        }
                        return item;
                    });

                    set(state => {
                        state.playlist.playlist_items = newPlaylistItems;
                    });
                }
            },
            setBlackoutDuration: (duration) => {
                const { currentPlaylistItem, playlist } = get();

                if (currentPlaylistItem) {
                    const newPlaylistItems = playlist.playlist_items.map(item => {
                        if (item.id === currentPlaylistItem.id) {
                            return { ...item, blackout_duration: duration };
                        }
                        return item;
                    });

                    set(state => {
                        state.playlist.playlist_items = newPlaylistItems;
                    });
                }
            },
            setDisplayType: (displayType) => {
                const { currentPlaylistItem, playlist } = get();

                if (currentPlaylistItem) {
                    const newPlaylistItems = playlist.playlist_items.map(item => {
                        if (item.id === currentPlaylistItem.id) {
                            return {
                                ...item,
                                display_type: displayType,
                                is_interruptible: displayType === "optional" ? true : false
                            };
                        }
                        return item;
                    });

                    set(state => {
                        state.playlist.playlist_items = newPlaylistItems;
                    });
                }
            },
            setTriggers: (data) => {
                const { currentPlaylistItem } = get();

                if (currentPlaylistItem) {
                    set(state => {
                        state.triggers[currentPlaylistItem.id] = data;
                    });
                }
            },
            setTotalTime: (totalTime) => {
                set(state => {
                    state.playlist.duration = totalTime;
                });
            },
            setPlaylistName: (name, description) => {
                set(state => {
                    state.playlist.name = name;
                    state.playlist.description = description;
                });
            },
            createPlaylist: async () => {
                const { playlist, triggers, isEdited } = get();

                const respPlaylist = changeRespPlaylist(playlist, triggers);

                try {
                    if (isEdited) {
                        return await updatePlaylistApi(respPlaylist).then(() => {
                            return true;
                        });
                    } else {
                        return await createPlaylistApi(respPlaylist).then((data) => {
                            if (data?.id) {
                                set(state => {
                                    state.isEdited = true;
                                    state.playlist.id = data.id;
                                });
                                return true;
                            } else {
                                return false;
                            }
                        });
                    }
                } catch {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.save"
                        };
                    });
                    return false;
                }
            },
            getPlaylist: async (playlistId) => {
                const { setDefaultState } = get();

                if (localStorage.getItem("create_scenario")) {
                    const prevState: ICreatePlaylistStore = JSON.parse(localStorage.getItem("create_playlist")!).state;
                    const id = prevState.playlist.id;

                    if (id === playlistId) {
                        return;
                    }
                }

                await getOnePlaylistApi(playlistId).then((data) => {
                    if (data) {
                        set(state => {
                            state.isEdited = true;
                            state.playlist = data;
                        });

                        setDefaultState();
                    }
                }).catch(() => {
                    set(state => {
                        state.error = {
                            title: "notification.error.label",
                            body: "notification.error.server_connect"
                        };
                    });
                });
            }
        }),
        {
            name: "create_playlist",
            storage: createJSONStorage(() => localStorage)
        }
    ))
);
