import axios from "./axios";
import { IFilters } from "../types/default";
import { IPlaylist, IPlaylistList } from "../types/playlist";


export const getOnePlaylistApi = async (playlist_id: string): Promise<IPlaylist | undefined> => {
    return await axios.get(`/playlist/${playlist_id}/`);
};

export const getAllPlaylistApi = async (filters: IFilters): Promise<IPlaylistList | undefined> => {
    return await axios.post("/playlist/", {
        page: filters.page,
        per_page: filters.perPage,
        filters: filters.filters,
        order_by: filters.orderBy,
        order_desc: filters.orderDesk
    });
};

export const createPlaylistApi = async (data: IPlaylist): Promise<Record<string, string> | undefined> => {
    return await axios.post("/playlist/create/", data);
};

export const updatePlaylistApi = async (data: IPlaylist) => {
    return await axios.post("/playlist/update/", data);
};

export const deletePlaylistApi = async (data: string[]) => {
    return await axios.delete("/playlist/delete/", { data });
};